interface LocationState {
  lat: number
  lng: number
}
const defaultLocationState: LocationState = {
  lat: 13.7563,
  lng: 100.5018,
}
export default (
  state: LocationState = defaultLocationState,
  action: { type: string; payload: { lat: number; lng: number } },
) => {
  switch (action.type) {
    case 'SET_LOCATION':
      return action.payload
    default:
      return state
  }
}
