import { AnyAction } from 'redux'
export type ImageFieldType =
  | 'national_id'
  | 'car_driving_license'
  | 'motorcycle_driving_license'
  | 'organization'

export type UserType = 'general' | 'student' | 'corporate' | 'resident' | 'medical'
export type ServiceType = 'car' | 'motorcycle' | 'micro' | 'all'

interface OrganizationType {
  name: string
  cardURL: string
  selfieURL: string
}

export interface UserRegistrationType {
  term: boolean
  term_accept_time: string
  privacy1: boolean
  privacy1_accept_time: string
  subscribe: boolean
  userType: UserType
  serviceType: ServiceType
  organization: OrganizationType
  national_id: { cardURL: string; selfieURL: string }
  car_driving_license: { cardURL: string; selfieURL: string }
  motorcycle_driving_license: { cardURL: string; selfieURL: string }
  signature: string
  campaignCode: string
}

export const defaultUserRegistrationState: UserRegistrationType = {
  term: false,
  term_accept_time: '',
  privacy1: false,
  privacy1_accept_time: '',
  subscribe: false,
  userType: 'general',
  serviceType: 'all',
  national_id: { cardURL: '', selfieURL: '' },
  car_driving_license: { cardURL: '', selfieURL: '' },
  motorcycle_driving_license: { cardURL: '', selfieURL: '' },
  organization: { name: '', cardURL: '', selfieURL: '' },
  signature: '',
  campaignCode: '',
}

export default (state = defaultUserRegistrationState, action: AnyAction): UserRegistrationType => {
  switch (action.type) {
    case 'SET_STATE': {
      return action.payload
    }
    case 'SET_FIELD': {
      const field: keyof UserRegistrationType = action.payload.field
      const value = action.payload.value
      return { ...state, [field]: value }
    }
    default: {
      return state
    }
  }
}
