import axios from 'axios'

export async function sendResetPassword(email: string) {
  try {
    const encoded = encodeURIComponent(email)
    const result = await axios.get(
      `https://ws.haupcar.com/admin_emailresetpassword?email=${encoded}`,
    )
    return result.data
  } catch (error: any) {
    if (error?.response?.data?.message) {
      throw error?.response?.data?.message
    }
    throw error
  }
}
