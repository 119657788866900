export const TermsContent = () => {
  return (
    <section className="words-content">
      <h1 className="text-center">
        ข้อกำหนดและเงื่อนไขตามสัญญาความร่วมมือดำเนินธุรกิจให้เช่ารถยนต์
      </h1>
      <br />
      <p>
        สัญญาฉบับนี้ทำขึ้นระหว่าง บริษัท ฮ้อปคาร์ จำกัด สำนักงานตั้งอยู่เลขที่ 128/21 ชั้น 3
        อาคารพญาไทพลาซ่า ถนนพญาไท แขวงทุ่งพญาไท เขตราชเทวี กรุงเทพมหานคร 10400
        ซึ่งต่อไปในสัญญานี้จะเรียกว่า “ผู้ให้บริการแพลตฟอร์ม” ฝ่ายหนึ่ง กับ “ผู้ให้เช่ารถยนต์”
        อีกฝ่ายหนึ่ง ที่ระบุไว้ในสัญญา
      </p>
      <br />
      <p className="indent">
        คู่สัญญาทั้งสองฝ่ายตกลงดำเนินธุรกิจร่วมกันโดยมีข้อกำหนดและเงื่อนไข ดังต่อไปนี้
      </p>
      <p className="bold topic">ข้อ 1. คำนิยาม</p>
      <p className="indent">
        เพื่อให้เป็นไปตามเจตนารมณ์ของคู่สัญญา คำหรือข้อความที่ปรากฏในสัญญาฉบับนี้
        และรวมถึงเอกสารแนบท้ายทั้งหลายให้มีความหมาย ดังต่อไปนี้
      </p>
      <br />
      <p className="indent">
        “ผู้ให้บริการแพลตฟอร์ม” หมายถึง บริษัท ฮ้อปคาร์ จำกัด
        ที่เป็นเจ้าของและผู้ดูแลระบบซอฟต์แวร์ในการดำเนินงานตามสัญญานี้
      </p>
      <p className="indent">
        “ผู้ให้เช่ารถยนต์” หมายถึง บุคคลธรรมดา หรือนิติบุคคลใด ๆ
        ซึ่งเป็นเจ้าของรถยนต์หรือผู้ครอบครองรถยนต์โดยถูกต้องตามกฎหมาย
        ที่นำมาให้บริการหรือดำเนินงานตามสัญญานี้
      </p>
      <p className="indent">
        “ผู้เช่า” หมายถึง บุคคลธรรมดา หรือนิติบุคคลใด ๆ ที่เช่ารถยนต์ของผู้ให้เช่ารถยนต์
        ผ่านทางระบบซอฟต์แวร์ของผู้ให้บริการแพลตฟอร์ม
      </p>
      <p className="indent">
        “รถเช่า” หมายถึง รถยนต์ที่ผู้ให้เช่ารถยนต์จัดหามาดำเนินงานตามสัญญานี้ รวมทั้งชิ้นส่วน
        อุปกรณ์ ยางอะไหล่ เครื่องมือ และส่วนประกอบต่าง ๆ ของรถยนต์
      </p>
      <p className="indent">
        “อุปกรณ์” หมายถึง อุปกรณ์ที่ผู้ให้บริการแพลตฟอร์มติดตั้งในตัวรถเช่า
        เพื่อควบคุมรถยนต์หรือส่งข้อมูลพิกัดตำแหน่งทางดาวเทียมของรถยนต์ ด้วยระบบอิเล็กทรอนิกส์
      </p>
      <p className="indent">
        “ระบบ” หมายถึง
        งานซอฟต์แวร์ที่ใช้จองรถและจัดการรถและอุปกรณ์ส่วนควบที่ติดตั้งเพิ่มเติมในรถเช่าจากผู้ให้บริการแพลตฟอร์ม
      </p>
      <p className="indent">
        “รายรับ” หมายถึง รายรับที่เกิดขึ้นจากความร่วมมือในการดำเนินธุรกิจให้เช่ารถยนต์ เช่น
        รายรับจากค่าเช่ารถที่คิดตามระยะเวลาและตามระยะทางที่เก็บจากผู้เช่า
        รวมถึงค่าปรับที่ผู้เช่าจ่ายให้กับโครงการในกรณีที่ผู้เช่าใช้งานเกินเวลาและเกินระยะทาง
        แต่ทั้งนี้ไม่รวมถึงค่าเสียหายที่ผู้เช่าชดใช้แก่ผู้ให้เช่ารถยนต์ในกรณีที่เกิดอุบัติเหตุ
        หรือความเสียหาย หรือทำให้รถยนต์ ส่วนควบ และอุปกรณ์ของรถยนต์ ด้อยค่าลงด้วยประการใด ๆ
      </p>

      <p className="bold topic">ข้อ 2. ความร่วมมือในการดำเนินธุรกิจ</p>
      <p className="indent">
        ผู้ให้บริการแพลตฟอร์มและผู้ให้เช่ารถยนต์ประสงค์ที่จะสร้างความร่วมมือกันในการทำธุรกิจให้บริการรถเช่าในรูปแบบคาร์แชริ่ง
        (Car Sharing) โดยใช้ระบบที่ผู้ให้บริการแพลตฟอร์มเป็นผู้จัดทำ
        มีระยะเวลาของสัญญาตามที่ระบุไว้ในหน้าแรกของสัญญา
        โดยในวันทำสัญญาผู้ให้เช่ารถยนต์วางเงินเป็นหลักประกันในการทำสัญญาไว้เป็นจำนวนที่ระบุในในหน้าแรกของสัญญา
        และยินยอมให้ผู้ให้บริการแพลตฟอร์มสามารถหักเงินประกันได้ หากมิได้ปฏิบัติตามข้อกำหนดใด ๆ
        ในสัญญาฉบับนี้
      </p>

      <p className="indent">
        ในสัญญานี้ ผู้ให้เช่ารถยนต์สามารถเลือกรูปแบบของการให้เช่ารถยนต์ได้ 4 ประเภท คือ
      </p>
      <ol>
        <li>2.1 ประเภท Verified Pro</li>
        <li>2.2 ประเภท Verified Franchise</li>
        <li>2.3 ประเภท Starter</li>
        <li>2.4 ประเภท Verified Starter</li>
      </ol>
      <p className="bold topic">ข้อ 3. สิทธิและหน้าที่ของคู่สัญญา</p>
      <ol>
        <li prefix="3.1">
          <span>สิทธิและหน้าที่ของผู้ให้เช่ารถยนต์ ประเภท Verified Pro และ Verified Franchise</span>
          <ol>
            <li prefix="3.1.1">
              ผู้ให้เช่ารถยนต์มีหน้าที่รับผิดชอบค่าใช้จ่ายในการดำเนินการจัดหารถเช่าที่ระบุไว้ในหน้าแรกของสัญญา
              โดยผู้ให้เช่ารถยนต์จะต้องส่งมอบรถเช่า ให้ผู้ให้บริการแพลตฟอร์มเพื่อติดตั้งอุปกรณ์ ณ
              สถานที่ติดตั้งอุปกรณ์ของ บริษัท ฮ้อปคาร์ จำกัด ภายใน 30 วัน นับจากวันเริ่มสัญญา
              มิเช่นนั้นจะต้องชำระค่าปรับให้ผู้ให้เช่ารถยนต์ ตามอัตราซึ่งระบุไว้ในหน้าแรกของสัญญา
              รถเช่าทั้งหมดในโครงการเป็นกรรมสิทธิ์หรือสิทธิครอบครองของผู้ให้เช่ารถยนต์แต่เพียงฝ่ายเดียว
              โดยผู้ให้บริการแพลตฟอร์มไม่มีสิทธิดัดแปลง หรือเปลี่ยนชิ้นส่วนใด ๆ
              โดยไม่ได้รับความยินยอมจากผู้ให้เช่ารถยนต์ก่อน
            </li>
            <li prefix="3.1.2">
              ผู้ให้เช่ารถยนต์มีหน้าที่ต้องดูแลรักษารถเช่าและรับผิดชอบค่าใช้จ่ายในการดำเนินธุรกิจให้เช่ารถยนต์เพื่อให้รถเช่าอยู่ในสภาพพร้อมใช้งานอยู่ตลอดระยะเวลาตามสัญญา
              เพื่อประโยชน์สูงสุดของโครงการ ซึ่งรวมถึงค่าใช้จ่ายอื่น ๆ ที่เกี่ยวข้อง เช่น
              ค่าประกันภัยรถเช่าเชิงพาณิชย์ ค่าล้างรถอย่างน้อย 1 ครั้งต่อสัปดาห์ ค่าน้ำมัน
              ค่าน้ำมันเครื่อง ค่ายางรถ ค่าภาษีรถยนต์ ค่าบริการอุปกรณ์รายเดือน ค่าสนับสนุนทางการตลาด
              เป็นต้น
            </li>
            <li prefix="3.1.3">
              ผู้ให้เช่ารถยนต์และผู้ให้บริการแพลตฟอร์มจะร่วมกันประชาสัมพันธ์และทำการตลาด
              รวมทั้งการออกโปรโมชั่นต่าง ๆ เพื่อผลสำเร็จของโครงการ
              โดยรูปแบบของการประชาสัมพันธ์และทำการตลาด รวมทั้งการออกโปรโมชั่นต่าง ๆ
              ถือเป็นดุลยพินิจของผู้ให้บริการแพลตฟอร์มแต่เพียงผู้เดียว ทั้งนี้
              ผู้ให้เช่ารถยนต์เป็นผู้รับผิดชอบค่าใช้จ่ายที่เกิดขึ้นจากการประชาสัมพันธ์และทำการตลาด
              รวมทั้งการออกโปรโมชั่นต่าง ๆ
            </li>
            <li prefix="3.1.4">
              ผู้ให้เช่ารถยนต์จะเป็นผู้รับผิดชอบดูแลและซ่อมแซมรถเช่า
              ในกรณีรถเช่ามีปัญหาในการใช้งานหรือเกิดอุบัติเหตุ โดยต้องทำการซ่อมให้เสร็จภายใน 45
              วันนับตั้งแต่รถยนต์ถูกถอดจากระบบ ทั้งนี้
              ผู้ให้เช่ารถยนต์ยังมีหน้าที่ชำระค่าใช้จ่ายในการดำเนินธุรกิจให้เช่ารถยนต์
            </li>
            <li prefix="3.1.5">
              ผู้ให้เช่ารถยนต์มีสิทธิเข้าถึงข้อมูลอื่น ๆ ที่จำเป็นในการให้บริการได้ เช่น
              การตรวจสอบข้อมูลสถานะรถเช่าจาก GPS ที่ผู้ให้บริการแพลตฟอร์มติดตั้งในรถเช่า
              การรับจองรถเช่า รายได้รถเช่าแต่ละคัน รวมถึงข้อมูลลูกค้าที่ใช้บริการรถเช่า
              และข้อมูลอื่น ๆ โดยผ่านระบบของผู้ให้บริการแพลตฟอร์ม
            </li>
            <li prefix="3.1.6">
              ผู้ให้เช่ารถยนต์จะต้องใช้งานข้อมูลส่วนบุคคลของผู้เช่าตามวัตถุประสงค์เพื่อการบริการยานพาหนะให้เช่าแบบคาร์แชริ่ง
              (Car Sharing) เท่านั้น และมีหน้าที่ควบคุมและดูแลการจัดเก็บ ประมวลผล ส่ง/โอน
              และทำลายข้อมูลส่วนบุคคลของผู้เช่า ตาม พ.ร.บ. คุ้มครองข้อมูลส่วนบุคคล พ.ศ. 2562
            </li>
            <li prefix="3.1.7">
              คู่สัญญาทั้งสองฝ่ายต้องไม่จ้างแรงงานหรือจ้างทำของใด ๆ
              กับลูกจ้างหรืออดีตลูกจ้างหรือบุคคลใด ๆ
              ซึ่งทำงานหรือเคยทำงานกับคู่สัญญาอีกฝ่ายหนึ่งทุกกรณี
              นับตั้งแต่วันที่สัญญาฉบับนี้มีผลบังคับใช้จนถึงวันที่ครบ 3 ปี
              หลังจากที่สัญญาฉบับนี้สิ้นสุด
            </li>
            <li prefix="3.1.8">
              ผู้ให้เช่ารถยนต์จะต้องส่งมอบรายละเอียดยี่ห้อ รุ่น
              และปีของรถยนต์ที่ต้องการนำเข้าแพลตฟอร์ม
              เพื่อผู้ให้บริการแพลตฟอร์มพิจารณาความเหมาะสมและอนุมัติการติดตั้งอุปกรณ์ก่อนทุกครั้ง
            </li>
            <li prefix="3.1.9">
              ผู้ให้เช่ารถยนต์มอบหมายให้ผู้ให้บริการแพลตฟอร์มเป็นผู้พิจารณาคุณสมบัติของผู้เช่าในแต่ละการจอง
              โดยผู้ให้บริการแพลตฟอร์มไม่ต้องรับผิดหากเกิดความเสียหายอันเกิดจากการอนุมัติการจองแทนผู้ให้เช่ารถยนต์
            </li>
            <li prefix="3.1.10">
              ผู้ให้เช่ารถยนต์มีหน้าที่จัดหาที่ตั้งของพื้นที่จอดรถ
              โดยต้องแจ้งให้ผู้ให้บริการแพลตฟอร์มทราบถึงตำแหน่งที่ตั้งของรถแต่ละคัน
              หรือผู้ให้เช่ารถยนต์สามารถมอบหมายให้ผู้ให้บริการแพลตฟอร์มเป็นตัวกลางในการจัดหาที่จอดรถยนต์ให้กับผู้ให้เช่ารถยนต์
              โดยทั้งสองกรณี ผู้ให้เช่ารถยนต์จะเป็นผู้รับผิดชอบค่าจอดรถ
              ตามอัตราซึ่งระบุไว้ในหน้าแรกของสัญญา
            </li>
            <li prefix="3.1.11">
              ผู้ให้เช่ารถยนต์จะชำระค่าติดตั้งอุปกรณ์ซึ่งระบุในหน้าแรกของสัญญาในวันทำสัญญา
            </li>
            <li prefix="3.1.12">
              กรณีบอกเลิกสัญญาก่อนครบอายุสัญญา
              ผู้ให้เช่ารถยนต์จะต้องชำระค่าถอนการติดตั้งอุปกรณ์เป็นจำนวนเงินตามที่ระบุไว้ในหน้าแรกของสัญญา
            </li>
            <li prefix="3.1.13">
              ผู้ให้บริการแพลตฟอร์มคิดค่าใช้จ่ายในการย้ายอุปกรณ์ไปติดตั้งกับรถยนต์คันอื่นของผู้ให้เช่ารถยนต์เป็นจำนวนเงินซึ่งระบุในหน้าแรกของสัญญา
              ในระหว่างสัญญาความร่วมมือดำเนินธุรกิจให้เช่ารถยนต์ของคู่สัญญา
              และผู้ให้เช่ารถยนต์จะเป็นผู้รับผิดชอบในการนำส่งรถยนต์มายังสถานที่ติดตั้งอุปกรณ์ของผู้ให้บริการแพลตฟอร์ม
              รวมถึงบรรดาค่าใช้จ่ายต่าง ๆ ในการเดินทางที่เกิดขึ้น
            </li>
            <li prefix="3.1.14">
              ผู้ให้เช่ารถยนต์จะชำระค่าบริการช่วยเหลือฉุกเฉินบนท้องถนนและค่าชดเชยงานบริการลูกค้าต่างๆ
              ในกรณีที่ผู้ให้เช่ารถยนต์เป็นฝ่ายก่อให้เกิดความเสียหาย
            </li>
            <li prefix="3.1.15">
              ผู้เช่าต้องชำระค่าใช้จ่ายที่เกี่ยวกับการใช้งานรถยนต์ของผู้เช่าที่หน่วยงานรัฐหรือหน่วยงานผู้มีอำนาจอื่นเรียกเก็บจากผู้ให้เช่ารถยนต์ในภายหลัง
              โดยสามารถระบุวันเวลาที่กระทำความผิดจริง เช่น ค่าปรับการทำผิดกฎจราจร, ค่าทางด่วน,
              ค่าปรับการจอดรถในพื้นที่ห้ามจอด
              และร่วมกับผู้ให้เช่ารถยนต์ติดตามให้ผู้เช่าและบุคคลที่เกี่ยวข้องรับผิดชอบความเสียหายดังกล่าวที่เกิดขึ้น
              ทั้งนี้ หากผู้เช่าไม่ชำระ ผู้ให้เช่ารถยนต์ต้องเป็นผู้รับผิดชอบค่าใช้จ่ายดังกล่าว
            </li>
            <li prefix="3.1.16">
              ผู้ให้เช่ารถยนต์ยินยอมให้ผู้บริการแพลตฟอร์มเข้าไปดูแลรักษาและแก้ไขรถยนต์ที่อยู่ในสภาพไม่พร้อมให้บริการที่ผู้บริการแพลตฟอร์มแจ้งให้ผู้ให้เช่ารถยนต์แก้ไขแล้ว
              แต่ผู้ให้เช่ารถยนต์ไม่ดำเนินการแก้ไขภายใน 72 ชั่วโมงหลังจากที่ได้รับแจ้ง
              ยกเว้นกรณีในข้อ 3.1.4 โดยมีค่าใช้จ่ายตามอัตราซึ่งระบุไว้ในหน้าแรกของสัญญา
            </li>
          </ol>
        </li>
        <li prefix="3.2">
          <span>สิทธิและหน้าที่ของผู้ให้เช่ารถยนต์ ประเภท Starter และ Verified Starter</span>
          <ol>
            <li prefix="3.2.1">
              ผู้ให้เช่ารถยนต์มีหน้าที่รับผิดชอบค่าใช้จ่ายในการดำเนินการจัดหารถเช่าที่ระบุไว้ในหน้าแรกของสัญญาและส่งมอบรถเช่าให้ผู้เช่าและรับรถเช่าคืนจากผู้เช่าตามสัญญาเช่ารถยนต์ระหว่างผู้ให้เช่ารถยนต์และผู้เช่า
            </li>
            <li prefix="3.2.2">
              รถเช่าทั้งหมดในโครงการเป็นกรรมสิทธิ์หรือสิทธิครอบครองของผู้ให้เช่ารถยนต์แต่เพียงฝ่ายเดียว
            </li>
            <li prefix="3.2.3">
              ผู้ให้เช่ารถยนต์มีหน้าที่ต้องดูแลรักษารถเช่าและรับผิดชอบค่าใช้จ่ายในการดำเนินธุรกิจให้เช่ารถยนต์เพื่อให้รถเช่าอยู่ในสภาพพร้อมใช้งานอยู่ตลอดระยะเวลาตามสัญญา
              เพื่อประโยชน์สูงสุดของโครงการ ซึ่งรวมถึงค่าใช้จ่ายอื่น ๆ ที่เกี่ยวข้อง เช่น
              ค่าประกันภัยรถเช่าเชิงพาณิชย์ ค่าล้างรถ ค่าน้ำมัน ค่าน้ำมันเครื่อง ค่ายางรถ
              ค่าภาษีรถยนต์ ค่าบริการอุปกรณ์รายเดือน ค่าสนับสนุนทางการตลาด เป็นต้น
            </li>
            <li prefix="3.2.4">
              ผู้ให้เช่ารถยนต์และผู้ให้บริการแพลตฟอร์มจะร่วมกันประชาสัมพันธ์และทำการตลาด
              รวมทั้งการออกโปรโมชั่นต่างๆ เพื่อผลสำเร็จของโครงการ
              โดยรูปแบบของการประชาสัมพันธ์และทำการตลาด รวมทั้งการออกโปรโมชั่นต่างๆ
              ถือเป็นดุลยพินิจของผู้ให้บริการแพลตฟอร์มแต่เพียงผู้เดียว ทั้งนี้
              ผู้ให้เช่ารถยนต์เป็นผู้รับผิดชอบค่าใช้จ่ายที่เกิดขึ้นจากการประชาสัมพันธ์และทำการตลาด
              รวมทั้งการออกโปรโมชั่นต่างๆ
            </li>
            <li prefix="3.2.5">
              ผู้ให้เช่ารถยนต์จะเป็นผู้รับผิดชอบดูแลและซ่อมแซมรถเช่า
              ในกรณีรถเช่ามีปัญหาในการใช้งานหรือเกิดอุบัติเหตุ โดยต้องทำการซ่อมให้เสร็จภายใน 45
              วันนับตั้งแต่รถยนต์ถูกถอดจากระบบ ทั้งนี้
              ผู้ให้เช่ารถยนต์ยังมีหน้าที่ชำระค่าใช้จ่ายในการดำเนินธุรกิจให้เช่ารถยนต์
            </li>
            <li prefix="3.2.6">
              ผู้ให้เช่ารถยนต์มีสิทธิเข้าถึงข้อมูลอื่น ๆ ที่จำเป็นในการให้บริการได้ เช่น
              การตรวจสอบข้อมูลสถานะรถเช่าจาก GPS ที่ผู้ให้บริการแพลตฟอร์มติดตั้งในรถเช่า
              การรับจองรถเช่า รายได้รถเช่าแต่ละคัน รวมถึงข้อมูลลูกค้าที่ใช้บริการรถเช่า
              และข้อมูลอื่น ๆ โดยผ่านระบบของผู้ให้บริการแพลตฟอร์ม
            </li>
            <li prefix="3.2.7">
              ผู้ให้เช่ารถยนต์จะต้องใช้งานข้อมูลส่วนบุคคลของผู้เช่าตามวัตถุประสงค์เพื่อการบริการยานพาหนะให้เช่าแบบคาร์แชริ่ง
              (Car Sharing) เท่านั้น และมีหน้าที่ควบคุมและดูแลการจัดเก็บ ประมวลผล ส่ง/โอน
              และทำลายข้อมูลส่วนบุคคลของผู้เช่า ตาม พ.ร.บ. คุ้มครองข้อมูลส่วนบุคคล พ.ศ. 2562
            </li>
            <li prefix="3.2.8">
              ผู้ให้เช่ารถยนต์มีหน้าที่อนุมัติการเช่าผ่านระบบของผู้ให้บริการแพลตฟอร์ม ทั้งนี้
              หากพ้นระยะเวลา 24 ชั่วโมงก่อนเวลาเริ่มเช่าแต่ละครั้งแล้ว
              ผู้ให้เช่ารถยนต์มอบหมายให้ผู้ให้บริการแพลตฟอร์มเป็นผู้พิจารณาคุณสมบัติของผู้เช่า
              โดยผู้ให้บริการแพลตฟอร์มไม่ต้องรับผิดหากเกิดความเสียหายอันเกิดจากการอนุมัติการจองแทนผู้ให้เช่ารถยนต์
            </li>
            <li prefix="3.2.9">
              ผู้ให้เช่ารถยนต์มีหน้าที่จัดหาที่ตั้งของพื้นที่จอดรถ
              โดยต้องแจ้งให้ผู้ให้บริการแพลตฟอร์มทราบถึงตำแหน่งที่ตั้งของรถแต่ละคัน
              หรือผู้ให้เช่ารถยนต์สามารถมอบหมายให้ผู้ให้บริการแพลตฟอร์มเป็นตัวกลางในการจัดหาที่จอดรถยนต์ให้กับผู้ให้เช่ารถยนต์
              โดยทั้งสองกรณี ผู้ให้เช่ารถยนต์จะเป็นผู้รับผิดชอบค่าจอดรถ
              ตามอัตราซึ่งระบุไว้ในหน้าแรกของสัญญา
            </li>
            <li prefix="3.2.10">
              หากผู้ให้เช่ารถยนต์ต้องการให้เช่ารถยนต์ประเภท Verified Starter
              ผู้ให้เช่ารถยนต์จะชำระค่าติดตั้งอุปกรณ์ซึ่งระบุในหน้าแรกของสัญญาในวันทำสัญญา
            </li>
            <li prefix="3.2.11">
              กรณีบอกเลิกสัญญาก่อนครบอายุสัญญา
              ผู้ให้เช่ารถยนต์จะต้องชำระค่าถอนการติดตั้งอุปกรณ์เป็นจำนวนเงินตามที่ระบุไว้ในหน้าแรกของสัญญา
            </li>
            <li prefix="3.2.12">
              ผู้ให้เช่ารถยนต์จะชำระค่าบริการช่วยเหลือฉุกเฉินบนท้องถนนและค่าชดเชยงานบริการลูกค้าต่างๆ
              ในกรณีที่ผู้ให้เช่ารถยนต์เป็นฝ่ายก่อให้เกิดความเสียหาย
            </li>
            <li prefix="3.2.13">
              ผู้เช่าต้องชำระค่าใช้จ่ายที่เกี่ยวกับการใช้งานรถยนต์ของผู้เช่าที่หน่วยงานรัฐหรือหน่วยงานผู้มีอำนาจอื่นเรียกเก็บจากผู้ให้เช่ารถยนต์ในภายหลัง
              โดยสามารถระบุวันเวลาที่กระทำความผิดจริง เช่น ค่าปรับการทำผิดกฎจราจร, ค่าทางด่วน,
              ค่าปรับการจอดรถในพื้นที่ห้ามจอด
              และร่วมกับผู้ให้เช่ารถยนต์ติดตามให้ผู้เช่าและบุคคลที่เกี่ยวข้องรับผิดชอบความเสียหายดังกล่าวที่เกิดขึ้น
              ทั้งนี้ หากผู้เช่าไม่ชำระ ผู้ให้เช่ารถยนต์ต้องเป็นผู้รับผิดชอบค่าใช้จ่ายดังกล่าว
            </li>
            <li prefix="3.2.14">
              ผู้ให้เช่ารถยนต์มีหน้าที่ต้องส่งมอบรถยนต์พร้อมน้ำมันเต็มถังให้แก่ผู้เช่า
              และผู้เช่ามีหน้าที่ต้องส่งมอบรถยนต์พร้อมน้ำมันเต็มถังคืนให้แก่ผู้ให้เช่ารถยนต์เมื่อสิ้นสุดสัญญาเช่ารถยนต์ในแต่ละครั้ง
            </li>
          </ol>
        </li>
        <li prefix="3.3">
          <span>สิทธิและหน้าที่ของผู้ให้บริการแพลตฟอร์ม</span>
          <ol>
            <li prefix="3.3.1">
              ผู้ให้บริการแพลตฟอร์มเป็นเจ้าของและมีหน้าที่พัฒนาและบำรุงรักษาระบบให้บริการเช่ารถแบบ
              On Demand (แบบเรียกใช้ได้ตามความต้องการ) ผ่านแอปพลิเคชันชื่อ HAUP
              และมีหน้าที่อัพเดทซอฟต์แวร์ให้เป็นเวอร์ชั่นล่าสุด
            </li>
            <li prefix="3.3.2">
              ในกรณีผู้ให้เช่ารถยนต์ ประเภท Verified Pro และ Verified Franchise
              ผู้ให้บริการแพลตฟอร์มเป็นเจ้าของและมีหน้าที่ติดตั้งอุปกรณ์ที่ใช้เทคโนโลยี เรียกว่า
              Vehicle Tracking and Access (VTA) หรือเทคโนโลยีอื่น ๆ ที่เกี่ยวข้อง
              รวมทั้งอุปกรณ์ควบคุมรถยนต์ในรถเช่าของผู้ให้รถยนต์เช่าทั้งหมดในโครงการเพื่อวัตถุประสงค์และประโยชน์สูงสุดของโครงการ
              ผู้ให้บริการแพลตฟอร์มมีหน้าที่รับผิดชอบค่าใช้จ่ายทั้งหมดในการบำรุงรักษา VTA
              หรือเทคโนโลยีอื่น ๆ ที่เกี่ยวข้อง
              รวมทั้งอุปกรณ์ควบคุมรถยนต์และระบบที่เกี่ยวข้องทั้งหมด
              ในรถยนต์ของผู้ให้เช่ารถยนต์ให้อยู่ในสภาพที่ดีและพร้อมทำงาน
            </li>
            <li prefix="3.3.3">
              ในกรณีผู้ให้เช่ารถยนต์ ประเภท Verified Starter
              ผู้ให้บริการแพลตฟอร์มเป็นเจ้าของและมีหน้าที่ติดตั้งอุปกรณ์ที่ใช้เทคโนโลยี เรียกว่า
              Global Positioning System (GPS) หรือเทคโนโลยีอื่น ๆ ที่เกี่ยวข้อง
              ในรถเช่าของผู้ให้รถยนต์เช่าทั้งหมดในโครงการเพื่อวัตถุประสงค์และประโยชน์สูงสุดของโครงการ
              ผู้ให้บริการแพลตฟอร์มมีหน้าที่รับผิดชอบค่าใช้จ่ายทั้งหมดในการบำรุงรักษา GPS
              หรือเทคโนโลยีอื่น ๆ ที่เกี่ยวข้อง
              ในรถยนต์ของผู้ให้เช่ารถยนต์ให้อยู่ในสภาพที่ดีและพร้อมทำงาน
            </li>
            <li prefix="3.3.4">
              ผู้ให้บริการแพลตฟอร์มมีหน้าที่ดำเนินการแก้ไขระบบหากเกิดความขัดข้องให้สามารถใช้งานได้ภายใน
              48 ชั่วโมง นับจากได้รับแจ้งจากผู้ให้เช่ารถยนต์
              หรือนับจากผู้ให้บริการแพลตฟอร์มทราบเหตุขัดข้องด้วยตนเอง
            </li>
            <li prefix="3.3.5">
              ผู้ให้บริการแพลตฟอร์มจะเป็นผู้รับผิดชอบดูแลและบริการลูกค้าเฉพาะในส่วนการสอบถามผ่านทางโทรศัพท์ในเรื่องของระบบการจองและการเข้าใช้รถเช่าเท่านั้น
            </li>
            <li prefix="3.3.6">
              ผู้ให้บริการแพลตฟอร์มเป็นเจ้าของระบบตลอดจนทรัพย์สินทางปัญญาที่เกี่ยวข้องกับระบบในโครงการทั้งหมด
            </li>
            <li prefix="3.3.7">
              ผู้ให้บริการแพลตฟอร์มมีหน้าที่ทำบัญชีรายรับจากการให้เช่ารถเช่าและค่าใช้จ่ายในการดำเนินธุรกิจให้เช่ารถยนต์ของผู้ให้เช่ารถยนต์
              โดยจะส่งให้ผู้ให้เช่ารถยนต์เช่าตรวจสอบ ภายในวันที่ 7 ของเดือนถัดไป
            </li>
            <li prefix="3.3.8">
              ผู้ให้เช่ารถยนต์มีหน้าที่นำส่งใบแจ้งหนี้ตามบัญชีรายรับจากการให้เช่ารถเช่าและค่าใช้จ่ายในการดำเนินธุรกิจทั้งหมดให้เช่ารถยนต์ของผู้ให้เช่ารถยนต์ให้แก่ผู้ให้บริการแพลตฟอร์ม
              โดยจะส่งให้ผู้ให้บริการแพลตฟอร์ม ภายในวันที่ 15 ของเดือนถัดไป
              ด้วยวิธีการทางอิเล็กทรอนิกส์ใด ๆ
            </li>
            <li prefix="3.3.9">
              ในกรณีที่รถเช่าสูญหายหรือมีเหตุอันควรสงสัยว่าผู้เช่าจะไม่ส่งมอบรถเช่าคืนตามกำหนด
              ผู้ให้บริการแพลตฟอร์มมีหน้าที่ให้ความช่วยเหลือผู้ให้เช่ารถยนต์ในการติดตามรถกลับคืน
              ทั้งด้านการติดตามผ่านระบบที่เกี่ยวข้อง
              และการแจ้งรายละเอียดของผู้เช่าให้ผู้ให้เช่ารถยนต์ทราบ
              ทั้งนี้หากรถยนต์ของผู้ให้เช่ารถยนต์สูญหาย
              ผู้ให้บริการแพลตฟอร์มไม่ต้องรับผิดชอบค่าเสียหายใด ๆ ทั้งสิ้น
            </li>
            <li prefix="3.3.10">
              ผู้ให้บริการแพลตฟอร์มมีหน้าที่แจ้งให้ผู้ให้เช่ารถยนต์ทราบเมื่อทราบว่ารถเช่าเกิดอุบัติเหตุ
              มีรอยเฉี่ยวชน หรือเกิดความเสียหายใด ๆ ก็ตามไม่ว่าจะเป็นความเสียหายเล็กน้อยหรือขนาดใหญ่
              รวมทั้งต้องแจ้งรายละเอียดของการเกิดความเสียหายเท่าที่ผู้ให้บริการแพลตฟอร์มมีข้อมูล
              เช่น ตัวผู้เช่าที่ทำให้เกิดความเสียหาย
              และข้อมูลที่เกี่ยวข้องทั้งหมดให้ผู้ให้เช่ารถยนต์ทราบ
              และผู้ให้บริการแพลตฟอร์มมีหน้าที่ให้ความช่วยเหลือ
              และร่วมกับผู้ให้เช่ารถยนต์ติดตามให้ผู้เช่าและบุคคลที่เกี่ยวข้องรับผิดชอบความเสียหายดังกล่าวที่เกิดขึ้น
            </li>
            <li prefix="3.3.11">
              ผู้ให้บริการแพลตฟอร์มจะต้องตรวจสอบผู้เช่าและกำหนดให้ผู้เช่าปฏิบัติตามข้อกำหนดในการให้เช่ารถยนต์ของผู้ให้บริการ
              รวมทั้งแจ้งให้ผู้เช่ารับทราบและยอมรับว่าหากรถเช่าเกิดอุบัติเหตุ
              หรือได้รับความเสียหายแล้วไม่ได้รับความคุ้มครองตามกรมธรรม์ประกันภัยหรือได้รับความคุ้มครองไม่เต็มจำนวนมูลค่าความเสียหาย
              ผู้เช่าที่กระทำให้เกิดความเสียหายต้องเป็นผู้รับผิดชอบค่าเสียหายทั้งหมด ทั้งนี้
              หากผู้เช่าไม่ชำระ ผู้ให้เช่ารถยนต์ต้องเป็นผู้รับผิดชอบค่าใช้จ่ายดังกล่าว
            </li>
            <li prefix="3.3.12">
              ข้อมูลทางการค้าหรือธุรกิจ หรือความรู้ กระบวนงาน กลยุทธ์ รายชื่อลูกค้า
              รวมทั้งแผนงานของผู้ให้บริการแพลตฟอร์ม
              เป็นทรัพย์สินของผู้ให้บริการแพลตฟอร์มแต่เพียงผู้เดียว
              เว้นแต่ผู้ให้บริการแพลตฟอร์มอนุญาตให้ผู้ให้เช่ารถยนต์เข้าถึงข้อมูลนั้น
            </li>

            <li prefix="3.3.13">
              ผู้ให้บริการแพลตฟอร์มจะนำส่งส่วนแบ่งรายรับในส่วนของผู้ให้เช่ารถยนต์ตามที่ระบุไว้ในหน้าแรกของสัญญาให้ผู้ให้เช่ารถยนต์
              หลังจากหักภาษีมูลค่าเพิ่มและค่าใช้จ่ายในการดำเนินธุรกิจให้เช่ารถยนต์ของผู้ให้เช่ารถยนต์เรียบร้อยแล้ว
            </li>
          </ol>
        </li>
      </ol>
      <p className="bold topic">ข้อ 4. การรักษาความลับ</p>
      <p className="indent">
        ข้อมูลต่าง ๆ ของคู่สัญญาเกี่ยวกับการค้าหรือธุรกิจ หรือความรู้ กระบวนงาน กลยุทธ์
        รายชื่อลูกค้า รวมทั้งแผนงาน
        ซึ่งคู่สัญญาอีกฝ่ายได้รับทราบอันเนื่องมาจากการปฏิบัติที่เกี่ยวข้องกับสัญญานี้
        คู่สัญญาแต่ละฝ่ายมีหน้าที่เก็บรักษาข้อมูลดังกล่าวไว้เป็นความลับอย่างดี
        และจะไม่นำไปเปิดเผยหรือเผยแพร่ต่อหน่วยงานใด ๆ บุคคลภายนอก สาธารณชน หรือผ่านทางโซเชียล
        เน็ตเวิร์ค ยกเว้นในกรณีที่จำเป็นต้องเปิดเผยข้อมูลตามบทบังคับของกฎหมาย
        หรือเพื่อประโยชน์ร่วมกันของคู่สัญญาตามสัญญานี้
      </p>
      <p className="indent">
        เมื่อสัญญาฉบับนี้สิ้นสุดลงไม่ว่าด้วยเหตุใด คู่สัญญาแต่ละฝ่ายจะต้องส่งคืนข้อมูลดังกล่าว
        รวมทั้งสำเนาทั้งหมด ให้คู่สัญญาอีกฝ่ายภายใน 15 (สิบห้า) วันนับแต่วันที่สัญญาสิ้นสุดลง
      </p>
      <p className="bold topic">ข้อ 5. การเลิกสัญญา</p>
      <p className="indent">
        5.1 หากคู่สัญญาฝ่ายใดฝ่ายหนึ่งไม่ปฏิบัติตามข้อตกลงใดข้อตกลงหนึ่งในสัญญานี้
        คู่สัญญาอีกฝ่ายหนึ่งมีสิทธิที่จะเรียกร้องเป็นลายลักษณ์อักษรให้คู่สัญญาฝ่ายที่ผิดสัญญา
        ทำการแก้ไขหรือปฏิบัติให้ถูกต้องตามสัญญา
        หากคู่สัญญาฝ่ายนั้นไม่กระทำการแก้ไขภายในระยะเวลาดังกล่าว
        คู่สัญญาอีกฝ่ายมีสิทธิบอกเลิกสัญญาได้ทันที
        รวมทั้งเรียกร้องให้คู่สัญญาฝ่ายที่ผิดสัญญาชดใช้ค่าเสียหายที่เกิดขึ้นทั้งหมด
      </p>
      <p className="indent">
        5.2 คู่สัญญามีสิทธิบอกเลิกสัญญาฉบับนี้ได้ก่อนครบอายุสัญญา
        โดยต้องบอกกล่าวเป็นลายลักษณ์อักษรไปยังคู่สัญญาอีกฝ่ายล่วงหน้าอย่างน้อย 60 วัน
      </p>
      <p className="indent">
        ทั้งนี้หากผู้ให้เช่ารถยนต์ยกเลิกสัญญาก่อนครบอายุสัญญาดังกล่าวต้องเสียค่าใช้จ่ายให้กับผู้ให้บริการแพลตฟอร์มตามรายละเอียดแนบท้ายสัญญานี้
      </p>
      <p className="bold topic">ข้อ 6. การดำเนินการของคู่สัญญาในกรณีที่สัญญาสิ้นสุด</p>
      <ol>
        <li prefix="6.1">
          <span>
            ในกรณีที่สัญญาสิ้นสุดลงไม่ว่าด้วยเหตุใดก็ตาม ผู้ให้เช่ารถยนต์ตกลงจะดำเนินการต่อไปนี้
          </span>
          <ol>
            <li prefix="6.1.1">
              หยุดใช้ระบบของผู้ให้บริการแพลตฟอร์มทันทีและส่งคืนชุดข้อมูลที่เกี่ยวข้องทั้งหมดคืนแก่ผู้ให้บริการแพลตฟอร์ม
            </li>
            <li prefix="6.1.2">หยุดใช้ชื่อและเครื่องหมายการค้าของผู้ให้บริการแพลตฟอร์มในทันที</li>
            <li prefix="6.1.3">
              หากมีการติดตั้งอุปกรณ์ของผู้ให้บริการแพลตฟอร์มในรถเช่า
              ผู้ให้เช่ารถยนต์ต้องนำรถยนต์ที่ติดตั้งอุปกรณ์ทั้งหมดส่งมอบให้ผู้ให้บริการแพลตฟอร์มถอดอุปกรณ์และส่งมอบอุปกรณ์ทั้งหมดคืนให้แก่ผู้ให้บริการแพลตฟอร์ม
              หากไม่ส่งคืนหรือนำรถยนต์มาเพื่อถอดอุปกรณ์ภายใน 15 วันหลังสิ้นสุดสัญญา
              ผู้ให้เช่ารถยนต์ต้องรับผิดชดใช้ค่าเสียหายให้แก่ผู้ให้บริการแพลตฟอร์ม
            </li>
          </ol>
        </li>
        <li prefix="6.2">
          <span>
            ในกรณีที่สัญญาสิ้นสุดลงไม่ว่าด้วยเหตุใดก็ตาม
            ผู้ให้บริการแพลตฟอร์มตกลงจะดำเนินการต่อไปนี้
          </span>
          <ol>
            <li prefix="6.2.1">หยุดใช้ชื่อและเครื่องหมายการค้าของผู้ให้เช่ารถยนต์ในทันที</li>
            <li prefix="6.2.2">
              จัดทำบัญชีจนถึงวันสิ้นสุดสัญญาและส่งมอบรายรับที่ผู้ให้บริการแพลตฟอร์มยังค้างชำระให้แก่ผู้ให้รถยนต์ภายใน
              30 วัน หลังสิ้นสุดสัญญา
            </li>
          </ol>
        </li>
      </ol>

      <p className="bold topic">ข้อ 7. การบอกกล่าว</p>
      <p className="indent">
        การบอกกล่าว ทวงถาม หรือส่งเอกสารใด ๆ ของคู่สัญญาตามสัญญานี้
        ไม่ว่าจะส่งโดยวิธีการใดตามที่อยู่ที่ปรากฎข้างต้น ให้ถือว่าได้ส่งโดยชอบแล้ว
        และคู่สัญญาอีกฝ่ายได้ทราบแล้วในวันที่คำบอกกล่าว ทวงถามหรือเอกสารนั้นพึงไปถึงตามปกติ
      </p>
      <p className="bold topic">ข้อ 8. สัญญาเป็นโมฆะ</p>
      <p className="indent">
        ในกรณีที่ปรากฏในภายหลังว่าข้อกำหนดและเงื่อนไขส่วนหนึ่งส่วนใดของสัญญานี้เป็นโมฆะ
        คู่สัญญาทั้งสองฝ่ายตกลงให้ถือว่าข้อกำหนดและเงื่อนไขส่วนที่เป็นโมฆะดังกล่าวนั้นไม่กระทบกระเทือนถึงความสมบูรณ์และมีผลบังคับใช้ของข้อกำหนดและเงื่อนไขส่วนอื่น
        ๆ ของสัญญานี้
      </p>
    </section>
  )
}
