import { CloseOutlined } from '@ant-design/icons'
import { Col, Divider, Drawer, message, Row, Space, Typography } from 'antd'
import promiseRetry from 'promise-retry'
import { useEffect, useState } from 'react'
import { createRoot } from 'react-dom/client'
import OTPInput from 'react-otp-input'
import { theme } from '../../config/style'
import { LoginSuccess, RegisterOTPVerifySuccess } from '../../services/auth/auth.interfaces'
import { instanceOfRegisterOTPVerifySuccess } from '../../services/auth/instanceof.function'
import { loginVerifyMobileOtp } from '../../services/auth/login.service'
import { registerVerifyMobileOtp } from '../../services/auth/register.service'
import { Heading2 } from '../StyledComponents/StyledComponents'
import { DrawerProps } from './CustomDrawer.interfaces'

export interface OTPDrawerProps extends DrawerProps {
  otpLength?: number
  onInputFinish?: (otp: string) => void
  title?: string
  mobileNumber?: string
  refCode?: string
  onResend?: () => void
}

export const OTPDrawer = ({ otpLength = 4, ...props }: OTPDrawerProps) => {
  const onClose = props.onClose ? props.onClose : () => null
  const [value, setValue] = useState('')
  const [open, setOpen] = useState<boolean>()

  function handleBeforeClose() {
    setOpen(false)
    setTimeout(() => {
      onClose()
    }, 200)
  }

  useEffect(() => {
    if (value.length === otpLength) {
      setOpen(false)
      setTimeout(() => {
        props.onInputFinish && props.onInputFinish(value)
      }, 200)
    }
  }, [value])

  return (
    <Drawer
      open={open ?? props.open}
      onClose={() => handleBeforeClose()}
      placement="bottom"
      getContainer={false}
      height="50%"
      headerStyle={{
        borderBottom: 0,
        zIndex: 1 /* boxShadow: '0px 2px 8px 0px rgba(0,0,0,0.25)' */,
      }}
      title={
        <Row justify="center" align="middle" style={{ marginTop: '1rem' }}>
          <Col span={2}></Col>
          <Col span={20} style={{ textAlign: 'center' }}>
            <Heading2 style={{ color: theme.haupBlue }}>{props.title || 'OTP'}</Heading2>
          </Col>
          <Col span={2}>
            <CloseOutlined
              style={{ fontSize: '20px', color: '#00000050' }}
              onClick={() => handleBeforeClose()}
            />
          </Col>
        </Row>
      }
      contentWrapperStyle={{
        borderRadius: '20px 20px 0 0',
        overflow: 'hidden',
      }}
      drawerStyle={{ background: theme.haupBlueSecondary }}
      closeIcon={null}
      // maskStyle={{ background: 'transparent' }}
      bodyStyle={{ position: 'relative' }}
      style={{
        maxWidth: '500px',
        margin: '0 auto',
      }}
    >
      <Space direction="vertical" className="color-primary text-center w-100">
        <Typography.Paragraph className="color-primary text-center">
          {props.mobileNumber && `Enter the OTP sent to ${props.mobileNumber}`}
        </Typography.Paragraph>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <OTPInput
            value={value}
            onChange={(v: string) => (isNaN(Number(v)) ? null : setValue(v))}
            numInputs={4}
            isInputNum={true}
            containerStyle={{
              width: '320px',
              justifyContent: 'space-evenly',
              gap: '8px',
            }}
            inputStyle={{
              width: '100%',
              height: '80px',
              fontSize: '40px',
              borderRadius: '10px',
              border: 0,
            }}
          />
        </div>
        <Typography.Paragraph type="secondary">
          {props.refCode && `Reference code: ${props.refCode}`}
        </Typography.Paragraph>
        <Divider style={{ display: 'none' }} />
        <Typography.Paragraph>
          {props.onResend && (
            <>
              Didn't receive it?{' '}
              <a onClick={props.onResend}>
                <u>Resend</u>
              </a>
            </>
          )}
        </Typography.Paragraph>
      </Space>
    </Drawer>
  )
}

type AwaitOTPDrawerOptions = Pick<
  OTPDrawerProps,
  'otpLength' | 'title' | 'mobileNumber' | 'refCode' | 'onResend'
>

export function popOTPDrawer(
  container: HTMLDivElement | null,
  drawerOptions?: AwaitOTPDrawerOptions,
): Promise<string> {
  return new Promise((resolve, reject) => {
    if (!container) {
      return reject('Container Not Found.')
    }
    const root = createRoot(container)
    async function beforeResolve(otp: string) {
      return detachResolve(otp)
    }
    function detachResolve(otp = '') {
      root.unmount()
      return resolve(otp)
    }
    root.render(
      <OTPDrawer
        {...drawerOptions}
        open={true}
        onClose={detachResolve}
        onInputFinish={beforeResolve}
      />,
    )
  })
}

export async function popRetryableLoginVerifyOtp(
  container: HTMLDivElement,
  otpParams: { mobile: string; refCode: string },
): Promise<LoginSuccess> {
  return new Promise((resolve, reject) => {
    promiseRetry<LoginSuccess>(
      async function (retry, attempts) {
        if (attempts > 5) {
          throw 'Exceed 5 attempts. Please try again.'
        }
        const otpValue = await popOTPDrawer(container, {
          otpLength: 4,
          title: 'Login',
          mobileNumber: otpParams.mobile,
          refCode: otpParams.refCode,
        })
        if (otpValue === '') {
          throw 'Cancel'
        }
        const otpResult = await loginVerifyMobileOtp(otpParams.refCode, otpValue).catch((error) => {
          message.error(error)
          retry(error)
        })
        // function fakeValidate(): Promise<LoginSuccess> {
        //   return new Promise((rs, rj) => {
        //     const even = Math.floor(Math.random() * 100) % 2 === 0
        //     setTimeout(() => {
        //       if (even) {
        //         return rj('Even!')
        //       }
        //       rs({ accessToken: 'a', userData: {}, expireAt: '2020', adminGroup: null })
        //     }, 1000)
        //   })
        // }
        // const otpResult = await fakeValidate().catch((error) => {
        //   message.error(error)
        //   retry(error)
        // })
        if (!otpResult?.accessToken || !otpResult?.userData) {
          throw 'Invalid OTP data. Please try again'
        }
        return otpResult
      },
      { minTimeout: 0 },
    )
      .then((otp) => resolve(otp))
      .catch((reason) => reject(reason))
  })
}

export async function popRetryableRegisterVerifyOtp(
  container: HTMLDivElement,
  otpParams: { mobile: string; refCode: string },
): Promise<RegisterOTPVerifySuccess> {
  return new Promise((resolve, reject) => {
    promiseRetry<RegisterOTPVerifySuccess>(
      async function (retry, attempts) {
        if (attempts > 5) {
          throw 'Exceed 5 attempts. Please try again.'
        }
        const otpValue = await popOTPDrawer(container, {
          otpLength: 4,
          title: 'Register',
          mobileNumber: otpParams.mobile,
          refCode: otpParams.refCode,
        })
        if (otpValue === '') {
          throw null
        }
        const otpResult = await registerVerifyMobileOtp(otpParams.refCode, otpValue).catch(
          (error) => {
            message.error(error)
            retry(error)
          },
        )
        if (instanceOfRegisterOTPVerifySuccess(otpResult)) {
          return otpResult
        }
        throw 'Invalid OTP data. Please try again'
      },
      { minTimeout: 0 },
    )
      .then((otp) => resolve(otp))
      .catch((reason) => reject(reason))
  })
}
