import axios from 'axios'
import config from '../../config'
import { UserLoggedInType } from '../submission'
const { restful } = config

interface IFreshchatRestore {
  _id: string
  userId: number
  restoreId: string
  createdAt: string
  updatedAt: string
  __v: number
}

export async function createRestoreId(
  userLoggedIn: UserLoggedInType,
  restoreId: string,
): Promise<IFreshchatRestore> {
  try {
    const response = await axios.post(
      `${restful.URL}/freshchat/restore`,
      { restoreId },
      { headers: { Authorization: userLoggedIn.userToken } },
    )
    return response.data
  } catch (error: any) {
    if (error?.response?.data?.message) {
      throw error?.response?.data?.message
    }
    throw error
  }
}

export async function getRestoreId(
  userLoggedIn: UserLoggedInType,
): Promise<IFreshchatRestore | null> {
  try {
    const response = await axios.get(`${restful.URL}/freshchat/restore`, {
      headers: { Authorization: userLoggedIn.userToken },
    })
    return response.data
  } catch (error: any) {
    if (error?.response?.data?.message) {
      throw error?.response?.data?.message
    }
    throw error
  }
}
