import { FormDataState } from './FormDataReducer'
export type SubmissionActionType = 'SET_SUBMISSION'
export default (
  state: FormDataState[] = [],
  action: { type: SubmissionActionType; payload: any },
): typeof state => {
  switch (action.type) {
    case 'SET_SUBMISSION': {
      return action.payload
    }
    default: {
      return state
    }
  }
}
