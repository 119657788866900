export const UIText = {
  TH: {
    LAYOUT: {
      login: 'ลงชื่อเข้าใช้',
      signup: 'สมัครสมาชิก',
      logout: 'ลงชื่อออก',
      save: 'บันทึก',
    },
    STEPS: [
      'หน้าแรก',
      'ข้อมูลทั่วไปของเจ้าของรถ',
      'ข้อตกลงการส่งข้อมูลรถ',
      'ข้อมูลเกี่ยวกับตัวรถ',
      'รูปภาพรถ',
      'ประเภทการบริการ',
      'เลือกแพคเกจเจ้าของรถ',
      'ข้อมูลประกันภัย',
      'จุดจอดรถ',
      'ช่วงเวลาติดต่อกลับ',
      'ช่องทางการชำระเงิน',
      'ภาพรวม',
    ],
    UPLOAD: { placeholder: 'อัพโหลด', editTitle: 'ปรับแต่งรูปภาพ', cancel: 'ยกเลิก', ok: 'ตกลง' },

    BUTTON: {
      back: 'กลับ',
      skip: 'ข้าม',
      next: 'ต่อไป',
      submit: 'ยืนยัน',
    },
    subHeader: { carsharing: 'สำหรับ คาร์แชร์ริ่ง', carrental: 'สำหรับ ปล่อยแบบรถเช่า' },
    AUTH_PAGE: {},
    LOGIN_PAGE: {
      username: { label: 'บัญชีผู้ใช้', placeholder: '' },
      password: { label: 'รหัสผ่าน', placeholder: '' },
      forgotPassword: 'ลืมรหัสผ่าน ?',
      login: 'เข้าสู่ระบบ',
      or: 'หรือ',
      loginWith: 'เข้าสู่ระบบผ่าน',
      MODAL: {
        email: { label: 'อีเมล', placeholder: '' },
        resetPassword: 'รีเซ็ตรหัสผ่าน',
      },
    },
    SIGNUP_PAGE: {
      name: { label: 'ชื่อ', placeholder: '' },
      familyName: { label: 'นามสกุล', placeholder: '' },
      email: { label: 'อีเมล', placeholder: '' },
      telephone: { label: 'หมายเลขโทรศัพท์ที่ติดต่อได้', placeholder: '' },
      password: { label: 'รหัสผ่าน', placeholder: '' },
      password2: { label: 'ยืนยันรหัสผ่าน' },
      terms: 'ฉันยินดีที่จะรับข่าวสารและโปรโมชั่นต่างๆจาก Haupcar ผ่านช่องทาง Email address ของฉัน',
      signup: 'สมัครสมาชิก',
    },
    STEP1_ACTION: {
      header: 'พร้อมหรือยังที่จะให้รถของคุณทำเงินให้กับคุณ',
      newVehicle: 'เพิ่มรถเข้าในระบบ',
      continue: 'ทำรายการที่มีอยู่แล้วต่อให้สำเร็จ',
      listed: 'รถที่อยู่ในระบบกับเราแล้ว',
    },
    STEP2_IDCARD_UPLOAD: {
      header: 'ข้อมูลทั่วไป',
      idCardUpload: 'บัตรประจำตัวประชาชน',
      houseRegistrationUpload: 'สำเนาทะเบียนบ้าน',
      MODAL: {
        title:
          'เพิ่มช่องทางสำหรับเก็บภาพสำเนาทะเบียนบ้านเพื่อใช้ทำสัญญาเช่าจุดจอดหรือเช่าพื้นที่จอด โดยข้อมูลจะถูกเปิดเผยต่อคู่ค้าของ Haup เท่านั้น',
        idCardDemo: 'ตัวอย่างภาพบัตรประชาชน',
        houseRegistrationDemo: 'ตัวอย่างภาพสำเนาทะเบียนบ้าน',
      },
    },
    STEP3_TERMS: {
      header: 'ข้อตกลงในการใช้บริการ',
      terms1:
        'ฉันเป็นเจ้าของรถ ได้อ่านและรับทราบเงื่อนไขการปล่อยเช่ารถผ่านแพลตฟอร์ม Haup โดยใช้ Haup เป็นช่องทางในการหาผู้เช่า',
      terms2:
        'ฉันตกลงจะเก็บรักษาข้อมูลที่ฉันกำลังจะได้รับจาก Haup เป็นความลับและไม่เปิดเผยขั้นตอนและเงื่อนไขการเข้าร่วมระบบฯ ก่อนได้รับอนุญาตจากทางแพลตฟอร์ม',
    },
    STEP4_VEHICLE_INFO: {
      header: 'ข้อมูลรถของคุณ',
      vehicleBrand: { label: 'ยี่ห้อรถ', placeholder: '' },
      vehicleModel: { label: 'รุ่น', placeholder: '' },
      vehicleYear: { label: 'ปี', placeholder: '' },
      vehicleType: { label: 'ประเภท', placeholder: '' },
      transmissionType: { label: 'เกียร์', manual: 'ธรรมดา', auto: 'อัตโนมัติ' },
      MODAL: {
        title: 'กรอกข้อมูลเบื้องต้นของรถที่คุณจะนำมาปล่อยเช่ากับเรา',
        info: [{ attr: 'ปีรถ', message: ['บอกอายุจริงของรถ ไม่ใช่ปีที่จดทะเบียน'] }],
      },
    },
    STEP5_VEHICLE_PHOTO: {
      VEHICLE: {
        header: 'ภาพถ่ายรถของคุณ',
        front: 'ด้านหน้า',
        back: 'ด้านหลัง',
        left: 'ด้านซ้าย',
        right: 'ด้านขวา',
        MODAL: {
          title:
            'เราจำเป็นจะต้องเก็บเอกสารสำคัญบางอย่างเพื่อใช้ประสานงานและอำนวยความสะดวกให้แก่ผู้เช่า',
        },
      },
      DOCS: {
        header: 'เอกสารของรถ',
        vehicleRegistrationBook: 'เล่มทะเบียนรถ',
        MODAL: {
          title:
            'เราจำเป็นจะต้องเก็บเอกสารสำคัญบางอย่างเพื่อใช้ประสานงานและอำนวยความสะดวกให้แก่ผู้เช่า',
        },
      },
    },
    STEP6_SERVICE_TYPE: {
      header: 'รูปแบบการปล่อยเช่า',
      CARSHARING: {
        title: 'คาร์แชร์ริ่ง',
        subTitle: 'จองรถใช้ได้ทันที',
        body: 'เหมาะสำหรับเจ้าของรถที่สามารถปล่อยเช่ารถได้ตลอดเวลา โดยให้ผู้ใช้รับรถได้ด้วยตัวเองและไม่ต้องส่งมอบกุญแจรถ',
      },
      CARRENTAL: {
        title: 'ปล่อยเช่าแบบรถเช่า',
        subTitle: 'ผู้ใช้จองล่วงหน้า',
        body: 'เหมาะสำหรับเจ้าของรถที่ยังใช้รถอยู่ในชีวิตประจำวัน เจ้าของรถจะต้องส่งมอบกุญแจรถให้กับผู้ที่มาเช่า',
      },
      MODAL: {
        title:
          'เลือกประเภทการปล่อยเช่าที่เหมาะสมกับการใช้งานรถของคุณโดยเลือกจากลักษณะการใช้งานรถของคุณตามปกติ',
        text1: 'หากเป็นรถที่ใช้อยู่ในชีวิตประจำวัน Haup แนะนำการปล่อยเช่าแบบรถเช่าทั่วไป',
        text2: 'หากเป็นรถที่ไม่ค่อยได้ใช้ แนะนำให้ปล่อยเช่าแบบคาร์แชร์ริ่ง',
      },
    },
    STEP7_PACKAGE: {
      header: 'เลือกแพคเกจการปล่อยเช่า',
      selectButton: 'เลือก',
      recommend: 'แนะนำ',
      STARTER: {
        title: 'Starter',
        subTitle: 'ส่วนแบ่งรายได้สำหรับเจ้าของรถ 70%',
        equipment: { label: 'อุปกรณ์', value: '-' },
        minimum: { label: 'ปล่อยเช่าขั้นต่ำ', value: 'รายวัน' },
        preBook: { label: 'จองใช้ล่วงหน้า', value: '24 ชม. ล่วงหน้า' },
        unlockType: { label: 'การปลดล็อครถ', value: 'กุญแจรถ' },
        insurance: { label: 'ประกันเชิงพาณิชย์', value: 'รายวัน' },
        techFee: { label: 'ค่าบริการเทคโนโลยี', value: '-' },
        installationFee: { label: 'ค่าติดตั้ง', value: '-' },
        revenueShare: { label: 'ส่วนแบ่งรายได้สำหรับเจ้าของรถ', value: '70%' },
      },
      VERIFIRED_STARTER: {
        title: 'Verified Starter',
        subTitle: 'ส่วนแบ่งรายได้สำหรับเจ้าของรถ 70%',
        equipment: { label: 'อุปกรณ์', value: 'HAUP GPS' },
        minimum: { label: 'ปล่อยเช่าขั้นต่ำ', value: 'รายวัน' },
        preBook: { label: 'จองใช้ล่วงหน้า', value: '24 ชม. ล่วงหน้า' },
        unlockType: { label: 'การปลดล็อครถ', value: 'กุญแจรถ' },
        insurance: { label: 'ประกันเชิงพาณิชย์', value: 'รายวัน' },
        techFee: { label: 'ค่าบริการเทคโนโลยี', value: '490 บาท*' },
        installationFee: { label: 'ค่าติดตั้ง', value: '1,000 บาท*' },
        revenueShare: { label: 'ส่วนแบ่งรายได้สำหรับเจ้าของรถ', value: '70%' },
      },
      VERIFIED_PRO: {
        title: 'Verified Pro',
        subTitle: 'ส่วนแบ่งรายได้สำหรับเจ้าของรถ 75%',
        equipment: { label: 'อุปกรณ์', value: 'HAUP IoT, GPS' },
        minimum: { label: 'ปล่อยเช่าขั้นต่ำ', value: 'รายชั่วโมง' },
        preBook: { label: 'จองใช้ล่วงหน้า', value: 'เช่าได้ทันที' },
        unlockType: { label: 'การปลดล็อครถ', value: 'ผ่านแอป HAUP, HAUPCARD' },
        insurance: { label: 'ประกันเชิงพาณิชย์', value: 'รายปี' },
        techFee: { label: 'ค่าบริการเทคโนโลยี', value: '790 บาท*' },
        installationFee: { label: 'ค่าติดตั้ง', value: '1,000 บาท*' },
        revenueShare: { label: 'ส่วนแบ่งรายได้สำหรับเจ้าของรถ', value: '75%' },
      },
      MODAL: {
        title: 'เลือกแพคเกจที่เหมาะสมกับตัวคุณมากที่สุด',
        subTitle: {
          carsharing:
            'สำหรับ คาร์แชร์ริ่ง แพคเกจ Verified Starter / Verify Pro เป็นแพคเกจที่เราแนะนำเพราะจะทำให้ลูกค้าสามารถจองและใช้รถได้ทันที',
          carrental:
            'สำหรับ การปล่อยเช่าแบบรถเช่า แพคเกจ Starter เป็นแพคเกจที่เหมาะสำหรับการปล่อยเช่าแบบรายวันเป็นต้นไป โดยเจ้าของรถมีหน้าที่ส่งมอบกุญแจ และนัดรับส่งรถ.',
        },
        packages: [
          {
            packageTitle: 'Starter | Verified Starter',
            pros: [
              'ไม่เสียค่าแรกเข้าใดๆ',
              'สามารถทำประกันพาณิชย์แบบรายวันได้',
              'มีอุปกรณ์ GPS สามารถติดตามรถของคุณได้ตลอดเวลา',
            ],
          },
          // {
          //   packageTitle: 'Verified Starter',
          //   pros: [
          //     'มีอุปกรณ์ GPS สามารถติดตามรถของคุณได้ตลอดเวลา',
          //     'สามารถทำประกันพาณิชย์แบบรายวันได้',
          //   ],
          // },
          {
            packageTitle: 'Verified Pro',
            pros: [
              'มีอุปกรณ์ GPS, HAUP IoT สามารถติดตามรถของคุณและดูข้อมูลเชิงลึกกับรถของคุณได้ผ่านระบบของเรา',
              'ลูกค้าสามารถจองและใช้รถของคุณได้ทันที ทำให้เพิ่มโอกาสสร้างรายได้ได้มากกว่า',
              'ลูกค้าสามารถเช่ารถของคุณเป็นรายชั่วโมงได้',
              'ลูกค้าสามารถปลดล็อครถของคุณได้ผ่าน Haupcard, Haup Application ทำให้กุญแจของคุณยังอยู่ในรถตลอดเวลา',
              'ได้รับส่วนแบ่งรายได้ที่มากชึ้น',
            ],
          },
        ],
      },
    },
    STEP8_INSURANCE: {
      header: 'ประกันพาณิชย์',
      inputTitle: 'รถของคุณมีประกันพาณิชย์ไหม ?',
      yes: 'มี',
      no: 'ไม่มี',
      uploadTitle: 'อัพโหลดประกันพาณิชย์ของท่าน',
      insuranceApplyType: 'ท่านต้องการสมัครประกันพาณิชย์แบบไหน',
      daily: {
        label: 'แบบรายวัน',
        body: 'ประกันภัยรายวัน จะใช้กับรถที่ปล่อยเช่าแบบที่ต้องใช้ล่วงหน้า โดยผู้ใช้จ่ายเป็นรายครั้งตามรายการที่เกิดขึ้น',
      },
      annual: {
        label: 'แบบรายปี',
        body: 'ประกันภัยแบบรายปี ความคุ้มครองครอบคลุมตลอดทั้งปี ลักษณะคล้ายประกันฯเชิงพาณิชย์ทั่วไปในเรทราคาพิเศษจาก Haup และพาร์ทเนอร์',
      },
      MODAL: {
        header:
          'การนำรถเข้ามาใช้ในทางพาณิชย์ ท่านจำเป็นจะต้องมีประกันพาณิชย์เพื่อคุ้มครองตัวรถในกรณีที่เกิดเหตุสุดวิสัย',
        STARTER: {
          title: 'สำหรับเจ้าของรถที่เลือกแพคเกจ Starter',
          text: 'ในการปล่อยเช่าแบบรถเช่าทั่วไป จำเป็นจะต้องมีประกันภัยเชิงพาณิชย์ โดย Haup สามารถจัดหาข้อเสนอพิเศษให้คุณสามารถซื้อประกันฯได้แบบรายครั้ง โดยมีเงื่อนไขในการตรวจสอบข้อมูลก่อนการใช้งานจริงของผู้เช่าอย่างน้อย 24 ชั่วโมง',
        },
        VERIFIED_STARTER: {
          title: 'สำหรับเจ้าของรถที่เลือกแพคเกจ Verify Starter',
          text: 'ในการปล่อยเช่าแบบรถเช่าทั่วไป จำเป็นจะต้องมีประกันภัยเชิงพาณิชย์ โดย Haup สามารถจัดหาข้อเสนอพิเศษให้คุณสามารถซื้อประกันฯได้แบบรายครั้ง โดยมีเงื่อนไขในการตรวจสอบข้อมูลก่อนการใช้งานจริงของผู้เช่าอย่างน้อย 24 ชั่วโมง',
        },
        VERIFIED_PRO: {
          title: 'สำหรับท่านที่เลือกแพคเกจ Verify Pro',
          text: 'ทางบริษัทจำเป็นจะต้อง ให้ผู้ที่นำรถมาปล่อยเช่ากับเรา ทำประกันพาณิชย์แบบรายปีเพื่อที่ผู้ปล่อยเช่าจะไม่ต้องดำเนินการใดๆ สำหรับการปล่อยเช่า ลูกค้าของผู้ปล่อยเช่าจะสามารถกดจองและเข้ามาใช้รถของท่าน ด้วย Haupcard / HaupApplication ได้โดยไม่ต้องมีการส่งมอบกุญแจใดๆ',
        },
      },
    },
    STEP9_PARKING: {
      header: 'จุดจอดรถ',
      inputTitle: 'คุณมีที่จอดสำหรับรถที่คุณต้องการจะปล่อยเช่าไหม',
      yes: 'มี',
      no: 'ไม่มี',
      parkingMapTitle: 'เลือกจุดจอดรถของคุณ',
      MODAL: {
        text: 'เราจำเป็นจะต้องมีที่จอดรถประจำให้กับรถของคุณ ไม่ต้องกังวลหากคุณไม่มีที่จอดรถ ทาง Haup จะจัดหาที่จอดให้กับรถของคุณ',
        text2:
          'เราจำเป็นจะต้องมีที่จอดรถประจำให้กับรถของคุณ ไม่ต้องกังวลหากคุณไม่มีที่จอดรถ ทาง Haup จะจัดหาที่จอดให้กับรถของคุณ',
      },
    },
    STEP10_CONTACT_TIME: {
      header: 'ช่วงเวลาติดต่อกลับ',
      inputTitle: 'ช่วงเวลาที่สะดวกให้ติดต่อกลับ ?',
      smallTitle: 'สามารถเลือกได้มากกว่า 1 ช่วงเวลา',
      TIME_LIST: [
        { label: '9.00 น. - 11.00 น.', value: '9.00 น. - 11.00 น' },
        { label: '11.00 น. - 13.00 น.', value: '11.00 น. - 13.00 น.' },
        { label: '13.00 น. - 15.00 น.', value: '13.00 น. - 15.00 น.' },
        { label: '15.00 น. - 17.00 น.', value: '15.00 น. - 17.00 น.' },
        { label: '17.00 น. - 19.00 น.', value: '17.00 น. - 19.00 น.' },
      ],
      MODAL: {
        text: 'เซลล์ของเราจำเป็นต้องติดต่อให้ผู้เช่ารถเพื่อพูดคุยเกี่ยวกับการนำรถเข้ามาติดตั้งอุปกรณ์และเรื่องอื่นๆประมาณ 5-20 นาที ท่านสามารถถามข้อสงสัยต่างๆ กับเซลล์ของเราได้ เซลล์ของเราทำการในช่วงเวลา 9:00 น - 20:00 น. วันจันทร์ - วันอาทิตย์ เว้นวันหยุดราชการ',
      },
    },
    STEP11_PAYMENT: {
      header: 'วิธีการชำระเงิน',
      inputTitle: 'อัพโหลดหน้าสมุดบัญชีธนาคาร / ภาพบันทึกหน้าจอของแอปพลิเคชั่นธนาคาร',
      MODAL: {
        title:
          'อัพโหลดภาพหรือสำเนาสมุดบัญชีหน้าแรก หรือภาพบันทึกหน้าจอของแอปพลิเคชั่นธนาคารที่บอกเลขบัญชีธนาคารเพื่อเป็นช่องทางการรับเงิน',
        account: 'ตัวอย่างภาพสมุดบัญชี',
        eAccount: 'ตัวอย่างภาพบันทึกหน้าจอของแอปพลิเคชั่นธนาคาร',
      },
    },
    STEP12_SUBMIT: {
      header: 'รายการตรวจสอบ',
      incomplete: 'ทำต่อ',
      completed: 'เรียบร้อย',
    },
  },
  EN: {
    LAYOUT: {
      login: 'ลงชื่อเข้าใช้',
      signup: 'สมัครสมาชิก',
      logout: 'ลงชื่อออก',
      save: 'บันทึก',
    },
    STEPS: [
      'ข้อมูลทั่วไปของเจ้าของรถ',
      'ข้อตกลงการส่งข้อมูลรถ',
      'ข้อมูลเกี่ยวกับตัวรถ',
      'รูปภาพรถ',
      'ประเภทการบริการ',
      'เลือกแพคเกจเจ้าของรถ',
      'ข้อมูลประกันภัย',
      'จุดจอดรถ',
      'ช่วงเวลาติดต่อกลับ',
      'ช่องทางการชำระเงิน',
    ],
    UPLOAD: { placeholder: 'อัพโหลด', editTitle: 'ปรับแต่งรูปภาพ', cancel: 'ยกเลิก', ok: 'ตกลง' },
    BUTTON: {
      back: 'กลับ',
      skip: 'ข้าม',
      next: 'ต่อไป',
      submit: 'ยืนยัน',
    },
    subHeader: { carsharing: 'สำหรับ คาร์แชร์ริ่ง', carrental: 'สำหรับ ปล่อยแบบรถเช่า' },
    AUTH_PAGE: {},
    LOGIN_PAGE: {
      username: { label: 'Username', placeholder: '' },
      password: { label: 'Password', placeholder: '' },
      forgotPassword: 'Forgot your password ?',
      login: 'Login',
      or: 'OR',
      loginWith: 'Login with',
      MODAL: {
        email: { label: 'Email', placeholder: '' },
        resetPassword: 'Request password reset',
      },
    },
    SIGNUP_PAGE: {
      name: { label: 'First Name', placeholder: '' },
      familyName: { label: 'Surname', placeholder: '' },
      email: { label: 'Email', placeholder: '' },
      telephone: { label: 'Mobile', placeholder: '' },
      password: { label: 'Password', placeholder: '' },
      password2: { label: 'Password (again)' },
      terms: 'ฉันยินดีที่จะรับข่าวสารและโปรโมชั่นต่างๆจาก Haupcar ผ่านช่องทาง Email address ของฉัน',
      signup: 'Sign up',
    },
    STEP1_ACTION: {
      header: 'พร้อมหรือยังที่จะให้รถของคุณทำเงินให้กับคุณ',
      newVehicle: 'เพิ่มรถเข้าในระบบ',
      continue: 'ทำรายการที่มีอยู่แล้วต่อให้สำเร็จ',
      listed: 'รถที่อยู่ในระบบกับเราแล้ว',
    },
    STEP2_IDCARD_UPLOAD: {
      header: 'ข้อมูลทั่วไป',
      idCardUpload: 'บัตรประจำตัวประชาชน',
      houseRegistrationUpload: 'สำเนาทะเบียนบ้าน',
      MODAL: {
        title:
          'เพิ่มช่องทางสำหรับเก็บภาพสำเนาทะเบียนบ้านเพื่อใช้ทำสัญญาเช่าจุดจอดหรือเช่าพื้นที่จอด โดยข้อมูลจะถูกเปิดเผยต่อคู่ค้าของ Haup เท่านั้น',
        idCardDemo: 'ตัวอย่างภาพบัตรประชาชน',
        houseRegistrationDemo: 'ตัวอย่างภาพสำเนาทะเบียนบ้าน',
      },
    },
    STEP3_TERMS: {
      header: 'ข้อตกลงในการใช้บริการ',
      terms1:
        'ฉันเป็นเจ้าของรถ รับทราบและยอมรับข้อตกลงการให้เช่ารถโดยใช้ฮ้อปเป็นช่องทางในการหาผู้เช่า',
      terms2: 'ฉันเป็นเจ้าของรถ รับทราบหน้าที่และข้อปฏิบัติในการให้เช่ารถที่ติดตั้งอุปกรณ์จากฮ้อป',
    },
    STEP4_VEHICLE_INFO: {
      header: 'ข้อมูลรถของคุณ',
      vehicleBrand: { label: 'ยี่ห้อรถ', placeholder: '' },
      vehicleModel: { label: 'รุ่น', placeholder: '' },
      vehicleYear: { label: 'ปี', placeholder: '' },
      vehicleType: { label: 'ประเภท', placeholder: '' },
      transmissionType: { label: 'เกียร์', manual: 'ธรรมดา', auto: 'อัตโนมัติ' },
      MODAL: {
        title: 'กรอกข้อมูลเบื้องต้นของรถที่คุณจะนำมาปล่อยเช่ากับเรา',
        info: [{ attr: 'ปีรถ', message: ['บอกอายุจริงของรถ ไม่ใช่ปีที่จดทะเบียน'] }],
      },
    },
    STEP5_VEHICLE_PHOTO: {
      VEHICLE: {
        header: 'ภาพถ่ายรถของคุณ',
        front: 'ด้านหน้า',
        back: 'ด้านหลัง',
        left: 'ด้านซ้าย',
        right: 'ด้านขวา',
        MODAL: {
          title:
            'เราจำเป็นจะต้องเก็บเอกสารสำคัญบางอย่างเพื่อใช้ประสานงานและอำนวยความสะดวกให้แก่ผู้เช่า',
        },
      },
      DOCS: {
        header: 'เอกสารของรถ',
        vehicleRegistrationBook: 'เล่มทะเบียนรถ',
        MODAL: {
          title:
            'เราจำเป็นจะต้องเก็บเอกสารสำคัญบางอย่างเพื่อใช้ประสานงานและอำนวยความสะดวกให้แก่ผู้เช่า',
        },
      },
    },
    STEP6_SERVICE_TYPE: {
      header: 'รูปแบบการปล่อยเช่า',
      CARSHARING: {
        title: 'คาร์แชร์ริ่ง',
        subTitle: 'จองรถใช้ได้ทันที',
        body: 'เหมาะสำหรับเจ้าของรถที่สามารถปล่อยเช่ารถได้ตลอดเวลา โดยให้ผู้ใช้รับรถได้ด้วยตัวเองและไม่ต้องส่งมอบกุญแจรถ',
      },
      CARRENTAL: {
        title: 'ปล่อยเช่าแบบรถเช่า',
        subTitle: 'ผู้ใช้จองล่วงหน้า',
        body: 'เหมาะสำหรับเจ้าของรถที่ยังใช้รถอยู่ในชีวิตประจำวัน เจ้าของรถจะต้องส่งมอบกุญแจรถให้กับผู้ที่มาเช่า',
      },
      MODAL: {
        title:
          'เลือกประเภทการปล่อยเช่าที่เหมาะสมกับการใช้งานรถของคุณโดยเลือกจากลักษณะการใช้งานรถของคุณตามปกติ',
        text1: 'หากเป็นรถที่ใช้อยู่ในชีวิตประจำวัน Haup แนะนำการปล่อยเช่าแบบรถเช่าทั่วไป',
        text2: 'หากเป็นรถที่ไม่ค่อยได้ใช้ แนะนำให้ปล่อยเช่าแบบคาร์แชร์ริ่ง',
      },
    },
    STEP7_PACKAGE: {
      header: 'เลือกแพคเกจการปล่อยเช่า',
      selectButton: 'เลือก',
      recommend: 'แนะนำ',
      STARTER: {
        title: 'Starter',
        subTitle: 'ส่วนแบ่งรายได้ 30%',
        equipment: { label: 'อุปกรณ์', value: '-' },
        minimum: { label: 'ปล่อยเช่าขั้นต่ำ', value: 'รายวัน' },
        preBook: { label: 'จองใช้ล่วงหน้า', value: '24 ชม. ล่วงหน้า' },
        unlockType: { label: 'การปลดล็อครถ', value: 'กุญแจรถ' },
        insurance: { label: 'ประกันเชิงพาณิชย์', value: 'รายวัน' },
        techFee: { label: 'ค่าบริการเทคโนโลยี', value: '-' },
        installationFee: { label: 'ค่าติดตั้ง', value: '-' },
        revenueShare: { label: 'ส่วนแบ่งรายได้', value: '30%' },
      },
      VERIFIRED_STARTER: {
        title: 'Verified Starter',
        subTitle: 'ส่วนแบ่งรายได้ 30%',
        equipment: { label: 'อุปกรณ์', value: 'HAUP GPS' },
        minimum: { label: 'ปล่อยเช่าขั้นต่ำ', value: 'รายวัน' },
        preBook: { label: 'จองใช้ล่วงหน้า', value: '24 ชม. ล่วงหน้า' },
        unlockType: { label: 'การปลดล็อครถ', value: 'กุญแจรถ' },
        insurance: { label: 'ประกันเชิงพาณิชย์', value: 'รายวัน' },
        techFee: { label: 'ค่าบริการเทคโนโลยี', value: '490 บาท*' },
        installationFee: { label: 'ค่าติดตั้ง', value: '1,000 บาท*' },
        revenueShare: { label: 'ส่วนแบ่งรายได้', value: '30%' },
      },
      VERIFIED_PRO: {
        title: 'Verified Pro',
        subTitle: 'ส่วนแบ่งรายได้ 25%',
        equipment: { label: 'อุปกรณ์', value: 'HAUP IoT, GPS' },
        minimum: { label: 'ปล่อยเช่าขั้นต่ำ', value: 'รายชั่วโมง' },
        preBook: { label: 'จองใช้ล่วงหน้า', value: 'เช่าได้ทันที' },
        unlockType: { label: 'การปลดล็อครถ', value: 'ผ่านแอป HAUP, HAUPCARD' },
        insurance: { label: 'ประกันเชิงพาณิชย์', value: 'รายปี' },
        techFee: { label: 'ค่าบริการเทคโนโลยี', value: '790 บาท*' },
        installationFee: { label: 'ค่าติดตั้ง', value: '1,000 บาท*' },
        revenueShare: { label: 'ส่วนแบ่งรายได้', value: '25%' },
      },
      MODAL: {
        title: 'เลือกแพคเกจที่เหมาะสมกับตัวคุณมากที่สุด',
        subTitle: {
          carsharing:
            'สำหรับ คาร์แชร์ริ่ง แพคเกจ Verified Starter / Verify Pro เป็นแพคเกจที่เราแนะนำเพราะจะทำให้ลูกค้าสามารถจองและใช้รถได้ทันที',
          carrental:
            'สำหรับ การปล่อยเช่าแบบรถเช่า แพคเกจ Starter เป็นแพคเกจที่เหมาะสำหรับการปล่อยเช่าแบบรายวันเป็นต้นไป โดยเจ้าของรถมีหน้าที่ส่งมอบกุญแจ และนัดรับส่งรถ.',
        },
        packages: [
          {
            packageTitle: 'Starter',
            pros: ['ไม่เสียค่าแรกเข้าใดๆ', 'สามารถทำประกันพาณิชย์แบบรายวันได้'],
          },
          {
            packageTitle: 'Verified Starter',
            pros: [
              'มีอุปกรณ์ GPS สามารถติดตามรถของคุณได้ตลอดเวลา',
              'สามารถทำประกันพาณิชย์แบบรายวันได้',
            ],
          },
          {
            packageTitle: 'Verified Pro',
            pros: [
              'มีอุปกรณ์ GPS, HAUP IoT สามารถติดตามรถของคุณและดูข้อมูลเชิงลึกกับรถของคุณได้ผ่านระบบของเรา',
              'ลูกค้าสามารถจองและใช้รถของคุณได้ทันที ทำให้เพิ่มโอกาสสร้างรายได้ได้มากกว่า',
              'ลูกค้าสามารถเช่ารถของคุณเป็นรายชั่วโมงได้',
              'ลูกค้าสามารถปลดล็อครถของคุณได้ผ่าน Haupcard, Haup Application ทำให้กุญแจของคุณยังอยู่ในรถตลอดเวลา',
              'ได้รับส่วนแบ่งรายได้ที่มากชึ้น',
            ],
          },
        ],
      },
    },
    STEP8_INSURANCE: {
      header: 'ประกันพาณิชย์',
      inputTitle: 'รถของคุณมีประกันพาณิชย์ไหม ?',
      yes: 'มี',
      no: 'ไม่มี',
      uploadTitle: 'อัพโหลดประกันพาณิชย์ของท่าน',
      insuranceApplyType: 'ท่านต้องการสมัครประกันพาณิชย์แบบไหน',
      daily: {
        label: 'แบบรายวัน',
        body: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Vitae, magnam.',
      },
      annual: {
        label: 'แบบรายปี',
        body: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Vitae, magnam.',
      },
      MODAL: {
        header:
          'การนำรถเข้ามาใช้ในทางพาณิชย์ ท่านจำเป็นจะต้องมีประกันพาณิชย์เพื่อคุ้มครองตัวรถในกรณีที่เกิดเหตุสุดวิสัย',
        STARTER: {
          title: '',
          text: '',
        },
        VERIFIED_STARTER: {
          title: '',
          text: '',
        },
        VERIFIED_PRO: {
          title: 'สำหรับท่านที่เลือกแพคเกจ Verify Pro',
          text: 'ทางบริษัทจำเป็นจะต้อง ให้ผู้ที่นำรถมาปล่อยเช่ากับเรา ทำประกันพาณิชย์แบบรายปีเพื่อที่ผู้ปล่อยเช่าจะไม่ต้องดำเนินการใดๆ สำหรับการปล่อยเช่า ลูกค้าของผู้ปล่อยเช่าจะสามารถกดจองและเข้ามาใช้รถของท่าน ด้วย Haupcard / HaupApplication ได้โดยไม่ต้องมีการส่งมอบกุญแจใดๆ',
        },
      },
    },
    STEP9_PARKING: {
      header: 'จุดจอดรถ',
      inputTitle: 'คุณมีที่จอดสำหรับรถที่คุณต้องการจะปล่อยเช่าไหม',
      yes: 'มี',
      no: 'ไม่มี',
      parkingMapTitle: 'เลือกจุดจอดรถของคุณ',
      MODAL: {
        text: 'เราจำเป็นจะต้องมีที่จอดรถประจำให้กับรถของคุณ ไม่ต้องกังวลหากคุณไม่มีที่จอดรถ ทาง Haup จะจัดหาที่จอดให้กับรถของคุณ',
        text2:
          'เราจำเป็นจะต้องมีที่จอดรถประจำให้กับรถของคุณ ไม่ต้องกังวลหากคุณไม่มีที่จอดรถ ทาง Haup จะจัดหาที่จอดให้กับรถของคุณ',
      },
    },
    STEP10_CONTACT_TIME: {
      header: 'ช่วงเวลาติดต่อกลับ',
      inputTitle: 'ช่วงเวลาที่สะดวกให้ติดต่อกลับ ?',
      smallTitle: 'สามารถเลือกได้มากกว่า 1 ช่วงเวลา',
      TIME_LIST: [
        { label: '9.00 น. - 11.00 น.', value: '9.00 น. - 11.00 น' },
        { label: '11.00 น. - 13.00 น.', value: '11.00 น. - 13.00 น.' },
        { label: '13.00 น. - 15.00 น.', value: '13.00 น. - 15.00 น.' },
        { label: '15.00 น. - 17.00 น.', value: '15.00 น. - 17.00 น.' },
        { label: '17.00 น. - 19.00 น.', value: '17.00 น. - 19.00 น.' },
      ],
      MODAL: {
        text: 'เซลล์ของเราจำเป็นต้องติดต่อให้ผู้เช่ารถเพื่อพูดคุยเกี่ยวกับการนำรถเข้ามาติดตั้งอุปกรณ์และเรื่องอื่นๆประมาณ 5-20 นาที ท่านสามารถถามข้อสงสัยต่างๆ กับเซลล์ของเราได้ เซลล์ของเราทำการในช่วงเวลา 9:00 น - 20:00 น. วันจันทร์ - วันอาทิตย์ เว้นวันหยุดราชการ',
      },
    },
    STEP11_PAYMENT: {
      header: 'วิธีการชำระเงิน',
      inputTitle: 'อัพโหลดหน้าสมุดบัญชีธนาคาร / ภาพบันทึกหน้าจอของแอปพลิเคชั่นธนาคาร',
      MODAL: {
        title:
          'อัพโหลดภาพหรือสำเนาสมุดบัญชีหน้าแรก หรือภาพบันทึกหน้าจอของแอปพลิเคชั่นธนาคารที่บอกเลขบัญชีธนาคารเพื่อเป็นช่องทางการรับเงิน',
        account: 'ตัวอย่างภาพสมุดบัญชี',
        eAccount: 'ตัวอย่างภาพบันทึกหน้าจอของแอปพลิเคชั่นธนาคาร',
      },
    },
    STEP12_SUBMIT: {
      header: 'รายการตรวจสอบ',
      incomplete: 'ทำต่อ',
      completed: 'เรียบร้อย',
    },
  },
}

export type UITextType = typeof UIText.TH
