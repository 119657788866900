import { Image, Spin } from 'antd'
import { CSSProperties } from 'react'
import { theme } from '../config/style'
import HaupLoadingGif from '../static/img/haup-loading.gif'

const CustomIndicator = (
  <div
    style={{
      top: 0,
      left: 0,
      margin: 0,
      height: '100vh',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <span
      style={{
        backgroundColor: `${theme.haupBlue}`,
        width: '200px',
        height: '200px',
        padding: '40px',
        borderRadius: '200px',
      }}
    >
      <Image src={HaupLoadingGif} preview={false} />
    </span>
  </div>
)

interface HaupLoadingProps {
  loading?: boolean
  backgroundColor?: CSSProperties['backgroundColor']
}

export const HaupLoading = ({ loading, backgroundColor }: HaupLoadingProps) => {
  return (
    <Spin
      spinning={loading}
      style={
        loading === true
          ? {
              position: 'absolute',
              width: '100%',
              height: '100%',
              zIndex: 10000,
              backgroundColor: backgroundColor || '#ffffff75',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              cursor: 'wait',
            }
          : undefined
      }
      indicator={CustomIndicator}
    />
  )
}
