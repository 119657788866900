//https://stackoverflow.com/a/69110667
import { createContext, ReactElement, useContext, useEffect, useState } from 'react'
import { HaupLoading } from '../components/HaupLoading'

interface LoadingContextProvider {
  loading: boolean
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
}

const LoadingContext = createContext<LoadingContextProvider>({
  loading: false,
  setLoading: () => null,
})

interface LoadingProviderProps {
  children: ReactElement
}

export function LoadingProvider({ children }: LoadingProviderProps) {
  const [loading, setLoading] = useState(false)
  const value = { loading, setLoading }

  useEffect(() => {
    ;(window as any).toggleLoading = function () {
      setLoading((prev) => !prev)
    }
  }, [])
  return (
    <LoadingContext.Provider value={value}>
      <HaupLoading loading={loading} />
      {children}
    </LoadingContext.Provider>
  )
}

export function useLoading() {
  const context = useContext(LoadingContext)
  if (!context) {
    throw new Error('useLoading must be used within LoadingProvider')
  }
  return context
}
