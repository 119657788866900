import { ReactFacebookFailureResponse, ReactFacebookLoginInfo } from 'react-facebook-login'

export function instanceOfFacebookLoginResponse(object: any): object is ReactFacebookLoginInfo {
  return 'id' in object && 'userID' in object && 'accessToken' in object
}

export function instanceOfFacebookLoginFailure(
  object: any,
): object is ReactFacebookFailureResponse {
  return 'status' in object
}
