import styled from 'styled-components'
import * as image from '../../static/img/login-page'
const ImageFrame = styled.div`
  position: relative;
  overflow: hidden;
  height: 220px;
`
const BuildingImage = styled(image.Building)`
  position: absolute;
  top: 0;
  z-index: 1;
  width: 100%;
`
const CarAndPeopleImage = styled(image.CarAndPeople)`
  position: absolute;
  bottom: 0;
  z-index: 3;
  width: 100%;
`
const GroundImage = styled.div`
  position: absolute;
  bottom: 0;
  height: 40px;
  width: 100%;
  background-color: #ebf0f7;
  z-index: 2;
  border-radius: 75% 75% 0 0;
`

const LoginMainBackground = () => {
  return (
    <ImageFrame>
      <BuildingImage />
      <CarAndPeopleImage />
      <GroundImage />
    </ImageFrame>
  )
}

export default LoginMainBackground
