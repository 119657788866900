import { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { lineLogin } from '../../services/auth/line.service'
import LoginPage from '../LoginPage'

function LineCallback() {
  const [socialLogin, setSocialLogin] = useState(null)
  const search = useLocation().search
  const history = useHistory()
  useEffect(() => {
    const code = new URLSearchParams(search).get('code')
    if (code) {
      lineLogin(code)
        .then(async (result: any) => {
          setSocialLogin(result)
        })
        .catch((error) => {
          console.log('lineLoginError', error)
          alert(error)
          history.replace('/login')
        })
    }
  }, [])

  return socialLogin ? <LoginPage socialLogin={socialLogin} channel="LINE" /> : <div></div>
}

export default LineCallback
