import { Col, Row } from 'antd'
import { CSSProperties, ReactNode } from 'react'
import styled from 'styled-components'
import LoginBG from '../static/img/loginbg.jpg'

export const HaupWrapper = styled.div.attrs({ id: 'mobile-wrapper' })`
  background: url(${LoginBG});
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`

interface MobileWrapperProps {
  children: ReactNode
  style?: CSSProperties
  rowStyle?: CSSProperties
  colStyle?: CSSProperties
}
export const MobileWrapper = (props: MobileWrapperProps) => {
  return (
    <HaupWrapper style={props.style}>
      <Row justify="center" style={{ height: '100%', ...props.rowStyle }}>
        <Col
          flex="0 1 500px"
          style={{
            height: '100%',
            overflow: 'auto',
            ...props.colStyle,
          }}
        >
          {props.children}
        </Col>
      </Row>
    </HaupWrapper>
  )
}
