export function errorMessage(error: any) {
  if (typeof error === 'string') {
    return error
  }
  return (
    (error && error.response && error.response.data && error.response.data.message) ||
    (error && error.message) ||
    error
  )
}
