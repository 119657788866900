export interface MobileCountryCode {
  countryName: string
  mobileCountryCode: string
}

export async function getMobileCountryCodes(): Promise<MobileCountryCode[]> {
  return new Promise((resolve, reject) => {
    import('./data.json').then((raw) => raw.default).then((data) => resolve(data))
  })
}
