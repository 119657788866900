import { Form, FormInstance } from 'antd'

export function useArrayWatch<T extends [...any[]]>(
  dependencies: string[],
  formRef?: FormInstance<any>,
) {
  const watchMap = dependencies.map((namePath) => Form.useWatch(namePath, formRef)) as [
    ...Partial<T>,
  ]
  return watchMap
}

export function useObjectWatch<T extends [...any[]]>(
  dependencies: string[],
  formRef?: FormInstance<any>,
) {
  return (
    dependencies.map((namePath) => Form.useWatch(namePath, formRef)) as [...Partial<T>]
  ).reduce((acc, watcher, index) => ({ ...acc, [dependencies[index]]: watcher }), {})
}
