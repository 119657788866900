import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import Router from './components/Router'
import { store } from './redux/store'
import './static/styles.less'

const container = document.getElementById('root')
const root = createRoot(container!)

root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <BrowserRouter basename="/">
      <Router />
    </BrowserRouter>
  </Provider>,
  // </React.StrictMode>,
)
