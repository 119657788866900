import React from 'react'
import { AuthProvider } from '../../contexts/Auth'

class AuthenticatedPage extends React.Component<{ children: JSX.Element }> {
  render(): JSX.Element {
    return (
      <>
        <AuthProvider>{this.props.children}</AuthProvider>
      </>
    )
  }
}

export default AuthenticatedPage
