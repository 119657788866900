import { UIText } from '../../contexts/UIText'
interface StepType {
  id: string
  isDone: boolean
}
interface ActionType {
  type: string
  payload: { id: StepID; value: boolean }
}

export type StepID = typeof stepArr[number]

// const language = useSelector((state: RootState) => state.language)
const Text = UIText['TH']
const stepArr: string[] = Text.STEPS

const defaultStep: StepType[] = stepArr.map((step) => {
  return { id: step, isDone: false }
})

export default (state = defaultStep, action: ActionType): StepType[] => {
  switch (action.type) {
    case 'SET_STEP_STATUS': {
      const stepID = action.payload.id
      const steps = [...state]
      steps.forEach((step) => {
        if (step.id == stepID) {
          step.isDone = action.payload.value
        }
      })
      return [...steps]
    }
    default:
      return state
  }
}

// dispatch(toggleStepDone('GENERAL_INFO'))
