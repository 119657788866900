import { GoogleLoginResponse, GoogleLoginResponseOffline } from 'react-google-login'

export function instanceOfGoogleLoginResponseOffline(
  object: any,
): object is GoogleLoginResponseOffline {
  return 'code' in object
}

export function instanceOfGoogleLoginResponse(object: any): object is GoogleLoginResponse {
  return 'tokenId' in object
}
