import axios from 'axios'

export interface NationalityListReturnType {
  result: 'OK' | 'ERROR' | string
  nationalitylist?: Nationality[]
}

export interface Nationality {
  nationalityid: number
  nationalityname: string
}

export async function getNationalityList() {
  const response = await axios.post<NationalityListReturnType>(
    'https://ws.haupcar.com/mobile/getnationalitylist',
  )
  const { data } = response
  if (!(data.result === 'OK')) {
    throw new Error('Invalid Result')
  }
  if (!data.nationalitylist) {
    throw new Error('Invalid Result')
  }
  return data.nationalitylist
}
