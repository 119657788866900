import { AnyAction } from 'redux'

export type DocumentAlt =
  | 'id-card'
  | 'driving-license'
  | 'motorcycle-driving-license'
  | 'student-id'
  | 'organization-id'
  | 'selfie'
  | 'security'

const defaultDocuments: DocumentAlt[] = ['id-card', 'selfie', 'security']

export default (state = defaultDocuments, action: AnyAction) => {
  switch (action.type) {
    case 'SET_DOCUMENTS':
      return action.payload
    default:
      return state
  }
}
