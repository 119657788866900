import { CloseOutlined } from '@ant-design/icons'
import { DrawerProps as AntdDrawerProps, Button, Col, Drawer, Form, Row } from 'antd'
import { ReactNode, useMemo, useState } from 'react'
import { createRoot } from 'react-dom/client'
import { theme } from '../../config/style'
import { useArrayWatch } from '../../utils/useArrayWatch'
import { Heading2 } from '../StyledComponents/StyledComponents'
import { DrawerProps } from './CustomDrawer.interfaces'
import { CustomLoginInput, CustomMobilePrefixSelect } from './Elements'

export interface MobileDrawerProps extends DrawerProps {
  title?: ReactNode
  onFinish?: (prefix: string, mobile: string) => void
  getContainer?: AntdDrawerProps['getContainer']
}

export const MobileDrawer = ({ ...props }: MobileDrawerProps) => {
  const onClose = props.onClose || (() => null)
  const onFinish = props.onFinish || (() => null)
  const [form] = Form.useForm()
  const [tel, tel_prefix] = useArrayWatch<[string, string]>(['tel', 'tel_prefix'], form)
  const valid = useMemo(() => {
    if (!tel || !tel_prefix) {
      return false
    }
    if (tel.length < 9) {
      return false
    }
    return true
  }, [tel, tel_prefix])
  const [open, setOpen] = useState<boolean>()

  function handleBeforeClose() {
    setOpen(false)
    setTimeout(() => {
      onClose()
    }, 200)
  }

  return (
    <Drawer
      open={open ?? props.open}
      onClose={() => handleBeforeClose()}
      placement="bottom"
      getContainer={props.getContainer || false}
      height="50%"
      headerStyle={{
        borderBottom: 0,
        zIndex: 1 /* boxShadow: '0px 2px 8px 0px rgba(0,0,0,0.25)' */,
      }}
      title={
        <Row justify="center" align="middle" style={{ marginTop: '1rem' }}>
          <Col span={2}></Col>
          <Col span={20} style={{ textAlign: 'center' }}>
            <Heading2 style={{ color: theme.haupBlue }}>{props.title || 'Mobile Number'}</Heading2>
          </Col>
          <Col span={2}>
            <CloseOutlined
              style={{ fontSize: '20px', color: '#00000050' }}
              onClick={() => handleBeforeClose()}
            />
          </Col>
        </Row>
      }
      contentWrapperStyle={{
        borderRadius: '20px 20px 0 0',
        overflow: 'hidden',
      }}
      drawerStyle={{ background: theme.haupBlueSecondary }}
      closeIcon={null}
      // maskStyle={{ background: 'transparent' }}
      bodyStyle={{ position: 'relative' }}
      style={{
        maxWidth: '500px',
        margin: '0 auto',
      }}
    >
      <Form form={form}>
        <Form.Item>
          <Row gutter={8} wrap={false}>
            <Col>
              <Form.Item noStyle name="tel_prefix" initialValue={'66'}>
                <CustomMobilePrefixSelect style={{ minWidth: 150, maxWidth: 200 }} />
              </Form.Item>
            </Col>
            <Col flex="auto">
              <Form.Item noStyle name="tel" rules={[{ required: true, message: 'ห้ามเว้นว่าง' }]}>
                <CustomLoginInput
                  style={{ width: '100%', height: 70 }}
                  placeholder="Phone Number"
                  maxLength={12}
                  type="tel"
                />
              </Form.Item>
            </Col>
          </Row>
        </Form.Item>
        <Form.Item>
          <Button
            block
            type="primary"
            shape="round"
            size="large"
            disabled={!valid}
            onClick={() => {
              if (!tel_prefix || !tel) {
                return
              }
              onFinish(tel_prefix, tel)
            }}
          >
            Continue
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  )
}

export function popMobileDrawer(container?: HTMLDivElement | null): Promise<[string, string]> {
  return new Promise((resolve, reject) => {
    if (!container) {
      return reject('Container Not Found.')
    }
    const root = createRoot(container)
    function detachResolve(prefix = '', tel = '') {
      root.unmount()
      return resolve([prefix, tel])
    }
    root.render(
      <MobileDrawer
        open={true}
        onClose={() => detachResolve()}
        onFinish={(prefix, mobile) => detachResolve(prefix, mobile)}
      />,
    )
  })
}
