import { Button, Col, Form, message, Row, Space, Typography } from 'antd'
import { useEffect, useRef, useState } from 'react'
import AppleLogin from 'react-apple-login'
import { ReactFacebookFailureResponse, ReactFacebookLoginInfo } from 'react-facebook-login'
import GoogleLogin, { GoogleLoginResponse, GoogleLoginResponseOffline } from 'react-google-login'
import { useHistory } from 'react-router'
import { CustomDivider } from '../../components/LoginDrawer/Elements'
import { LoginDrawer } from '../../components/LoginDrawer/LoginDrawer'
import LoginMainBackground from '../../components/LoginDrawer/MainBackground'
import { popMobileDrawer } from '../../components/LoginDrawer/MobileDrawer'
import {
  popRetryableLoginVerifyOtp,
  popRetryableRegisterVerifyOtp,
} from '../../components/LoginDrawer/OTPDrawer'
import { RegisterDrawer } from '../../components/LoginDrawer/RegisterDrawer'
import { HaupWrapper } from '../../components/MobileWrapper'
import config from '../../config'
import { theme } from '../../config/style'
import { login } from '../../contexts/Auth'
import { useLoading } from '../../contexts/LoadingContext'
import { appleLogin } from '../../services/auth/apple.service'
import {
  instanceOfFacebookLoginFailure,
  instanceOfFacebookLoginResponse,
} from '../../services/auth/facebook-auth.function'
import { facebookLogin } from '../../services/auth/facebook-auth.service'
import {
  instanceOfGoogleLoginResponse,
  instanceOfGoogleLoginResponseOffline,
} from '../../services/auth/google-auth.function'
import { googleLogin } from '../../services/auth/google-auth.service'
import {
  instanceOfGetOTPSuccess,
  instanceOfLoginSuccess,
  instanceOfMobileDuplicated,
  instanceOfMobileRequiredToRegister,
  instanceOfRequireMobile,
  instanceOfRequireOTP,
} from '../../services/auth/instanceof.function'
import { userLoginGetMobileOTP } from '../../services/auth/login.service'
import { userRegisterGetMobileOtp } from '../../services/auth/register.service'
import * as image from '../../static/img/login-page'
import { apple2x, google, google2x, HaupBlueLogo } from '../../static/img/login-page'

export const LoginPage = (props: { socialLogin?: any; channel?: string }) => {
  const [panelOpen, setPanelOpen] = useState<'login' | 'register' | ''>('')
  const [loginFormRef] = Form.useForm()
  const [registerFormRef] = Form.useForm()
  const { GOOGLE_APP_ID, FACEBOOK_APP_ID } = config.social
  const mobileDrawerRef = useRef<HTMLDivElement>(null)
  const otpDrawerRef = useRef<HTMLDivElement>(null)
  const history = useHistory()
  const { setLoading } = useLoading()

  function redirectToBase() {
    history.replace('/')
  }

  useEffect(() => {
    if (props?.socialLogin) {
      handleSocialLoginSuccess(props?.socialLogin)
    }
  }, [])

  async function handleSocialLoginSuccess(res: any): Promise<void> {
    if (!otpDrawerRef.current || !mobileDrawerRef.current) {
      message.error('No window element provided')
      throw 'No window element provided'
    }
    const loginResponse = res
    if (instanceOfLoginSuccess(loginResponse)) {
      return await login(loginResponse.accessToken, loginResponse.userData, redirectToBase)
    }
    if (instanceOfRequireMobile(loginResponse)) {
      const [prefix, tel] = await popMobileDrawer(mobileDrawerRef.current)
      if (prefix === '' || tel === '') {
        return
      }
      setLoading(true)
      const requestOTP = await userLoginGetMobileOTP(
        Number(prefix),
        tel,
        loginResponse.loginToken,
      ).finally(() => setLoading(false))
      if (instanceOfRequireMobile(requestOTP)) {
        throw 'Impossible return object. Something went wrong ?'
      }
      if (instanceOfGetOTPSuccess(requestOTP)) {
        const loginSuccess = await popRetryableLoginVerifyOtp(otpDrawerRef.current, {
          mobile: requestOTP.mobile,
          refCode: requestOTP.refCode,
        }).catch((error: any) => {
          message.error(error)
          throw error
        })
        if (!(loginSuccess.accessToken && loginSuccess.userData)) {
          throw 'Invalid Login Data'
        }
        return login(loginSuccess.accessToken, loginSuccess.userData, redirectToBase)
      }
    }
    if (instanceOfRequireOTP(loginResponse)) {
      const mobileNumber = loginResponse.otp.mobile
      const refCode = loginResponse.otp.refCode

      const loginSuccess = await popRetryableLoginVerifyOtp(otpDrawerRef.current, {
        mobile: mobileNumber,
        refCode: refCode,
      }).catch((error: any) => {
        message.error(error)
        throw error
      })

      if (!(loginSuccess.accessToken && loginSuccess.userData)) {
        throw 'Invalid Login Data'
      }
      return login(loginSuccess.accessToken, loginSuccess.userData, redirectToBase)
    }
    if (instanceOfMobileRequiredToRegister(loginResponse)) {
      // Google account's email is not exist in db, so create new account instead.
      const [prefix, tel] = await popMobileDrawer(mobileDrawerRef.current)
      if (prefix === '' || tel === '') {
        return
      }
      setLoading(true)
      const requestOTP = await userRegisterGetMobileOtp(Number(prefix), tel).finally(() =>
        setLoading(false),
      )
      if (instanceOfMobileDuplicated(requestOTP)) {
        return message.error(requestOTP.message)
      }
      const registerOtpValid = await popRetryableRegisterVerifyOtp(otpDrawerRef.current, {
        mobile: requestOTP.mobile,
        refCode: requestOTP.refCode,
      }).catch((error: any) => {
        message.error(error)
        throw error
      })
      const loginResponse =
        props?.channel === 'APPLE'
          ? await appleLogin(res.tokenId, registerOtpValid.token)
          : await googleLogin(res.tokenId, registerOtpValid.token)
      if (instanceOfRequireMobile(loginResponse)) {
        return message.error(loginResponse.message)
      }
      if (instanceOfRequireOTP(loginResponse)) {
        return message.error(loginResponse.message)
      }
      return login(loginResponse.accessToken, loginResponse.userData, redirectToBase)
    }
  }

  async function handleGoogleLoginSuccess(
    res: GoogleLoginResponse | GoogleLoginResponseOffline,
  ): Promise<void> {
    if (!otpDrawerRef.current || !mobileDrawerRef.current) {
      message.error('No window element provided')
      throw 'No window element provided'
    }
    if (instanceOfGoogleLoginResponseOffline(res)) {
      return message.error(res.code) // Google Response Offline
    }
    if (instanceOfGoogleLoginResponse(res)) {
      const loginResponse = await googleLogin(res.tokenId)
      if (instanceOfLoginSuccess(loginResponse)) {
        return await login(loginResponse.accessToken, loginResponse.userData, redirectToBase)
      }
      if (instanceOfRequireMobile(loginResponse)) {
        const [prefix, tel] = await popMobileDrawer(mobileDrawerRef.current)
        if (prefix === '' || tel === '') {
          return
        }
        setLoading(true)
        const requestOTP = await userLoginGetMobileOTP(
          Number(prefix),
          tel,
          loginResponse.loginToken,
        ).finally(() => setLoading(false))
        if (instanceOfRequireMobile(requestOTP)) {
          throw 'Impossible return object. Something went wrong ?'
        }
        if (instanceOfGetOTPSuccess(requestOTP)) {
          const loginSuccess = await popRetryableLoginVerifyOtp(otpDrawerRef.current, {
            mobile: requestOTP.mobile,
            refCode: requestOTP.refCode,
          }).catch((error: any) => {
            message.error(error)
            throw error
          })
          if (!(loginSuccess.accessToken && loginSuccess.userData)) {
            throw 'Invalid Login Data'
          }
          return login(loginSuccess.accessToken, loginSuccess.userData, redirectToBase)
        }
      }
      if (instanceOfRequireOTP(loginResponse)) {
        const mobileNumber = loginResponse.otp.mobile
        const refCode = loginResponse.otp.refCode

        const loginSuccess = await popRetryableLoginVerifyOtp(otpDrawerRef.current, {
          mobile: mobileNumber,
          refCode: refCode,
        }).catch((error: any) => {
          message.error(error)
          throw error
        })

        if (!(loginSuccess.accessToken && loginSuccess.userData)) {
          throw 'Invalid Login Data'
        }
        return login(loginSuccess.accessToken, loginSuccess.userData, redirectToBase)
      }
      if (instanceOfMobileRequiredToRegister(loginResponse)) {
        // Google account's email is not exist in db, so create new account instead.
        const [prefix, tel] = await popMobileDrawer(mobileDrawerRef.current)
        if (prefix === '' || tel === '') {
          return
        }
        setLoading(true)
        const requestOTP = await userRegisterGetMobileOtp(Number(prefix), tel).finally(() =>
          setLoading(false),
        )
        if (instanceOfMobileDuplicated(requestOTP)) {
          return message.error(requestOTP.message)
        }
        const registerOtpValid = await popRetryableRegisterVerifyOtp(otpDrawerRef.current, {
          mobile: requestOTP.mobile,
          refCode: requestOTP.refCode,
        }).catch((error: any) => {
          message.error(error)
          throw error
        })
        const loginResponse = await googleLogin(res.tokenId, registerOtpValid.token)
        if (instanceOfRequireMobile(loginResponse)) {
          return message.error(loginResponse.message)
        }
        if (instanceOfRequireOTP(loginResponse)) {
          return message.error(loginResponse.message)
        }
        return login(loginResponse.accessToken, loginResponse.userData, redirectToBase)
      }
    }
  }

  async function handleFacebookLogin(res: ReactFacebookLoginInfo | ReactFacebookFailureResponse) {
    if (!otpDrawerRef.current || !mobileDrawerRef.current) {
      message.error('No window element provided')
      throw 'No window element provided'
    }
    if (instanceOfFacebookLoginFailure(res)) {
      return message.error(res.status)
    }
    if (instanceOfFacebookLoginResponse(res)) {
      const loginResult = await facebookLogin(res.accessToken)
      if (instanceOfLoginSuccess(loginResult)) {
        return await login(loginResult.accessToken, loginResult.userData, redirectToBase)
      }

      if (instanceOfRequireMobile(loginResult)) {
        const [prefix, tel] = await popMobileDrawer(mobileDrawerRef.current)
        if (prefix === '' || tel === '') {
          return
        }
        setLoading(true)
        const requestOTP = await userLoginGetMobileOTP(
          Number(prefix),
          tel,
          loginResult.loginToken,
        ).finally(() => setLoading(false))
        if (instanceOfRequireMobile(requestOTP)) {
          throw 'Impossible return object. Something went wrong ?'
        }
        if (instanceOfGetOTPSuccess(requestOTP)) {
          const loginSuccess = await popRetryableLoginVerifyOtp(otpDrawerRef.current, {
            mobile: requestOTP.mobile,
            refCode: requestOTP.refCode,
          }).catch((error: any) => {
            message.error(error)
            throw error
          })

          if (!(loginSuccess.accessToken && loginSuccess.userData)) {
            throw 'Invalid Login Data'
          }
          return login(loginSuccess.accessToken, loginSuccess.userData, redirectToBase)
        }
      }

      if (instanceOfRequireOTP(loginResult)) {
        const mobileNumber = loginResult.otp.mobile
        const refCode = loginResult.otp.refCode

        const loginSuccess = await popRetryableLoginVerifyOtp(otpDrawerRef.current, {
          mobile: mobileNumber,
          refCode: refCode,
        }).catch((error: any) => {
          message.error(error)
          throw error
        })

        if (!(loginSuccess.accessToken && loginSuccess.userData)) {
          throw 'Invalid Login Data'
        }
        return login(loginSuccess.accessToken, loginSuccess.userData, redirectToBase)
      }
    }
  }

  return (
    <HaupWrapper>
      <Row justify="center" style={{ height: '100%' }}>
        <Col
          flex="0 1 500px"
          style={{
            backgroundColor: '#EBF0F7',
            position: 'relative',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            overflow: 'auto',
            boxShadow: '0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)',
          }}
        >
          <section
            id="login-main"
            style={{ height: 'fit-content', paddingTop: '50px', backgroundColor: '#fff' }}
          >
            <Space direction="vertical" style={{ width: '100%' }} align="center">
              <HaupBlueLogo />
            </Space>
            <LoginMainBackground />
            <Space
              direction="vertical"
              className="pad-content"
              style={{
                backgroundColor: '#EBF0F7',
                width: '100%',
                height: '100%',
                alignItems: 'inherit',
                textAlign: 'center',
                paddingTop: '50px',
              }}
            >
              <Typography.Title level={2} style={{ color: theme.haupBlue }}>
                Mobility for Everyone
              </Typography.Title>
              <Typography.Paragraph>Earn from car share, Get a car anywhere.</Typography.Paragraph>
            </Space>
          </section>
          <Space
            direction="vertical"
            size={16}
            style={{
              width: '100%',
              marginTop: 'auto',
              padding: '4rem 2rem',
              backgroundColor: '#ebf0f7',
            }}
          >
            <div className="text-center">
              <Space size="large" align="center">
                <AppleLogin
                  clientId={config.social.APPLE_LOGIN_ID}
                  redirectURI={`https://staging.k8s.haupcar.com/ts-authentication/social-login/apple/web`}
                  responseMode="form_post"
                  scope="email name"
                  usePopup={false}
                  render={(renderProps) => (
                    <img
                      src={apple2x}
                      srcSet={`${apple2x} 2x`}
                      onClick={renderProps.onClick}
                      style={{ width: '40px', cursor: 'pointer' }}
                    />
                  )}
                />

                <GoogleLogin
                  clientId={GOOGLE_APP_ID}
                  buttonText="Login"
                  render={(renderProps) => (
                    <img
                      src={google}
                      srcSet={`${google2x} 2x`}
                      onClick={renderProps.onClick}
                      style={{ cursor: 'pointer' }}
                    />
                  )}
                  onFailure={(res) => console.log(res)}
                  onSuccess={handleGoogleLoginSuccess}
                  cookiePolicy={'single_host_origin'}
                />
                <a
                  href={`https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=${config.social.LINE_LOGIN_ID}&redirect_uri=${config.social.LINE_CALLBACK_URL}&state=12345abcde&scope=profile%20openid%20email&nonce=09876xyz`}
                >
                  <img src={image.line2x} width={'40px'} height={'40px'} />
                </a>
                {/* <FacebookLogin
                  appId={FACEBOOK_APP_ID}
                  callback={(res) => handleFacebookLogin(res)}
                  isMobile={true}
                  disableMobileRedirect={true}
                  render={(renderProps) => (
                    <img
                      src={facebook}
                      srcSet={`${facebook2x} 2x`}
                      onClick={renderProps.onClick}
                      style={{ cursor: 'pointer' }}
                      hidden
                    />
                  )}
                /> */}
              </Space>
            </div>
            <p
              style={{
                textAlign: 'center',
                backgroundColor: 'white',
                borderRadius: '10px',
                color: '#df0000',
                fontSize: 'smaller',
              }}
            >
              * Haupcar uses your email address to register or log in across our platform and to
              send important notifications.
            </p>
            <CustomDivider>Or login using email</CustomDivider>
            <Button
              type="primary"
              shape="round"
              block
              size="large"
              onClick={() => setPanelOpen((prev) => (!prev ? 'login' : ''))}
            >
              Login
            </Button>
            <Button
              shape="round"
              block
              size="large"
              style={{ border: 0 }}
              onClick={() => setPanelOpen((prev) => (!prev ? 'register' : ''))}
            >
              Create an account
            </Button>
          </Space>
        </Col>
      </Row>

      <LoginDrawer
        formRef={loginFormRef}
        open={panelOpen === 'login'}
        onClose={(open) =>
          open ? setPanelOpen(open) : setPanelOpen((prev) => (!prev ? 'login' : ''))
        }
      />
      <RegisterDrawer
        formRef={registerFormRef}
        open={panelOpen === 'register'}
        onClose={(open) =>
          open ? setPanelOpen(open) : setPanelOpen((prev) => (!prev ? 'register' : ''))
        }
        onGoogleLoginSuccess={handleGoogleLoginSuccess}
        onFacebookLoginSuccess={handleFacebookLogin}
      />
      <div ref={mobileDrawerRef} />
      <div ref={otpDrawerRef} />
    </HaupWrapper>
  )
}

export default LoginPage
