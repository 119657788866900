import { Form, FormItemProps } from 'antd'
export interface FormItemBrokerType<T = any> {
  id?: string
  value?: T
  onChange?: (val: T) => void
  children?: (val: Omit<FormItemBrokerType<T>, 'children'>) => JSX.Element
}
export const WithFormValue = (props: FormItemBrokerType) => {
  const { id, value, onChange, children } = props
  if (!children) {
    return <></>
  }
  return children({ id, value, onChange })
}

type FormItemWrapper = Omit<FormItemProps, 'children'> & {
  children: FormItemBrokerType['children']
}

export const FormItemBroker = (props: FormItemWrapper) => {
  const { children, ...otherProps } = props
  return (
    <Form.Item {...otherProps}>
      <WithFormValue children={children} />
    </Form.Item>
  )
}
