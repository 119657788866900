import { CSSProperties } from 'react'

export const PrivacyPolicyPDPAContent = ({ style }: { style?: CSSProperties }) => {
  return (
    <section className="words-content" style={style}>
      <h1 className="text-center">
        นโยบายความเป็นส่วนตัว
        <br />
        บริษัท ฮ้อปคาร์ จำกัด
      </h1>
      <br />
      <p className="indent">
        บริษัท ฮ้อปคาร์ จำกัด (“บริษัท”) ให้ความสำคัญและเคารพสิทธิในข้อมูลส่วนบุคล
        จึงจัดให้มีนโยบายความเป็นส่วนตัวฉบับนี้เพื่อให้ลูกค้า นักลงทุน พันธมิตรทางธุรกิจ
        คู่ค้าของบริษัท และ/หรือบุคคลธรรมดาที่เกี่ยวข้องกับบริษัท
        ได้รับทราบถึงนโยบายเกี่ยวกับการเก็บรวบรวม การใช้ และการเปิดเผยข้อมูลส่วนบุคคลของบุคคลธรรมดา
        (ต่อไปนี้จะเรียกรวมกันว่า “ท่าน”) ซึ่งเป็นไปตามพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ.2562
        (“พ.ร.บ. คุ้มครองข้อมูลส่วนบุคคล”) กฎหมาย และ/หรือกฎเกณฑ์อื่นที่เกี่ยวข้อง
        โดยนโยบายความเป็นส่วนตัวฉบับนี้จะแจ้งให้ท่านทราบถึงวิธีการที่บริษัทเก็บรวบรวม ใช้
        หรือเปิดเผยข้อมูลส่วนบุคคลของท่าน ประเภทข้อมูล และวัตถุประสงค์ในการดำเนินการดังกล่าว
        รวมถึงรายละเอียดเกี่ยวกับแหล่งที่มาของข้อมูลส่วนบุคคลของท่านระยะเวลาในการเก็บรักษาข้อมูล
        การเปิดเผยข้อมูลส่วนบุคคลให้แก่บุคคลภายนอก สิทธิของท่าน
        การรักษาความลับและความปลอดภัยของข้อมูลส่วนบุคคลของท่าน และวิธีการที่ท่านสามารถติดต่อบริษัท
      </p>
      <p className="bold topic">1. บทนำ</p>
      <p className="indent text-underline">1.1. นิยาม</p>
      <p className="indent">
        “ข้อมูลส่วนบุคคล” หมายถึง ข้อมูลเกี่ยวกับบุคคลธรรมดาซึ่งทำให้สามารถระบุตัวตนของบุคคลนั้นได้
        ไม่ว่าทางตรงหรือทางอ้อม แต่ไม่รวมถึงข้อมูลของผู้ถึงแก่กรรมโดยเฉพาะ
        ตามกฎหมายว่าด้วยการคุ้มครองข้อมูลส่วนบุคคล
      </p>
      <p className="indent">
        “ข้อมูลละเอียดอ่อน” หมายถึง ข้อมูลเกี่ยวกับเชื้อชาติ เผ่าพันธุ์ ความคิดเห็น ทางการเมือง
        ความเชื่อในลัทธิ ศาสนาหรือปรัชญา พฤติกรรมทางเพศ ประวัติอาชญากรรม ข้อมูลสุขภาพ ความพิการ
        ข้อมูลสหภาพแรงงาน ข้อมูลพันธุกรรม ข้อมูลชีวภาพ หรือข้อมูลอื่นใด
        ซึ่งกระทบต่อเจ้าของข้อมูลส่วนบุคคลในทำนองเดียวกันตามที่คณะกรรมการประกาศกำหนด
      </p>
      <p className="indent">
        “ประมวลผล” หมายถึง การดำเนินการใด ๆ กับข้อมูลส่วนบุคคลไม่ว่าด้วยวิธีการอัตโนมัติหรือไม่ก็ตาม
        เช่น การเก็บรวบรวม การบันทึก การจัดระบบ การจัดเก็บ การปรับเปลี่ยนหรือการดัดแปลง การเรียกคืน
        การปรึกษา การใช้ การเปิดเผย (โดยการส่ง โอน
        การเผยแพร่หรือการทำให้สามารถเข้าถึงหรือพร้อมใช้งานโดยวิธีใด ๆ) การจัดเรียง การนำมารวมกัน
        การบล็อกหรือจำกัด การลบหรือการทำลาย
      </p>
      <p className="indent">
        “ผู้ควบคุมข้อมูลส่วนบุคคล” หมายถึง
        บุคคลธรรมดาหรือนิติบุคคลซึ่งมีอำนาจหน้าที่ตัดสินใจเกี่ยวกับการเก็บรวบรวม ใช้
        หรือเปิดเผยข้อมูลส่วนบุคคล
        (โดยผู้ควบคุมข้อมูลส่วนบุคคลตามนัยของนโยบายความเป็นส่วนตัวฉบับนี้ก็คือบริษัท)
      </p>
      <p className="indent">
        “ผู้ประมวลผลข้อมูลส่วนบุคคล” หมายถึง
        บุคคลธรรมดาหรือนิติบุคคลซึ่งดำเนินการเกี่ยวกับการเก็บรวบรวม ใช้
        หรือเปิดเผยข้อมูลส่วนบุคคลตามคำสั่งหรือในนามของผู้ควบคุมข้อมูลส่วนบุคคล ทั้งนี้
        บุคคลธรรมดาหรือนิติบุคคลซึ่งดำเนินการดังกล่าวไม่เป็นผู้ควบคุมข้อมูลส่วนบุคคล
      </p>
      <p className="indent">
        1.2. นโยบายความเป็นส่วนตัวฉบับนี้ใช้สำหรับลูกค้าบุคคลธรรมดา คู่ค้าของบริษัท
        พันธมิตรทางธุรกิจ นักลงทุนและ/หรือบุคคลธรรมดาที่เกี่ยวข้องกับบริษัท
        รวมทั้งลูกค้าองค์กรธุรกิจในส่วนของกรรมการ ผู้รับประโยชน์ที่แท้จริง พนักงาน
        และผู้แทนโดยชอบด้วยกฎหมายของลูกค้าองค์กรธุรกิจ
        รวมถึงบุคคลธรรมดาอื่นที่มีอำนาจในการกระทำการแทนลูกค้าองค์กรธุรกิจ
      </p>
      <p className="indent">
        1.3. นโยบายความเป็นส่วนตัวฉบับนี้อาจใช้ประกอบกับประกาศ ระเบียบ นโยบายอื่นๆ สัญญา
        ข้อความในเอกสารให้ความยิมยอม และ/หรือวิธีการทางอิเล็กทรอนิกส์เกี่ยวกับเกี่ยวกับการเก็บรวบรวม
        การใช้ และการเปิดเผยข้อมูลส่วนบุคคลของท่านต่อบริษัท
      </p>
      <p className="indent">
        1.4. นโยบายความเป็นส่วนตัวฉบับนี้จะเป็นส่วนหนึ่งในการประกอบการตัดสินใจของท่าน
        ก่อนที่จะให้ข้อมูลใดๆ กับบริษัท ดังนั้นโปรดอ่านนโยบายความเป็นส่วนตัวฉบับนี้อย่างละเอียด
        หากมีข้อสงสัยเกี่ยวกับข้อมูลที่ได้ให้ไว้ในนโยบายความเป็นส่วนตัวฉบับนี้
        สามารถติดต่อสอบถามบริษัทได้
        รายละเอียดการติดต่อสอบถามอยู่ตอนท้ายของนโยบายความเป็นส่วนตัวฉบับนี้
      </p>
      <p className="bold topic">2. การเก็บรวบรวมและการใช้ข้อมูลส่วนบุคคล</p>
      <p className="indent">
        การเก็บรวบรวมข้อมูลส่วนบุคคลของท่านจะกระทำเฉพาะกรณีที่จำเป็น มีวัตถุประสงค์ ขอบเขต
        ใช้วิธีการที่ชอบด้วยกฎหมาย หรือมีฐานทางกฎหมายในการเก็บรวบรวมและใช้ข้อมูลส่วนบุคคลเท่านั้น
        ตามพ.ร.บ. คุ้มครองข้อมูลส่วนบุคคล กฎหมายและกฎระเบียบอื่นที่เกี่ยวข้องอย่างเป็นธรรม
        โดยการให้ข้อมูลส่วนบุคคลของท่านนั้นต้องเกิดจากความสมัครใจ และโดยความยินยอม ฐานทางกฎหมาย
        และวัตถุประสงค์ในเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลของบริษัทมีดังต่อไปนี้
      </p>
      <p className="indent text-underline">2.1. การปฏิบัติตามกฎหมายของบริษัท</p>
      <p className="indent">
        บริษัทต้องดำเนินการตามกฎหมายและกฎระเบียบที่เกี่ยวข้อง บริษัทจึงมีความจำเป็นจะต้องเก็บรวบรวม
        ใช้ หรือเปิดเผยข้อมูลส่วนบุคคลของท่านเพื่อวัตถุประสงค์ต่างๆ
        เพื่อให้เป็นไปตามกฎหมายและกฎระเบียบของหน่วยงานรัฐ
        และ/หรือหน่วยงานที่มีหน้าที่กำกับดูแลบริษัท
        ซึ่งรวมถึงแต่ไม่จำกัดเฉพาะวัตถุประสงค์ดังต่อไปนี้
      </p>
      <p className="indent">
        ก) เพื่อปฏิบัติตามกฎหมาย เช่น กฎหมายแพ่งและพาณิชย์ กฎหมายวิธีพิจารณาความแพ่งและอาญา
        กฎหมายเกี่ยวกับการจราจร กฎหมายภาษีอากร กฎหมายองค์กรธุรกิจ กฎหมายเกี่ยวกับรถยนต์
        กฎหมายว่าด้วยธุรกรรมทางอิเล็กทรอนิกส์ กฎหมายป้องกันและปราบปรามการฟอกเงิน
        กฎหมายเกี่ยวกับการคุ้มครองผู้บริโภค และกฎหมายอื่นที่บริษัทต้องปฏิบัติติตาม
        ทั้งในประเทศไทยและต่างประเทศ
      </p>
      <p className="indent">
        ข) เพื่อปฏิบัติตามกฎระเบียบ และ/หรือคำสั่งของผู้มีอำนาจ เช่น คำสั่งศาล พนักงานสอบสวน
        ระเบียบกรมขนส่ง คำสั่งของสำนักงานคณะกรรมการคุ้มครองผู้บริโภค คำสั่งของหน่วยงานรัฐ
        หรือพนักงานเจ้าหน้าที่ผู้มีอำนาจอื่นของรัฐ เป็นต้น
      </p>
      <p className="indent">
        ค) เพื่อปฏิบัติตาม พ.ร.บ. คุ้มครองข้อมูลส่วนบุคคลข้อมูลส่วนบุคคล พ.ศ. 2562
      </p>
      <p className="indent text-underline">2.2. สัญญาที่ได้ทำไว้กับท่าน</p>
      <p className="indent">
        บริษัทจะเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคลของท่านไปใช้ตามคำขอและ/หรือ
        ข้อตกลงที่ท่านได้ทำไว้กับบริษัท ซึ่งรวมถึงแต่ไม่จำกัดเฉพาะวัตถุประสงค์ดังต่อไปนี้
      </p>
      <p className="indent">
        ก) ดำเนินการตามวัตถุประสงค์ของสัญญาเพื่อประโยชน์ในการให้บริการแก่ท่าน
      </p>
      <p className="indent">ข) ติดตามหรือบันทึกการปฏิบัติตามสัญญาของท่าน</p>
      <p className="indent">
        ค) จัดทำรายงานต่างๆ เพื่อประโยชน์ของท่านหรือของบริษัท ตามวัตถุประสงค์ของสัญญา
      </p>
      <p className="indent">ง) การแจ้งชำระหนี้</p>
      <p className="indent text-underline">2.3. ประโยชน์โดยชอบด้วยกฎหมาย</p>
      <p className="indent">
        บริษัทคำนึงถึงประโยชน์ของบริษัทหรือของบุคคลอื่น
        เมื่อเทียบกับสิทธิขั้นพื้นฐานในข้อมูลส่วนบุคคลของท่านที่บริษัทจะได้เก็บรวบรวม ใช้
        หรือเปิดเผย ซึ่งรวมถึงแต่ไม่จำกัดเฉพาะวัตถุประสงค์ดังต่อไปนี้
      </p>
      <p className="indent">
        ก) บริหารจัดการความเสี่ยง ตรวจสอบ เฝ้าระวัง ป้องกันการก่อการร้าย การประพฤติโดยมิชอบ
        การก่ออาชญากรรมอื่นๆ หรือกระทำความผิดตามกฎหมาย{' '}
      </p>
      <p className="indent">
        ข) รักษาความปลอดภัย เช่น การส่งรหัสเพื่อความปลอดภัย OTP การบันทึกภาพกล้องวงจรปิด CCTV
        การลงทะเบียน แลกบัตร และ/หรือบันทึกภาพ ผู้ติดต่อก่อนเข้ามาในบริเวณพื้นที่ของบริษัท
      </p>
      <p className="indent">
        ค) บันทึกภาพและ/หรือเสียงเกี่ยวกับการประชุม อบรม สัมมนา สันทนาการ งานจัดแสดงสินค้าและบริการ
        หรือกิจกรรมส่งเสริมการตลาดอื่นๆ{' '}
      </p>
      <p className="indent">
        ง) จัดการความสัมพันธ์ระหว่างท่านกับบริษัท และเพื่อตอบสนองความต้องการของท่าน
        ไม่ว่าโดยการติดต่อทางใดก็ตาม เช่น จัดการข้อเรียกร้องของท่าน การทำแบบแสดงความคิดเห็น{' '}
      </p>
      <p className="indent">
        จ) เพื่อการปรับปรุงพัฒนาสินค้าและบริการ รวมถึงระบบต่างๆ
        เพื่อประโยชน์สูงสุดในการให้บริการท่าน{' '}
      </p>
      <p className="indent">
        ฉ) กรณีลูกค้าองค์กรธุรกิจ นิติบุคคล บริษัทจะมีการเก็บรวบรวม ใช้
        และเปิดเผยข้อมูลส่วนบุคคลของกรรมการ ผู้มีอำนาจกระทำการแทน ลูกจ้าง ตัวแทน
        และ/หรือผู้ที่ติดต่อกับบริษัท
      </p>
      <p className="indent">
        ช) เมื่อท่านส่งคำร้องเรียนเมื่อพบการกระทำความผิดหรือการประพฤติโดยมิชอบภายในบริษัท
      </p>
      <p className="indent">ซ) เพื่อประโยชน์ของท่าน และการขอความยินยอมไม่อาจกระทำได้ในเวลานั้น</p>
      <p className="indent text-underline">2.4 ความยินยอมของท่าน</p>
      <p className="indent">
        ในบางกรณีบริษัทอาจมีการขอความยินยอมจากท่านในการเก็บรวบรวม ใช้
        หรือเปิดเผยข้อมูลส่วนบุคคลของท่านเพื่อให้ท่านได้รับประโยชน์สูงสุด
        หรือเพื่อให้บริษัทสามารถให้บริการที่ตอบสนองต่อความต้องการของท่านหรือตามข้อบังคับ
        และ/หรือกฎหมายกำหนด ซึ่งรวมถึงแต่ไม่จำกัดเฉพาะวัตถุประสงค์ดังต่อไปนี้
      </p>
      <p className="indent">
        ก) กรณีมีความจำเป็นต้องเก็บรวบรวมและใช้ข้อมูลส่วนบุคคลอ่อนไหว เช่น ใช้ข้อมูลการจดจำใบหน้า
        ลายนิ้วมือ หรือภาพถ่ายบัตรประจำตัวประชาชนของท่าน
        ซึ่งในบัตรดังกล่าวจะมีข้อมูลส่วนบุคคลอ่อนไหวของท่าน ได้แก่ ศาสนา และ/หรือกรุ๊ปเลือดอยู่
        เพื่อการยืนยันตัวตน
      </p>
      <p className="indent">
        ข) เก็บรวบรวมและใช้ข้อมูลส่วนบุคคลและข้อมูลใดๆ
        ของท่านไปวิจัยและวิเคราะห์เพื่อประโยชน์สูงสุดในการพัฒนาบริการหรือผลิตภัณฑ์อื่นใด
        การจัดทำฐานข้อมูล และบริการที่ตอบสนองต่อความต้องการของท่าน
        และ/หรือติดต่อท่านเพื่อเสนอผลิตภัณฑ์ บริการ
        และสิทธิประโยชน์ที่เหมาะสมแก่ท่านโดยเฉพาะไม่ว่าโดยช่องทางใดๆ ของบริษัท
        และ/หรือบุคคลที่เป็นตัวแทน หรือมีความเกี่ยวข้องกับบริษัท และ/หรือของบุคคลอื่น
        และเพื่อวัตถุประสงค์อื่นใดที่ไม่ต้องห้ามตามกฎหมาย
        และ/หรือเพื่อปฏิบัติตามกฎหมายหรือกฎระเบียบที่ใช้บังคับกับบริษัท ทั้งขณะนี้และภายภาคหน้า
      </p>
      <p className="indent">
        ค) กรณีมีความจำเป็นต้องส่งหรือโอนข้อมูลส่วนบุคคลของท่านไปยังต่างประเทศ
        ซึ่งอาจมีมาตรฐานในการคุ้มครองข้อมูลส่วนบุคคลที่ไม่เพียงพอ (เว้นแต่เป็นกรณีที่
        พ.ร.บ.คุ้มครองข้อมูลส่วนบุคคลกำหนดให้สามารถดำเนินการได้โดยไม่ต้องได้รับความยินยอม){' '}
      </p>
      <p className="indent">
        ง) กรณีท่านเป็นผู้เยาว์ คนไร้ความสามารถ คนเสมือนไร้ความสามารถ
        ซึ่งต้องได้รับความยินยอมจากบิดามารดา ผู้ปกครอง ผู้อนุบาล หรือผู้พิทักษ์ แล้วแต่กรณี
        (เว้นแต่เป็นกรณีที่
        พ.ร.บ.คุ้มครองข้อมูลส่วนบุคคลกำหนดให้สามารถดำเนินการได้โดยไม่ต้องได้รับความยินยอม)
      </p>
      <p className="indent">
        จ) ข้อมูลอื่นใด ที่จะเป็นประโยชน์ในการให้บริการ ทั้งนี้ การดำเนินการดังกล่าวข้างต้น
        บริษัทจะขอความยินยอมจากท่านก่อนทำการเก็บรวบรวม ใช้ และเปิดเผยข้อมูลส่วนบุคคลของท่าน{' '}
      </p>
      <p className="indent">
        ทั้งนี้บริษัทจะดำเนินการให้เจ้าของข้อมูลรับรู้และให้ความยินยอม
        ผ่านช่องทางใดช่องทางหนึ่งไม่ว่าจะโดยทางอิเล็กทรอนิกส์ กล่องทำเครื่องหมาย (Checkbox)
        แทบตัวเลือก (Opt-in/Opt-out) โดยเอกสารและ/หรือตามแบบวิธีการที่บริษัทกำหนด{' '}
      </p>
      <p className="indent text-underline">2.5. ฐานทางกฎหมายอื่นๆ</p>
      <p className="indent">
        นอกเหนือไปจากฐานทางกฎหมายข้างต้น บริษัทอาจเก็บรวบรวม ใช้
        หรือเปิดเผยข้อมูลส่วนบุคคลของท่านภายใต้ฐานทางกฎหมายอื่นดังต่อไปนี้
      </p>
      <p className="indent">
        ก) จัดทำเอกสารประวัติศาสตร์หรือจดหมายเหตุเพื่อประโยชน์สาธารณะ
        หรือที่เกี่ยวกับการศึกษาวิจัยหรือสถิติ
      </p>
      <p className="indent">ข) ป้องกันหรือระงับอันตรายต่อชีวิต ร่างกาย หรือสุขภาพของบุคคล</p>
      <p className="indent">
        ค)
        เป็นการจำเป็นเพื่อการปฏิบัติหน้าที่ในการดำเนินภารกิจเพื่อประโยชน์สาธารณะหรือเพื่อการใช้อำนาจของพนักงานเจ้าหน้าที่
      </p>
      <p className="indent">
        3. บริษัทอาจเก็บรวบรวม ใช้ และเปิดเผยข้อมูลส่วนบุคคลจากท่านดังต่อไปนี้:
      </p>
      <p className="indent">
        3.1. <span className="text-underline">รายละเอียดส่วนบุคคล</span> เช่น ชื่อ ชื่อกลาง, นามสกุล
        เพศ วันเดือนปีเกิด อายุ สัญชาติ
      </p>
      <p className="indent">
        3.2. <span className="text-underline">รายละเอียดการติดต่อ</span> เช่น ที่อยู่ติดต่อ
        ที่อยู่อีเมล์ หมายเลขโทรศัพท์ หมายเลขโทรสาร บัญชีติดต่อทางเครือข่ายสังคมออนไลน์
      </p>
      <p className="indent">
        3.3. <span className="text-underline">รายละเอียดที่ใช้ในการระบุตัวตนและการยืนยันตัวตน</span>{' '}
        เช่น ภาพถ่ายและเลขบัตรประจำตัวประชาชน ข้อมูลหนังสือเดินทาง
        (ในกรณีที่จำเป็นในสถานการณ์ที่ไม่อาจหลีกเลี่ยงได้)
        ใบอนุญาตขับรถยนต์ส่วนบุคคล/ใบอนุญาตขับรถจักรยานยนต์ส่วนบุคคล บัตรยืนยันตัวบุคคลอื่น
        ลายมือชื่อ{' '}
      </p>
      <p className="indent">
        3.4.{' '}
        <span className="text-underline">
          ข้อมูลทางภูมิศาสตร์และข้อมูลเกี่ยวกับอุปกรณ์และซอฟต์แวร์ของท่าน
        </span>{' '}
        เช่น ข้อมูลตำแหน่งจีพีเอสของท่านขณะใช้บริการ
        (เพื่อประโยชน์ในการให้บริการและความปลอดภัยของท่าน) ประวัติการใช้งาน (ข้อมูล GPS
        ของยานพาหนะที่ใช้บริการและโทรศัพท์มือถือที่ติดตั้งแอปพลิเคชันของเรา)
        ข้อกำหนดเฉพาะทางเทคนิคและข้อมูลเฉพาะที่ใช้ระบุตัวตน
      </p>
      <p className="indent">
        3.5. <span className="text-underline">รายละเอียดการชำระเงินเช่น</span> ข้อมูลการแจ้งชำระเงิน
        ประวัติการชำระเงิน ช่องทางการชำระเงิน เลขที่บัญชี ข้อมูลบัตรเครดิต ข้อมูลบัตรเดบิต
      </p>
      <p className="indent">
        3.6.{' '}
        <span className="text-underline">
          ข้อมูลการใช้บริการและข้อมูลเกี่ยวกับความสัมพันธ์ของท่านกับบริษัท
        </span>{' '}
        เช่น ประวัติการใช้งาน ( รายละเอียดเกี่ยวกับ วันเดือนปีที่ใช้บริการ จำนวนครั้งในการใช้บริการ
        ประเภทของรถที่ท่านเคยใช้บริการ จุดรับบริการ ระยะทางที่ท่านเคยใช้บริการ
        การกระทำผิดกฎหมายขณะใช้บริการ) สินค้าและ/หรือบริการที่ท่านใช้ สถานะความเป็นลูกค้า
      </p>
      <p className="indent">
        3.7. <span className="text-underline">ข้อมูลการวิจัยตลาด ข้อมูลการตลาดและยอดขาย</span> เช่น
        แบบสำรวจความคิดเห็น
      </p>
      <p className="indent">
        3.8. <span className="text-underline">ข้อมูลเกี่ยวกับการรักษาความปลอดภัย</span> เช่น
        การตรวจพบข้อสงสัยหรือกิจกรรมที่ผิดปกติ ภาพถ่ายหรือภาพเคลื่อนไหวผ่านกล้องโทรทัศน์วงจรปิด
        บันทึกวีดีโอ
      </p>
      <p className="indent">
        3.9. <span className="text-underline">ข้อมูลส่วนบุคคลอ่อนไหว</span> เช่น ศาสนา กรุ๊ปเลือด
        (ไม่ว่าได้รับโดยทางใด เช่น จากการถ่ายสำเนาบัตรประชาชน) ประวัติอาชญากรรม ข้อมูลชีวภาพ (เช่น
        การจดจำใบหน้า ลายนิ้วมือ)
      </p>
      <p className="indent">
        3.10. <span className="text-underline">ข้อมูลอื่นๆ</span> เช่น
        บันทึกการโต้ตอบและการสื่อสารระหว่างท่านกับบริษัท ไม่ว่าช่องทาง รูปแบบหรือวิธีใดๆ ก็ตาม
        รวมถึงแต่ไม่จำกัดเพียง โทรศัพท์ อีเมล ข้อความ และการสื่อสารทางสื่อสังคมออนไลน์ /
        ข้อมูลเกี่ยวกับกรมธรรม์ประกันภัยและการเรียกร้องค่าสินไหมทดแทน / ข้อมูลใดๆ
        ที่ท่านให้ไว้ต่อบริษัท
      </p>
      <p className="indent">
        ท่านต้องแจ้งข้อมูลส่วนตัวของท่านซึ่งมีความถูกต้อง ไม่หลอกลวง ไม่เป็นข้อมูลเท็จ
        ไม่เป็นข้อมูลของบุคคลอื่น และเป็นข้อมูลล่าสุด
      </p>
      <p className="bold topic">4. ที่มาของข้อมูลส่วนบุคคลของท่าน</p>
      <p className="indent">
        บริษัทจะเก็บรวบรวมข้อมูลส่วนบุคคลจากท่านโดยตรงผ่านขั้นตอนของการให้บริการ เช่น
        เมื่อท่านสมัครใช้บริการกับบริษัท เมื่อท่านทำสัญญากับบริษัท
        รวมถึงเมื่อท่านติดต่อกับบริษัทผ่านช่องทางใดๆ หรือเมื่อมีการเก็บข้อมูลจากการใช้คุกกี้
        (และเทคโนโลยีในลักษณะเดียวกัน) และ/หรือเมื่อท่านส่งข้อมูลมายังบริษัทไม่ว่าด้วยเหตุผลใดๆ
        เป็นต้น แต่ในบางกรณีบริษัทอาจได้ข้อมูลส่วนบุคคลของท่านมาจากแหล่งอื่น
        ซึ่งบริษัทจะดำเนินการให้เป็นไปตามที่ฐานแห่งกฎหมาย เพื่อให้สอดคล้องตามที่ พ.ร.บ.
        คุ้มครองข้อมูลส่วนบุคคล กำหนด ข้อมูลส่วนบุคคลที่บริษัทเก็บรวบรวมจากแหล่งอื่น
        อาจรวมถึงแต่ไม่จำกัดเฉพาะข้อมูลดังต่อไปนี้
      </p>
      <p className="indent">
        ก) ข้อมูลที่บริษัทได้รับจากลูกค้าองค์กรธุรกิจ ในฐานะที่ท่านเป็นกรรมการ ผู้มีอำนาจกระทำการแทน
        ตัวแทน ผู้ที่ได้รับมอบหมาย หรือผู้ติดต่อ
      </p>
      <p className="indent">
        ข) ข้อมูลที่บริษัทได้รับจากหน่วยงานราชการ สถาบันการเงิน และ/หรือ ผู้ให้บริการภายนอก (เช่น
        ข้อมูลที่เปิดเผยต่อสาธารณะ)
      </p>
      <p className="bold topic">5. ข้อจำกัดในการใช้และ/หรือเปิดเผยข้อมูลส่วนบุคคล</p>
      <p className="indent">
        บริษัทจะใช้และ/หรือเปิดเผยข้อมูลส่วนบุคคลของท่านได้ตามวัตถุประสงค์และฐานตามฎหมายของการเก็บรวบรวม
        ใช้ หรือเปิดเผยข้อมูลส่วนบุคคลของท่านที่ได้ระบุในนโยบายความเป็นส่วนตัวฉบับนี้เท่านั้น
        บริษัทจะกำกับดูแลพนักงาน เจ้าหน้าที่หรือผู้ปฏิบัติงานของบริษัทมิให้ใช้และ/หรือเปิดเผย
        ข้อมูลส่วนบุคคลของท่านนอกเหนือไปจากวัตถุประสงค์ของการเก็บรวบรวมข้อมูลส่วนบุคคลหรือเปิดเผยต่อบุคคลภายนอก
        เว้นแต่บุคคลหรือองค์กรดังต่อไปนี้ ที่บริษัทอาจเปิดเผยเปิดเผยข้อมูลส่วนบุคคลของท่าน
        ภายใต้หลักเกณฑ์ของ พ.ร.บ. คุ้มครองข้อมูลส่วนบุคคล
      </p>
      <p className="indent">
        5.1. บริษัทในกลุ่มธุรกิจของบริษัท พันธมิตรทางธุรกิจ
        และ/หรือบุคคลอื่นใดที่บริษัทมีนิติสัมพันธ์ด้วย รวมถึงกรรมการ ผู้บริหาร พนักงาน ลูกจ้าง
        ผู้รับจ้าง ตัวแทน ที่ปรึกษาของบริษัท และ/หรือของบุคคลดังกล่าว
      </p>
      <p className="indent">
        5.2. ทนายความ ศาล พนักงานสอบสวน หน่วยงานป้องกันและปราบปรามการทุจริต
        สำนักงานตรวจคนเข้าเมืองและศุลกากร สำนักงานคณะกรรมการคุ้มครองผู้บริโภค กรมขนส่งทางบก
        ศูนย์ประสานการรักษาความมั่นคงปลอดภัยระบบคอมพิวเตอร์ประเทศไทย
        และ/หรือเจ้าหน้าที่ผู้มีอำนาจอื่นของรัฐ หรือรัฐบาล
        หรือที่บริษัทถูกกำหนดหรือได้รับอนุญาตให้เปิดเผยข้อมูลส่วนบุคคลตามกฎหมาย กฎระเบียบ หรือคำสั่ง
      </p>
      <p className="indent">
        5.3. คู่ค้า ตัวแทน หรือองค์กรอื่น (เช่น ผู้ตรวจสอบบัญชีอิสระ คลังเก็บเอกสาร
        สถาบันการเงินในประเทศและต่างประเทศ)
        ซึ่งการเปิดเผยข้อมูลบุคคลส่วนบุคคลของท่านจะมีวัตถุประสงค์โดยเฉพาะเจาะจง ภายใต้ฐานทางกฎหมาย
        และมาตรการความปลอดภัยที่เหมาะสมของบริษัท
      </p>
      <p className="indent">
        5.4. บุคคลที่เกี่ยวข้องในกรณีการขายสิทธิเรียกร้อง และ/หรือทรัพย์สินของบริษัท
        การปรับโครงสร้างองค์กร หรือการควบรวมกิจการของบริษัท
        ซึ่งบริษัทอาจต้องมีการโอนสิทธิไปยังกิจการดังกล่าว รวมถึงบุคคลต่างๆ
        ที่บริษัทจำเป็นต้องแบ่งปันข้อมูลเพื่อการขายสิทธิเรียกร้องและ/หรือทรัพย์สิน
        การปรับโครงสร้างองค์กร การโอนกิจการ ข้อตกลงทางการเงิน การจำหน่ายทรัพย์สิน
        หรือธุรกรรมอื่นใดที่เกี่ยวข้องกับกิจการ และ/หรือ ทรัพย์สินที่ใช้ในการดำเนินกิจการของบริษัท
      </p>
      <p className="indent">
        5.5. บุคคลภายนอกที่ให้บริการต่างๆ แก่บริษัท (เช่น ตัวแทน หรือ
        ผู้จ้างเหมาช่วงที่กระทำการแทนบริษัท ผู้รับจ้างการวิเคราะห์และการเปรียบเทียบทางการตลาด
        ซึ่งรวมถึงแต่ไม่จำกัดเพียง เช่น บริษัทที่ส่งเอกสารหรือสิ่งอื่นใดแก่ท่าน
        ผู้บริการให้ด้านความปลอดภัย)
      </p>
      <p className="indent">
        5.6. ผู้ให้บริการด้านสื่อสังคมออนไลน์ (ในรูปแบบที่ปลอดภัย) หรือบริษัทโฆษณาภายนอก
        เพื่อแสดงข้อความให้แก่ท่าน และบุคคลอื่นใดเกี่ยวกับผลิตภัณฑ์และบริการของบริษัท
      </p>
      <p className="indent">
        5.7. บุคคลอื่นใดที่ให้ผลประโยชน์หรือให้บริการที่เกี่ยวข้องกับผลิตภัณฑ์หรือบริการของท่าน
        (เช่น บริษัทประกันภัย){' '}
      </p>
      <p className="indent">
        5.8. ผู้รับมอบอำนาจ ผู้รับมอบอำนาจช่วง ตัวแทน
        หรือผู้แทนโดยชอบธรรมของท่านที่มีอำนาจตามกฎหมายโดยชอบ
      </p>
      <p className="bold topic">
        6. มาตรการรักษาความมั่นคงปลอดภัยและการคุ้มครองข้อมูลส่วนบุคคลของท่าน
      </p>
      <p className="indent">
        6.1. บริษัทตระหนักถึงความสำคัญของการรักษาความมั่นคงปลอดภัยของข้อมูลส่วนบุคคลของท่าน
        บริษัทจึงกำหนดให้มีมาตรการในการรักษาความมั่นคงปลอดภัยของข้อมูลส่วนบุคคลอย่างเหมาะสมและสอดคล้องกับการรักษาความลับของข้อมูลส่วนบุคคลเพื่อป้องกันการสูญหาย
        การเข้าถึง ทำลาย ใช้ แปลง
        แก้ไขหรือเปิดเผยข้อมูลส่วนบุคคลโดยไม่มีสิทธิหรือโดยไม่ชอบด้วยกฎหมาย
        ตลอดจนการป้องกันมิให้มีการนำข้อมูลส่วนบุคคลไปใช้โดยมิได้รับอนุญาต
        ทั้งนี้เป็นไปตามที่กำหนดในมาตรการรักษาความมั่นคงปลอดภัยของข้อมูลส่วนบุคคลของบริษัท{' '}
      </p>
      <p className="indent">
        6.2.
        โดยบริษัทกำหนดให้บุคลากรของบริษัทและผู้รับจ้างภายนอกจะต้องปฏิบัติตามมาตรฐานและนโยบายความเป็นส่วนตัวที่เหมาะสม
        รวมถึงจะต้องจัดให้มีการดูแลรักษาข้อมูลและมีมาตรการที่เหมาะสมในการใช้
        เปิดเผยหรือการส่งหรือโอนข้อมูลส่วนบุคคลของท่าน
      </p>
      <p className="indent">
        6.3. บริษัทจะทำลายหรือทำให้ไม่ปรากฎชื่อข้อมูลส่วนบุคคลของท่านทันทีที่มีเหตุผลอันเชื่อได้ว่า
        (1)
        วัตถุประสงค์ของการเก็บรวบรวมข้อมูลส่วนบุคคลนั้นไม่ตรงกับการรักษาข้อมูลส่วนบุคคลที่ได้แจ้งท่านไว้ก่อนหรือขณะขอความยินยอมกับท่าน
        และ (2)
        กรณีไม่จำเป็นต้องรักษาข้อมูลส่วนบุคคลดังกล่าวเพื่อวัตถุประสงค์ทางกฎหมายหรือธุรกิจอีกต่อไป
      </p>
      <p className="indent">
        6.4.
        บริษัทอาจใช้บริการสารสนเทศของผู้ให้บริการซึ่งเป็นบุคคลภายนอกเพื่อให้ดำเนินการเก็บรักษาข้อมูลส่วนบุคคลในฐานะผู้ประมวลผลข้อมูลของบริษัท
        ซึ่งผู้ให้บริการนั้นจะต้องมีมาตรการรักษาความมั่นคงปลอดภัยข้อมูล โดยห้ามดำเนินการเก็บรวบรวม
        ใช้หรือเปิดเผยข้อมูลส่วนบุคคลนอกเหนือจากที่บริษัทกำหนด
      </p>
      <p className="bold topic">7. สิทธิตามกฎหมายของท่าน</p>
      <p className="indent">
        ท่านสามารถเริ่มใช้สิทธิตาม พ.ร.บ.
        คุ้มครองข้อมูลส่วนบุคคลเมื่อบทบัญญัติในส่วนที่เกี่ยวกับสิทธิของเจ้าของข้อมูลส่วนบุคคลมีผลใช้บังคับ
      </p>
      <p className="indent">
        กรณีท่านประสงค์จะใช้สิทธิของท่านตามที่กฎหมายรับรอง โปรดแจ้งการปรสงค์ใช้สิทธิดังกล่าวต่อ{' '}
        <span className="text-underline">เจ้าหน้าที่คุ้มครองข้อมูลส่วนบุคคลบริษัท</span>{' '}
        ผ่านช่องทางตามที่ระบุไว้ในข้อที่ 12 ของนโยบายความเป็นส่วนตัวฉบับนี้
        โดยเจ้าหน้าที่จะติดต่อกลับไปหาท่าน เพื่อแจ้งขั้นตอนและวิธีการต่อไปตามที่บริษัทกำหนด
      </p>
      <p className="indent">
        <span className="text-underline">
          7.1. สิทธิในการเข้าถึงและขอรับสำเนาข้อมูลส่วนบุคคลของท่าน
        </span>
        <br />
        ท่านมีสิทธิเข้าถึงและได้รับสำเนาข้อมูลส่วนบุคคลของท่านที่บริษัทมีอยู่
        เว้นแต่กรณีที่บริษัทมีสิทธิปฏิเสธคำขอของท่านตามกฎหมายหรือคำสั่งศาล
        หรือกรณีที่คำขอของท่านจะมีผลกระทบที่อาจก่อให้เกิดความเสียหายต่อสิทธิและเสรีภาพของบุคคลอื่น
        บริษัทจะดำเนินการตามคำขอของท่านภายใน 30 วันนับแต่วันที่ได้รับคำขอ
      </p>
      <p className="indent">
        <span className="text-underline">7.2. สิทธิในการขอแก้ไขข้อมูลส่วนบุคคลของท่าน</span>
        <br />
        ท่านมีสิทธิขอให้บริษัททำการแก้ไข
        หรือปรับปรุงข้อมูลส่วนบุคคลของท่านที่ไม่ถูกต้องหรือไม่ครบถ้วน
      </p>
      <p className="indent">
        <span className="text-underline">7.3. สิทธิในการขอให้ลบข้อมูลส่วนบุคคลของท่าน</span>
        <br />
        ท่านมีสิทธิขอให้บริษัทลบหรือทำลายข้อมูลของท่าน
        หรือทำให้ข้อมูลของท่านเป็นข้อมูลที่ไม่สามารถระบุตัวตนของท่านได้
        เว้นแต่กรณีที่บริษัทจะมีเหตุอันชอบด้วยกฎหมายในการปฏิเสธคำขอของท่าน
      </p>
      <p className="indent">
        <span className="text-underline">
          7.4. สิทธิในการขอให้ระงับการใช้ข้อมูลส่วนบุคคลของท่าน
        </span>
        <br />
        ท่านมีสิทธิขอให้บริษัทระงับการใช้ข้อมูลส่วนบุคคลของท่านในบางกรณี (เช่น
        บริษัทอยู่ระหว่างการตรวจสอบคำขอใช้สิทธิแก้ไขข้อมูลส่วนบุคคลหรือคัดค้านการเก็บรวบรวม ใช้
        หรือเปิดเผยข้อมูลส่วนบุคคลของท่าน
        หรือท่านขอให้บริษัทระงับการใช้ข้อมูลส่วนบุคคลแทนการลบหรือทำลายข้อมูลส่วนบุคคลที่ไม่มีความจำเป็นอีกต่อไป)
      </p>
      <p className="indent">
        <span className="text-underline">
          7.5. สิทธิในการคัดค้านการเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคลของท่าน
        </span>
        <br />
        ท่านมีสิทธิคัดค้านการเก็บรวบรวม ใช้
        หรือเปิดเผยข้อมูลส่วนบุคคลของท่านในกรณีที่บริษัทดำเนินการภายใต้ฐานประโยชน์โดยชอบด้วยกฎหมาย
        หรือเพื่อวัตถุประสงค์เกี่ยวกับการตลาดแบบตรงหรือการศึกษาวิจัยทางวิทยาศาสตร์ ประวัติศาสตร์
        หรือสถิติ เว้นแต่กรณีที่บริษัทมีเหตุในการปฏิเสธคำขอของท่านโดยชอบด้วยกฎหมาย (เช่น
        บริษัทสามารถแสดงให้เห็นว่าการเก็บรวบรวม ใช้
        หรือเปิดเผยข้อมูลส่วนบุคคลของท่านมีเหตุอันชอบด้วยกฎหมายยิ่งกว่า
        หรือเพื่อการก่อตั้งสิทธิเรียกร้องตามกฎหมาย การปฏิบัติตามหรือการใช้สิทธิเรียกร้องทางกฎหมาย
        หรือเพื่อประโยชน์สาธารณะของบริษัท)
      </p>
      <p className="indent">
        <span className="text-underline">
          7.6. สิทธิในการขอรับหรือขอให้ส่งหรือโอนข้อมูลส่วนบุคคลของท่าน
        </span>
        <br />
        ท่านมีสิทธิขอรับข้อมูลส่วนบุคคลของท่านในกรณีที่บริษัทสามารถทำให้ข้อมูลนั้นอยู่ในรูปแบบที่สามารถอ่านหรือใช้งานได้โดยทั่วไปด้วยเครื่องมือหรืออุปกรณ์ที่ทำงานได้โดยอัตโนมัติและสามารถใช้หรือเปิดเผยข้อมูลส่วนบุคคลได้ด้วยวิธีการอัตโนมัติ
        รวมทั้งมีสิทธิขอให้บริษัทส่งหรือโอนข้อมูลส่วนบุคคลของท่านไปยังบุคคลภายนอก
        หรือขอรับข้อมูลส่วนบุคคลที่บริษัทได้ส่งหรือโอนไปยังบุคคลภายนอก
        เว้นแต่บริษัทไม่สามารถทำได้โดยสภาพทางเทคนิค
        หรือบริษัทมีเหตุในการปฏิเสธคำขอของท่านโดยชอบด้วยกฎหมาย
        <br />
        <span className="text-underline">สิทธิในการขอถอนความยินยอม</span>
        <br />
        ท่านมีสิทธิขอถอนความยินยอมที่ท่านได้ให้ไว้กับบริษัทเมื่อใดก็ได้
        ตามขั้นตอนและวิธีการที่บริษัทกำหนด เว้นแต่โดยสภาพไม่สามารถถอนความยินยอมได้
      </p>
      <p className="indent">
        <span className="text-underline">7.7. สิทธิในการร้องเรียน</span>
        <br />
        ท่านมีสิทธิร้องเรียนต่อคณะกรรมการคุ้มครองข้อมูลส่วนบุคคลหรือสำนักงานคณะกรรมการคุ้มครองข้อมูลส่วนบุคคล
        หากบริษัทกระทำการอันเป็นการไม่ปฏิบัติตามพรบ.คุ้มครองข้อมูลส่วนบุคคล
      </p>
      <p className="bold topic">8. การส่งหรือโอนข้อมูลส่วนบุคคลของท่านไปยังต่างประเทศ</p>
      <p className="indent">
        กรณีมีความจำเป็น บริษัทอาจจำเป็นต้องส่งหรือโอนข้อมูลส่วนบุคคลของท่านไปยังต่างประเทศ
        ในกรณีนี้บริษัทจะใช้ความพยายามอย่างดีที่สุดในการส่งหรือโอนข้อมูลส่วนบุคคลของท่านไปยังพันธมิตรทางธุรกิจ
        ผู้ให้บริการ เจ้าหน้าที่ ตัวแทน
        หรือผู้รับข้อมูลของบริษัทที่มีความน่าเชื่อถือด้วยวิธีการที่ปลอดภัยที่สุดเพื่อรักษาความปลอดภัยของข้อมูลส่วนบุคคลของท่าน
        ซึ่งรวมถึงกรณีดังต่อไปนี้
      </p>
      <p className="indent">ก) เป็นการปฏิบัติตามกฎหมาย</p>
      <p className="indent">
        ข)
        ได้แจ้งให้ท่านทราบถึงมาตรฐานการคุ้มครองข้อมูลส่วนบุคคลที่ไม่เพียงพอของประเทศปลายทางและได้รับความยินยอมจากท่าน
      </p>
      <p className="indent">
        ค) ปฏิบัติตามสัญญาที่ท่านทำไว้กับบริษัท หรือตามคำขอของท่านก่อนการเข้าทำสัญญา
      </p>
      <p className="indent">
        ง) ปฏิบัติตามสัญญาระหว่างบริษัทกับบุคคลหรือนิติบุคคลอื่นเพื่อประโยชน์ของท่าน
      </p>
      <p className="indent">
        จ) ป้องกันหรือระงับอันตรายต่อชีวิต ร่างกาย หรือสุขภาพของท่านหรือของบุคคลอื่น
        เมื่อท่านไม่สามารถให้ความยินยอมในขณะนั้นได้ หรือ
      </p>
      <p className="indent">ฉ) ดำเนินภารกิจเพื่อประโยชน์สาธารณะที่สำคัญ</p>
      <p className="bold topic">9. ระยะเวลาในการเก็บรักษาข้อมูลส่วนบุคคลของท่าน</p>
      <p className="indent">
        บริษัทจะเก็บรักษาข้อมูลส่วนบุคคลของท่านในระหว่างที่ท่านเป็นมีปฏิสัมพันธ์กับบริษัท
        และเมื่อท่านสิ้นสุดความสัมพันธ์กับบริษัท (เช่น เมื่อท่านปิดบัญชีสมาชิกกับบริษัท
        หรือกรณีบริษัทปฏิเสธคำขอของท่าน หรือท่านขอยกเลิกสัญญากับบริษัท)
        บริษัทจะเก็บข้อมูลส่วนบุคคลของท่านตามระยะเวลาที่เหมาะสมและจำเป็นสำหรับข้อมูลส่วนบุคคลแต่ละประเภทและวัตถุประสงค์ตามที่พรบ.คุ้มครองข้อมูลส่วนบุคคลกำหนด
        บริษัทจะเก็บรักษาข้อมูลส่วนบุคคลของท่านตามอายุความ หรือระยะเวลาที่กฎหมายที่เกี่ยวข้องกำหนด
        (เช่น กฎหมายแพ่งและพาณิชย์ กฎหมายการบัญชี กฎหมายภาษีอากร กฎหมายแรงงาน
        และกฎหมายอื่นที่บริษัทต้องปฏิบัติตาม ทั้งในประเทศไทยและต่างประเทศ)
      </p>
      <p className="bold topic">10. การใช้คุกกี้ (และเทคโนโลยีในลักษณะเดียวกัน)</p>
      <p className="indent">
        บริษัทอาจมีเก็บรวบรวมและ/หรือใช้คุกกี้และเทคโนโลยีในลักษณะเดียวกัน
        เมื่อท่านใช้บริการของบริษัท รวมถึงการใช้เว็บไซต์ผ่านเครือข่ายอินเทอร์เน็ต
        และแอปพลิเคชันของบริษัท
        คุกกี้และเทคโนโลยีในลักษณะเดียวกันดังกล่าวนั้นจะช่วยให้บริษัทสามารถจดจำท่าน
        ทราบถึงความชื่นชอบของท่าน และปรับปรุงวิธีการที่บริษัทจะเสนอบริการให้แก่ท่าน
        บริษัทอาจใช้คุกกี้เพื่อวัตถุประสงค์ต่างๆ (เช่น
        ช่วยให้บริษัทเข้าใจวิธีการที่ท่านใช้งานเว็บไซต์ของบริษัทหรืออีเมล
        และเพื่อให้มั่นใจว่าสื่อโฆษณาออนไลน์ที่ได้แสดงแก่ท่านมีความเกี่ยวข้องและเป็นสิ่งที่ท่านสนใจยิ่งขึ้น)
      </p>
      <p className="bold topic">11. การใช้ข้อมูลส่วนบุคคลตามวัตถุประสงค์เดิม</p>
      <p className="indent">
        บริษัทมีสิทธิในการเก็บรวบรวมและใช้ข้อมูลส่วนบุคคลของท่านที่บริษัทได้เก็บรวบรวมไว้ก่อนวันที่พรบ.
        คุ้มครองข้อมูลส่วนบุคคลในส่วนที่เกี่ยวข้องกับการเก็บรวบรวม การใช้
        และการเปิดเผยข้อมูลส่วนบุคคลมีผลใช้บังคับ ต่อไปตามวัตถุประสงค์เดิม
        หากท่านไม่ประสงค์ที่จะให้บริษัทเก็บรวมรวมและใช้ข้อมูลส่วนบุคคลดังกล่าวต่อไป
        ท่านสามารถแจ้งบริษัทเพื่อขอถอนความยินยอมของท่านเมื่อใดก็ได้
      </p>
      <p className="bold topic">12. ช่องทางการติดต่อบริษัท</p>
      <p className="">
        เจ้าหน้าที่คุ้มครองข้อมูลส่วนบุคคล
        <br />
        บริษัท ฮ้อปคาร์ จำกัด เลขที่ 128/403 พญาไท พลาซ่า ราชเทวี กรุงเทพมหานคร 10400
        <br />
        เวลาทำการ: วันจันทร์ - วันศุกร์ เวลา 09:00 น. – 18:00 น.
        <br />
        โทร: 02-080-3960 <br />
        Email: customers@haupcar.com <br />
        line official: @haupcar{' '}
      </p>
      <p className="bold topic">13. การเปลี่ยนแปลงนโยบายความเป็นส่วนตัว</p>
      <p className="indent">
        บริษัทอาจเปลี่ยนแปลงหรือแก้ไขเพิ่มเติมนโยบายความเป็นส่วนตัวนี้เพื่อให้สอดคล้องต่อกฎหมายหรือกฎระเบียบในปัจจุบัน
        โดยบริษัทจะแจ้งนโยบายความเป็นส่วนตัวฉบับปัจจุบันไว้ที่เว็บไซต์ของบริษัท
        https://www.haupcar.com/ หรือบนแพลตฟอร์มอื่นของบริษัท
      </p>
    </section>
  )
}
