export interface FormDataState {
  _id: string
  term1: boolean
  term2: boolean
  idCardURL: string
  houseURL: string
  vehicleBrand: string
  vehicleModel: string
  vehicleYear: number | undefined
  vehicleType: string
  transmissionType: 'auto' | 'manual' | ''
  frontURL: string
  backURL: string
  leftURL: string
  rightURL: string
  vehicleBookURL: string
  serviceType: 'carsharing' | 'car-rental' | ''
  packageType: 'starter' | 'verified-starter' | 'verified-pro' | ''
  hasInsurance: undefined | boolean
  insuranceApplyType: 'daily' | 'yearly' | ''
  insuranceURL: string
  hasParking: undefined | boolean
  parkingLat: number | undefined
  parkingLong: number | undefined
  parkingGeo: string
  availableContactTime: string[]
  paymentURL: string
  status:
    | 'incomplete'
    | 'submitted'
    | 'pending'
    | 'updated'
    | 'verified'
    | 'installed'
    | 'completed'
    | 'unqualified'
    | 'action_required'
  progress: number
  errorMessage: string
}

export const defaultFormData: FormDataState = {
  _id: '',
  term1: false,
  term2: false,
  idCardURL: '',
  houseURL: '',
  vehicleBrand: '',
  vehicleModel: '',
  vehicleYear: undefined,
  vehicleType: '',
  transmissionType: '',
  frontURL: '',
  backURL: '',
  leftURL: '',
  rightURL: '',
  vehicleBookURL: '',
  serviceType: '',
  packageType: '',
  hasInsurance: undefined,
  insuranceApplyType: '',
  insuranceURL: '',
  hasParking: undefined,
  parkingLat: undefined,
  parkingLong: undefined,
  parkingGeo: '',
  availableContactTime: [],
  paymentURL: '',
  status: 'incomplete',
  progress: 0,
  errorMessage: '',
}

export type FormDataKey = keyof FormDataState
interface ActionType {
  type: string
  payload: { field: FormDataKey; value: any }
}

export const ImgURLType = Object.fromEntries(
  Object.entries(defaultFormData).filter(([key, value]) => key.endsWith('URL')),
)

export default (state: FormDataState = defaultFormData, action: ActionType) => {
  switch (action.type) {
    case 'SET_DATA': {
      return { ...action.payload }
    }

    case 'SET_FIELD': {
      const key = action.payload.field
      const value = action.payload.value
      return { ...state, [key]: value }
    }
    default:
      return state
  }
}
