import { message, Select, SelectProps } from 'antd'
import { useEffect, useState } from 'react'
import { getNationalityList, Nationality } from '../../../services/nationality/nationality.service'

type NationalitySelectProps = Omit<SelectProps, 'options' | 'loading' | 'optionFilterProp'>

export const NationalitySelect = (props: NationalitySelectProps) => {
  const [loading, setLoading] = useState(false)
  const [nationList, setNationList] = useState<Nationality[]>([])

  useEffect(() => {
    setLoading(true)
    getNationalityList()
      .then((res) => setNationList(res))
      .catch((error) => {
        console.error(error)
        message.error(error.message)
      })
      .finally(() => setLoading(false))
  }, [])

  return (
    <Select
      {...props}
      options={nationList.map((nl) => ({ label: nl.nationalityname, value: nl.nationalityid }))}
      loading={loading}
      optionFilterProp="label"
      showSearch
    />
  )
}
