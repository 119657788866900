import { CheckCircleOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import styled from 'styled-components'
import HaupLoadingLogo from '../../static/img/haup-loading.gif'
import { ReactComponent as Line } from '../../static/img/svg/lineBtn.svg'
export const Background = styled.div`
  background: #333;
`

export const App = styled.div`
  background-color: #fff;
  min-width: 320px;
  min-height: 100vh;
  margin: 0 auto;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem 1.5rem;

  // @media only screen and (max-width: 500px) {
  //   padding: 0 1.5rem;
  // }
  // max-width: 1000px;
  // margin: 0 auto;
`
export const Container2 = styled.div`
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
`

export const Main = styled.main`
  background: #fafafa;
`

export const Header = styled.header`
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1.5rem;

  @media only screen and (min-width: 500px) {
    box-shadow: 0 0.4rem 0.4rem rgba(0, 0, 0, 0.1);
  }
`
export const HeaderSM = styled.header`
  position: fixed;
  background: var(--color-white);
  z-index: 2;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 4rem;
  padding: 0 1.5rem;
  box-shadow: 0 5px 5px -5px rgba(0, 0, 0, 0.3);
  svg {
    height: 40px;
  }
`

export const Action = styled.div`
  background: #fff;
  display: flex;
  justify-content: space-evenly;
  label {
    color: #2;
    flex: 1;
    text-align: center;
    font-size: medium;
    cursor: pointer;
    border-bottom: 3px solid transparent;
  }

  input[type='radio']:checked + label {
    color: var(--color-primary-light);
    // box-shadow: 0 4px 2px -2px currentColor;
    text-decoration: underline 3px currentColor;
    text-underline-offset: 0.5rem;
  }
`
export const ActionTitle = styled.h4`
  color: var(--color-primary);
  text-transform: uppercase;
  font-size: large;
  margin: 0;
`

export const StepForm = styled.section`
  --color-primary: rgb(21, 73, 156);
  --color-primary-light: rgb(53 187 236);
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: hidden;
  transition: all 0.2s ease;
  margin-bottom: auto;

  // @media only screen and (min-width: 500px) {
  //   margin-right: -20px;
  // }
`

export const Heading2 = styled.h2`
  color: inherit;
  font-size: x-large;
`
export const Heading3 = styled.h3`
  color: inherit;
  font-size: large;
  margin-top: 1rem;
  margin-bottom: 0;
`
export const Heading4 = styled.h4`
  font-size: medium;
`

export const SubHeading = styled.p`
  color: #999;
  font-weight: 300;
`

export const AddVehicleButton = styled.div`
  font-size: large;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem;
  border-radius: 10px;
  // box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3)
  box-shadow: 0 5px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: all 0.3s ease;
  background: transparent linear-gradient(235deg, #0058c9 0%, #0046a0 100%) 0% 0% no-repeat
    padding-box;
  color: white;
  :hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.3);
  }

  :active {
    transform: translateY(-2px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  }
`
export const IncompleteItems = styled.div`
  display: flex;
  flex-direction: column;
`
export const CompletedItems = styled.div`
  display: flex;
  flex-direction: column;
`
export const Item = styled.div`
  cursor: pointer;
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  box-shadow: 0 5px 8px rgba(0, 0, 0, 0.1);
  height: clamp(6rem, 6rem, 6rem);

  &:not(:last-child) {
    margin-bottom: 2rem;
  }

  .image {
    width: 6rem;
  }
  .detail {
    font-size: medium;
    padding: 0 1rem;
    flex: 1;
    text-transform: capitalize;
  }

  img {
    width: 100%;
  }
`

export const NavigationBar = styled.nav`
  // to fixed bottom
  position: fixed;
  bottom: 0;
  background: var(--color-white);
  z-index: 2;
  width: 100%;
  ////////////////////

  margin-top: auto;
  box-shadow: 0 -5px 5px -5px rgba(0, 0, 0, 0.3);
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  button {
    flex: 0.33;
  }

  button[role='previous'] {
    margin-right: auto;
  }
  button[role='next'],
  button[role='skip'] {
    margin-left: auto;
  }

  @media only screen and (min-width: 500px) {
    position: absolute;
  }
}
`

export const FlexPush = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`

export const InnerContainer = styled.div`
  padding: 0 1rem;
`

export const LargeContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
`
export const PageContainer = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`
export const ItemDetails = styled.div`
  color: var(--color-primary);
  display: flex;
  align-items: center;
  padding: 1rem 0;
  top: 0;
  position: sticky;
  background: var(--color-white);
  z-index: 2;
`

export const FlexBox = styled.div`
  display: flex;
  position: relative;
`
export const FlexItem = styled.div``

export const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  // gap: 0.5rem;
  > *:not(:last-child) {
    margin-bottom: 0.5rem;
  }
  padding: 0.5rem 0 0 0;
  ul {
    padding-left: 1rem;
    margin-bottom: 0;
  }

  li::marker {
    content: '>  ';
    font-weight: 200;
    display: inline-block;
    font-size: 1rem;
    color: var(--color-grey-2);
  }
  li {
    font-size: 1rem;
  }
  .modal-header {
    font-size: 1.2rem;
    font-weight: 500;
    color: var(--color-primary);
  }
  .modal-subHeader {
    font-size: 1rem;
    background: rgb(173, 216, 230, 0.53);
    padding: 0.5rem;
    border-radius: 1rem;
  }
  .modal-title {
    font-size: 1.1rem;
    font-weight: 500;
  }
  .modal-text {
    font-size: 1rem;
  }
  .modal-list {
    font-weight: 400;
  }
`

export const RemovePadding = styled.div`
  .ant-collapse-content-box {
    padding: 0 !important;
  }
`

export const DemoImage = styled.img`
  width: 100%;
  max-width: 300px;
  object-fit: contain;

  :not(:last-of-type) {
    margin-bottom: 1rem;
  }
`

export const SuccessTick = (): JSX.Element => {
  return (
    <CheckCircleOutlined
      style={{ color: 'green', fontSize: '1.3rem', animation: 'scaleAppear 0.5s' }}
    />
  )
}

export const HaupLoadingFullScreen = (): JSX.Element => {
  return (
    <div
      style={{
        width: '8rem',
        height: '8rem',
        background: 'var(--color-primary)',
        borderRadius: '50%',
        display: 'grid',
        placeItems: 'center',
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)',
        zIndex: 9999,
      }}
    >
      <img
        src={HaupLoadingLogo}
        style={{
          width: '50%',
          objectFit: 'contain',
        }}
      />
    </div>
  )
}

export const MainContent = styled.main`
  flex: 1;
  padding: 4rem 1rem 1rem 1rem;
  display: flex;
  min-height: 100%;
  position: relative;
  @media screen and (max-width: 320px) {
    padding-top: 4rem;
  }

  flex-direction: column;
  > *:not(:last-child) {
    margin-bottom: 1rem;
  }

  @media only screen and (min-width: 600px) {
    padding-bottom: 0;
  }
`

export const Title = styled.h1`
  text-transform: capitalize;
  font-weight: 500;
  font-size: 1.4rem;
  color: var(--color-primary);
`
export const Text = styled.p`
  font-size: 1rem;
  color: var(--color-text);
`

export const UserTypeButton = styled.div`
  cursor: pointer;
  height: 3.5rem;
  background: var(--color-primary);
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-radius: 0.8rem;
  color: white;
  position: relative;
  overflow: hidden;

  > svg {
    position: absolute;
    top: 5px;
    left: 45%;
    transform: translateX(-100%);
  }
  p,
  div {
    text-transform: capitalize;
    position: absolute;
    right: 45%;
    font-size: 1.2rem;
    font-weight: 500;
    transform: translateX(100%);
  }
`

export const SupText = styled.span`
  text-transform: uppercase;
  position: absolute;
  top: 3.1rem;
  font-size: 0.8rem;
  font-weight: 500;
  color: var(--color-primary);

  @media screen and (max-width: 320px) {
    top: 2.5rem;
  }
`

export const InputTitle = styled.h3`
  text-transform: capitalize;
  font-weight: 400;
  font-size: 1.3rem;
`

export const ButtonBlock = styled(Button)`
  height: 3rem;
  font-size: 1.2rem;
  background: var(--color-primary);
  text-transform: capitalize;
  color: #fff;
  width: 100%;

  :active,
  :focus,
  :hover {
    color: #fff;
    outline: none;
    background: var(--color-primary);
  }
`

export const LineButton = () => {
  return (
    <Button
      type="link"
      style={{
        height: 'min-content',
        display: 'flex',
        justifyContent: 'center',
        padding: 0,
        borderRadius: '12px',
        margin: '0 auto',
      }}
    >
      <a href="https://line.me/R/ti/p/@haupcar" target="_blank">
        <Line />
      </a>
    </Button>
  )
}
