import {
  GetOTPSuccess,
  LoginOTPVerifySuccess,
  LoginSuccess,
  MobileDuplicated,
  MobileRequired,
  OtpRequired,
  OtpTokenRequired,
  RegisterOTPVerifySuccess,
  RegisterSuccess,
} from './auth.interfaces'

export function instanceOfGetOTPSuccess(object: any): object is GetOTPSuccess {
  return 'refCode' in object && 'mobile' in object
}

export function instanceOfRequireOTP(object: any): object is OtpRequired {
  return object.status === 303 && object.name === 'OTP_MOBILE_REQUIRED' && 'otp' in object
}

export function instanceOfOTPTokenRequired(object: any): object is OtpTokenRequired {
  return object.name === 'OTP_TOKEN_REQUIRED' && 'otp' in object
}

export function instanceOfRequireMobile(object: any): object is MobileRequired {
  return object.status === 419 && object.name === 'MOBILE_REQUIRED' && 'loginToken' in object
}

export function instanceOfMobileDuplicated(object: any): object is MobileDuplicated {
  return object.status === 400 && object.name === 'MOBILE_DUPLICATED'
}

export function instanceOfMobileRequiredToRegister(object: any): object is OtpTokenRequired {
  return object.status === 400 && object.name === 'REGISTER_MOBILE_NUMBER_REQUIRED'
}

export function instanceOfLoginSuccess(object: any): object is LoginSuccess {
  return 'accessToken' in object && 'userData' in object
}

export function instanceOfRegisterSuccess(object: any): object is RegisterSuccess {
  return 'accessToken' in object && 'userData' in object
}

export function instanceOfRegisterOTPVerifySuccess(
  object: any,
): object is RegisterOTPVerifySuccess {
  return 'result' in object && 'token' in object
}

export function instanceOfLoginOTPVerifySuccess(object: any): object is LoginOTPVerifySuccess {
  return instanceOfLoginSuccess(object)
}
