import { Button, Form, Input, message, Modal } from 'antd'
import { sendResetPassword } from '../../services/forget_password'

export async function popResetPassword(): Promise<void> {
  const [form] = Form.useForm()
  Modal.confirm({
    title: 'รีเซ็ตรหัสผ่าน',
    content: (
      <Form form={form}>
        <Form.Item
          name="email"
          label="อีเมล"
          rules={[{ type: 'email' }, { required: true, message: 'ห้ามเว้นว่าง' }]}
        >
          <Input />
        </Form.Item>
        <Button onClick={() => console.log(form)}>TEST</Button>
      </Form>
    ),
    okText: 'ยืนยัน',
    cancelText: 'ยกเลิก',
    onOk() {
      return new Promise((resolve, reject) => {
        const email = form.getFieldValue('email') as string
        sendResetPassword(email)
          .then((result) => {
            if (result.result === 'OK') {
              message.success('Request submitted please check your email')
              resolve(true)
              return
            }
            reject('Result is not OK')
          })
          .catch((error) => {
            message.error('Request failed. Please try again.')
            reject(false)
          })
      })
    },
  })
}
