import { Select, SelectProps } from 'antd'
import { useEffect, useState } from 'react'
import {
  getMobileCountryCodes,
  MobileCountryCode,
} from '../../../services/mobile_countrycode/mobile_countrycode.service'

type MobileCountryCodeSelectProps = Omit<SelectProps, 'options' | 'loading' | 'optionFilterProp'>

export const MobileCountryCodeSelect = (props: MobileCountryCodeSelectProps) => {
  const [prefixes, setPrefixes] = useState<MobileCountryCode[]>([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    getMobileCountryCodes()
      .then((resp) => setPrefixes(resp))
      .finally(() => setLoading(false))
  }, [])

  return (
    <Select
      {...props}
      options={prefixes.map((v, i) => ({
        label: `(+${v.mobileCountryCode}) ${v.countryName}`,
        value: v.mobileCountryCode,
        key: `mobile-prefix-select-${i}`,
      }))}
      loading={loading}
      optionFilterProp="label"
      showSearch
    />
  )
}
