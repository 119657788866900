import { CloseOutlined } from '@ant-design/icons'
import { Button, Col, Drawer, Form, message, Modal, Row, Space } from 'antd'
import promiseRetry from 'promise-retry'
import { useRef } from 'react'
import { useHistory } from 'react-router'
import { theme } from '../../config/style'
import { login } from '../../contexts/Auth'
import { useLoading } from '../../contexts/LoadingContext'
import { LoginOTPVerifySuccess } from '../../services/auth/auth.interfaces'
import {
  instanceOfGetOTPSuccess,
  instanceOfLoginSuccess,
  instanceOfRequireMobile,
  instanceOfRequireOTP,
} from '../../services/auth/instanceof.function'
import {
  loginByCredential,
  loginVerifyMobileOtp,
  userLoginGetMobileOTP,
} from '../../services/auth/login.service'
import { errorMessage } from '../../utils/function.errorMessage'
import { useArrayWatch } from '../../utils/useArrayWatch'
import { popResetPassword } from '../Modal/ResetPasswordModal'
import { Heading2 } from '../StyledComponents/StyledComponents'
import { LoginDrawerProps } from './CustomDrawer.interfaces'
import { CustomLoginInput, CustomLoginInputPassword } from './Elements'
import { popMobileDrawer } from './MobileDrawer'
import { popOTPDrawer } from './OTPDrawer'

export const LoginDrawer = (props: LoginDrawerProps) => {
  const onClose = props.onClose ? props.onClose : () => null
  const [email, password] = useArrayWatch<[string, string]>(['email', 'password'], props.formRef)
  const loginCredentialComplete = Boolean(email && password)
  const history = useHistory()
  const mobileDrawerRef = useRef<HTMLDivElement>(null)
  const otpDrawerRef = useRef<HTMLDivElement>(null)
  const { setLoading } = useLoading()

  function redirectToSubmission() {
    history.replace('/submission')
  }

  async function loginViaCredential() {
    if (!email || !password) {
      return message.error('Email and Password Required.')
    }
    try {
      setLoading(true)
      const loginResult = await loginByCredential(email, password).finally(() => setLoading(false))
      if (instanceOfLoginSuccess(loginResult)) {
        return login(loginResult.accessToken, loginResult.userData, redirectToSubmission)
      }
      if (instanceOfRequireOTP(loginResult)) {
        const { mobile, refCode, expireAt } = loginResult.otp
        const loginSuccess = await promiseRetry<LoginOTPVerifySuccess>(
          async (retry) => {
            const otpValue = await popOTPDrawer(otpDrawerRef.current, {
              otpLength: 4,
              title: 'Login',
              mobileNumber: mobile,
              refCode: refCode,
            })
            if (otpValue === '') {
              throw 'Cancel'
            }
            const otpResult = await loginVerifyMobileOtp(refCode, otpValue).catch((error) => {
              console.error(error.message)
              message.error(error)
              return retry(error)
            })
            if (!otpResult.accessToken || !otpResult.userData) {
              retry('Invalid OTP Data')
            }
            return otpResult
          },
          { retries: 3, minTimeout: 0 },
        ).catch((error: any) => message.error(error))

        if (!(loginSuccess.result === true && loginSuccess.accessToken && loginSuccess.userData)) {
          throw 'Invalid Login Data'
        }
        return login(loginSuccess.accessToken, loginSuccess.userData, redirectToSubmission)
      }
      if (instanceOfRequireMobile(loginResult)) {
        const [prefixValue, mobileValue] = await popMobileDrawer(mobileDrawerRef.current)
        if (prefixValue === '' || mobileValue === '') {
          return
        }
        setLoading(true)
        const requestOTP = await userLoginGetMobileOTP(
          Number(prefixValue),
          mobileValue,
          loginResult.loginToken,
        ).finally(() => setLoading(false))
        if (instanceOfGetOTPSuccess(requestOTP)) {
          // get otp ok
          const loginSuccess = await promiseRetry<LoginOTPVerifySuccess>(
            async (retry) => {
              const otpValue = await popOTPDrawer(otpDrawerRef.current, {
                otpLength: 4,
                title: 'Login',
                mobileNumber: requestOTP.mobile,
                refCode: requestOTP.refCode,
              })
              if (otpValue === '') {
                throw 'Cancel'
              }
              const otpResult = await loginVerifyMobileOtp(requestOTP.refCode, otpValue).catch(
                (error) => {
                  console.error(error.message)
                  message.error(error)
                  return retry(error)
                },
              )
              if (!otpResult.accessToken || !otpResult.userData) {
                return retry('Invalid OTP Data')
              }
              return otpResult
            },
            { retries: 3, minTimeout: 0 },
          ).catch((error: any) => message.error(error))

          if (loginSuccess.result === true && loginSuccess.accessToken && loginSuccess.userData) {
            return login(loginSuccess.accessToken, loginSuccess.userData, redirectToSubmission)
          }
          throw loginSuccess
        }
        throw requestOTP
      }
      throw loginResult
    } catch (error: any) {
      console.error(error)
      const message = errorMessage(error)
      if (message) {
        Modal.error({ content: errorMessage(error) })
      }
    }
  }

  return (
    <>
      <Drawer
        open={props.open}
        onClose={() => onClose()}
        placement="bottom"
        getContainer={false}
        height="90%"
        headerStyle={{
          borderBottom: 0,
          zIndex: 1 /* boxShadow: '0px 2px 8px 0px rgba(0,0,0,0.25)' */,
        }}
        title={
          <Row justify="center" align="middle" style={{ marginTop: '1rem' }}>
            <Col span={2}></Col>
            <Col span={20} style={{ textAlign: 'center' }}>
              <Heading2 style={{ color: theme.haupBlue }}>Login</Heading2>
            </Col>
            <Col span={2}>
              <CloseOutlined
                style={{ fontSize: '20px', color: '#00000050' }}
                onClick={() => onClose()}
              />
            </Col>
          </Row>
        }
        contentWrapperStyle={{
          borderRadius: '20px 20px 0 0',
          overflow: 'hidden',
        }}
        drawerStyle={{ background: theme.haupBlueSecondary }}
        closeIcon={null}
        // maskStyle={{ background: 'transparent' }}
        bodyStyle={{ position: 'relative' }}
        style={{
          maxWidth: '500px',
          margin: '0 auto',
        }}
      >
        <Space direction="vertical" style={{ width: '100%' }} size="large">
          <Form
            form={props.formRef}
            onKeyUp={(e) => {
              if (e.key === 'Enter') {
                loginViaCredential()
              }
            }}
          >
            <Form.Item
              name="email"
              rules={[
                { type: 'email', message: 'Only email' },
                { required: true, message: 'ห้ามเว้นว่าง' },
              ]}
            >
              <CustomLoginInput placeholder="Email" />
            </Form.Item>
            <Form.Item>
              <Form.Item
                noStyle
                name="password"
                rules={[{ required: true, message: 'ห้ามเว้นว่าง' }]}
              >
                <CustomLoginInputPassword placeholder="Password" />
              </Form.Item>
              <a onClick={popResetPassword} style={{ float: 'right' }}>
                Forgot password ?
              </a>
            </Form.Item>
            {/* <Form.Item>
                <Divider>Or login using</Divider>
              </Form.Item>
              <Form.Item>
                <div className="text-center">
                  <Space size="large" align="center">
                    <img src={image.facebook} srcSet={`${image.facebook2x} 2x`} />
                    <img src={image.google} srcSet={`${image.google2x} 2x`} />
                    <img src={image.apple} srcSet={`${image.apple2x} 2x`} />
                  </Space>
                </div>
              </Form.Item> */}
            <Form.Item>
              <Button
                block
                type="primary"
                shape="round"
                size="large"
                disabled={!loginCredentialComplete}
                onClick={loginViaCredential}
              >
                Login
              </Button>
            </Form.Item>
            <Form.Item>
              <p className="color-primary text-center">
                New to HAUP?{' '}
                <a onClick={() => onClose('register')}>
                  <u>Register Here</u>
                </a>
              </p>
            </Form.Item>
          </Form>
        </Space>
      </Drawer>
      <div ref={mobileDrawerRef} />
      <div ref={otpDrawerRef} />
    </>
  )
}
