import axios from 'axios'
import config from '../../config'
import { getUserToken as getLocalUserToken } from '../../contexts/Auth'
import {
  LoginOTPVerifySuccess,
  MobileDuplicated,
  RegisterOTPVerifySuccess,
} from './auth.interfaces'
const { authService } = config

interface ICredential {
  username: string
  password: string
}

export async function checkValidToken(accessToken: string): Promise<any> {
  return new Promise((resolve, reject) => {
    axios
      .post(`${authService.URL}/token/verify`, {
        accessToken,
        project: authService.AUTH_PROJECT,
      })
      .then(function (response) {
        const { data } = response
        const { token } = data
        if (token) {
          resolve(token)
        } else {
          reject(token)
        }
      })
      .catch(function (error) {
        const httpStatus = error?.response?.status
        if (httpStatus === 401) {
          resolve(false)
        }
        reject(error)
      })
  })
}

export async function tokenLogin(exchangeToken: string): Promise<any> {
  return new Promise((resolve, reject) => {
    axios
      .post(`${authService.URL}/token/exchange`, {
        exchangeToken,
      })
      .then(function (response) {
        resolve(response.data)
      })
      .catch(function (error) {
        console.log(error)
        reject(error)
      })
  })
}

export async function verifyMobileOtp<
  T extends LoginOTPVerifySuccess | RegisterOTPVerifySuccess | MobileDuplicated,
>(refCode: string, otpCode: string): Promise<T> {
  try {
    const result = await axios.post<T>(`${authService.URL}/otp/verify/mobile`, { refCode, otpCode })
    return result.data
  } catch (error: any) {
    if (error?.response?.data?.message) {
      throw error?.response?.data?.message
    }
    throw error
  }
}

interface IExchangeTokenReturnType {
  exchangeToken: string
  result: boolean
}
export async function getAuthTokenByProject(project: string): Promise<IExchangeTokenReturnType> {
  const storedToken = await getLocalUserToken()
  if (!storedToken) {
    throw 'No token has been store'
  }
  return new Promise((resolve, reject) => {
    axios
      .get(`${authService.URL}/token/exchange/${project}`, {
        headers: {
          Authorization: storedToken,
          project,
        },
      })
      .then(function (response) {
        resolve(response.data)
      })
      .catch(function (error) {
        console.log(error)
        reject(error)
      })
  })
}

export async function getHostDashboardAuthToken(): Promise<IExchangeTokenReturnType> {
  const storedToken = await getLocalUserToken()
  if (!storedToken) {
    throw 'No token has been store'
  }
  try {
    const result = await getAuthTokenByProject('host-console')
    return result
  } catch (error: any) {
    console.log(error)
    throw new Error(error)
  }
}
