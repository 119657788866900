import moment, { Moment } from 'moment'
import React, { ReactElement } from 'react'
import { Redirect } from 'react-router'
import { UserData } from '../../services/auth/auth.interfaces'
import { checkValidToken } from '../../services/auth/auth.service'
import loadingIcon from '../../static/img/haup-loading.gif'

const defaultValues = {
  user: {
    userId: null,
    userName: null,
    userToken: null,
  },
  userLoggedIn: false,
}

const AuthContext = React.createContext(defaultValues)

class AuthProvider extends React.Component<{ children?: ReactElement }> {
  _isMounted = false
  readyLogin = false
  state = {
    user: {
      userId: null,
      userName: null,
      userToken: null,
    },
    userLoggedIn: true,
  }

  componentDidMount(): any {
    this._isMounted = true
    checkAuthStatus().then(async (res) => {
      if (!res) {
        await clearSession()
        this.setState({ userLoggedIn: false })
      }
      if (this._isMounted) {
        this.setState({ userLoggedIn: res })
        const userData = await getUserData()
        this.readyLogin = true
        this.setState({ user: userData })
      }
    })
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  render(): JSX.Element {
    const isUserLoggedIn = this.state.userLoggedIn
    return (
      <>
        {!this.readyLogin ? (
          <div
            style={{
              backgroundColor: 'rgb(21, 73, 156)',
              height: '100%',
              width: '100%',
              textAlign: 'center',
              position: 'relative',
            }}
          >
            <img
              src={loadingIcon}
              style={{
                margin: 0,
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                maxHeight: '20vh',
              }}
            ></img>
          </div>
        ) : (
          [
            isUserLoggedIn ? (
              <AuthContext.Provider value={this.state} key="AuthProvider">
                {this.props.children}
              </AuthContext.Provider>
            ) : (
              <Redirect
                to={{
                  pathname: '/login',
                  search: location.search,
                  state: { from: location.pathname },
                }}
                key="RedirectLogin"
              />
            ),
          ]
        )}
      </>
    )
  }
}

function useAuth(): any {
  const context = React.useContext(AuthContext)
  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider')
  }
  return context
}

export async function login(accessToken: string, userData: UserData, onLoginSuccess?: () => void) {
  await setUserToken(accessToken)
  await setUserData(userData)
  if (onLoginSuccess) {
    onLoginSuccess()
  }
}

export async function setUserToken(userToken: string): Promise<boolean> {
  try {
    await localStorage.setItem('userToken', JSON.stringify(userToken))
    return true
  } catch (error) {
    return false
  }
}

export async function setUserData(userData: any): Promise<boolean> {
  try {
    await localStorage.setItem('userData', JSON.stringify(userData))
    return true
  } catch (error) {
    return false
  }
}

export async function setLogTime(userData: any): Promise<boolean> {
  try {
    await localStorage.setItem('logTime', JSON.stringify(userData))
    return true
  } catch (error) {
    return false
  }
}

export async function getUserData(): Promise<any> {
  try {
    const userData = await localStorage.getItem('userData')
    if (userData) {
      return JSON.parse(userData)
    }
    return {}
  } catch (error) {
    return false
  }
}

export function getUserToken() {
  const tokenString = localStorage.getItem('userToken')
  if (!tokenString) {
    return null
  }
  return JSON.parse(tokenString)
}

export async function getLogTime(): Promise<Moment | null> {
  const logTime = (await localStorage.getItem('logTime')) || null
  if (logTime) {
    return moment(logTime)
  }
  return null
}

export async function checkAuthStatus(): Promise<boolean> {
  const userToken = await getUserToken()
  if (!userToken) {
    return false
  } else {
    const isValidToken = await checkValidToken(userToken)
    return isValidToken
  }
}

export async function clearSession(): Promise<boolean> {
  if (window) {
    ;(window as any).fcWidget?.destroy()
  }
  await localStorage.clear()
  return true
}

export async function _setLoggedInViaToken(): Promise<boolean> {
  try {
    await localStorage.setItem('loggedInViaToken', JSON.stringify(true))
    return true
  } catch (error) {
    return false
  }
}

export async function _isLoggedInViaToken(): Promise<boolean | null> {
  const _loggedInViaToken = (await localStorage.getItem('loggedInViaToken')) || null
  const loggedInViaToken = _loggedInViaToken ? JSON.parse(_loggedInViaToken) : null
  return loggedInViaToken
}

export { AuthProvider, useAuth, AuthContext }
