import { CloseOutlined } from '@ant-design/icons'
import {
  Button,
  Col,
  Drawer,
  Form,
  FormInstance,
  message,
  Modal,
  Row,
  Space,
  Typography,
} from 'antd'
import { useMemo, useRef } from 'react'
import { ReactFacebookFailureResponse, ReactFacebookLoginInfo } from 'react-facebook-login'
import GoogleLogin, { GoogleLoginResponse, GoogleLoginResponseOffline } from 'react-google-login'
import { useHistory } from 'react-router'
import config from '../../config'
import { theme } from '../../config/style'
import { login } from '../../contexts/Auth'
import { useLoading } from '../../contexts/LoadingContext'
import {
  instanceOfGetOTPSuccess,
  instanceOfMobileDuplicated,
  instanceOfMobileRequiredToRegister,
  instanceOfOTPTokenRequired,
  instanceOfRegisterSuccess,
} from '../../services/auth/instanceof.function'
import { userRegister, userRegisterGetMobileOtp } from '../../services/auth/register.service'
import * as image from '../../static/img/login-page'
import { errorMessage } from '../../utils/function.errorMessage'
import { validEmail } from '../../utils/regex'
import { useArrayWatch } from '../../utils/useArrayWatch'
import { FormItemBroker, FormItemBrokerType } from '../FormItem'
import { Heading2 } from '../StyledComponents/StyledComponents'
import { PrivacyPolicyPDPAContent } from '../Submissions/Form/PrivacyPolicyPDPA/content'
import { TermsContent } from '../Submissions/Form/Terms/content'
import { DrawerProps } from './CustomDrawer.interfaces'
import {
  CustomCheckbox,
  CustomLoginInput,
  CustomLoginInputPassword,
  CustomMobilePrefixSelect,
  CustomNationalitySelect,
  CustomDivider as Divider,
} from './Elements'
import { popRetryableRegisterVerifyOtp } from './OTPDrawer'
const {
  social: { GOOGLE_APP_ID, FACEBOOK_APP_ID },
} = config

function popPrivacyPolicy() {
  return Modal.info({
    icon: null,
    closable: true,
    maskClosable: true,
    content: <PrivacyPolicyPDPAContent />,
  })
}

function popTermsAndCondition() {
  return Modal.info({
    icon: null,
    closable: true,
    maskClosable: true,
    content: <TermsContent />,
  })
}

interface RegisterDrawerProps extends DrawerProps {
  formRef: FormInstance<any>
  onGoogleLoginSuccess?: (res: GoogleLoginResponse | GoogleLoginResponseOffline) => Promise<void>
  onFacebookLoginSuccess?: (
    res: ReactFacebookLoginInfo | ReactFacebookFailureResponse,
  ) => Promise<void>
}

export const RegisterDrawer = (props: RegisterDrawerProps) => {
  const onClose = props.onClose || (() => null)
  const [name, lastname, email, password, password_repeat, tel_prefix, tel, nationality, acceptTC] =
    useArrayWatch<[string, string, string, string, string, string, string, number, boolean]>(
      [
        'name',
        'lastname',
        'email',
        'password',
        'password_repeat',
        'tel_prefix',
        'tel',
        'nationality',
        'acceptTC',
      ],
      props.formRef,
    )
  const passwordRepeatValid = Boolean(password && password_repeat && password === password_repeat)
  const registerValid = useMemo(() => {
    if (!name || !lastname || !validEmail(email)) {
      return false
    }
    if ((password || '').length < 8 || !passwordRepeatValid) {
      return false
    }
    if (!tel_prefix || !tel || tel.length < 9) {
      return false
    }
    if (!nationality || !acceptTC) {
      return false
    }
    return true
  }, [name, lastname, email, password, password_repeat, tel_prefix, tel, nationality, acceptTC])
  const otpDrawerRef = useRef(null)
  const history = useHistory()
  const { setLoading } = useLoading()

  function redirectToSubmission() {
    history.replace('/submission')
  }

  async function registerViaCredentials() {
    if (!otpDrawerRef.current) {
      message.error('No window element provided')
      throw 'No window element provided'
    }
    try {
      if (!(email && password && name && lastname && nationality && tel_prefix && tel)) {
        return message.error('Register form incomplete.')
      }
      setLoading(true)

      const firstRegister = await userRegister({
        email: email,
        password: password,
        name: name,
        lastName: lastname,
        mobileNo: tel,
        mobileCountryCode: Number(tel_prefix),
        nationalityId: nationality,
      }).finally(() => setLoading(false))

      if (instanceOfOTPTokenRequired(firstRegister)) {
        const verifySuccess = await popRetryableRegisterVerifyOtp(otpDrawerRef.current, {
          mobile: firstRegister.otp.mobile,
          refCode: firstRegister.otp.refCode,
        })

        if (!(verifySuccess.result && verifySuccess.token)) {
          return message.error('Invalid OTP Data')
        }

        setLoading(true)
        const registerResult = await userRegister(
          {
            email: email,
            password: password,
            name: name,
            lastName: lastname,
            mobileNo: tel,
            mobileCountryCode: Number(tel_prefix),
            nationalityId: nationality,
          },
          verifySuccess.token,
        ).finally(() => setLoading(false))

        if (instanceOfRegisterSuccess(registerResult)) {
          return login(registerResult.accessToken, registerResult.userData, redirectToSubmission)
        }
      }

      if (instanceOfMobileRequiredToRegister(firstRegister)) {
        const otpData = await userRegisterGetMobileOtp(Number(tel_prefix), `${tel}`).finally(() =>
          setLoading(false),
        )
        if (instanceOfMobileDuplicated(otpData)) {
          return message.error(otpData.message)
        }
        if (instanceOfGetOTPSuccess(otpData)) {
          const verifySuccess = await popRetryableRegisterVerifyOtp(otpDrawerRef.current, {
            mobile: otpData.mobile,
            refCode: otpData.refCode,
          })

          if (!(verifySuccess.result && verifySuccess.token)) {
            return message.error('Invalid OTP Data')
          }

          setLoading(true)
          const registerResult = await userRegister(
            {
              email: email,
              password: password,
              name: name,
              lastName: lastname,
              mobileNo: tel,
              mobileCountryCode: Number(tel_prefix),
              nationalityId: nationality,
            },
            verifySuccess.token,
          ).finally(() => setLoading(false))

          if (instanceOfRegisterSuccess(registerResult)) {
            return login(registerResult.accessToken, registerResult.userData, redirectToSubmission)
          }
        }
      }

      if (instanceOfRegisterSuccess(firstRegister)) {
        return login(firstRegister.accessToken, firstRegister.userData, redirectToSubmission)
      }
      throw firstRegister
    } catch (error: any) {
      console.error(error)
      const message = errorMessage(error)
      if (message) {
        Modal.error({ content: errorMessage(error) })
      }
    }
  }

  return (
    <>
      <Drawer
        open={props.open}
        onClose={() => onClose()}
        placement="bottom"
        getContainer={false}
        height="90%"
        headerStyle={{
          borderBottom: 0,
          zIndex: 1 /*boxShadow: '0px 2px 8px 0px rgba(0,0,0,0.25)'*/,
        }}
        title={
          <Row justify="center" align="middle" style={{ marginTop: '1rem' }}>
            <Col span={2}></Col>
            <Col span={20} style={{ textAlign: 'center' }}>
              <Heading2 style={{ color: theme.haupBlue }}>Sign Up</Heading2>
            </Col>
            <Col span={2}>
              <CloseOutlined
                style={{ fontSize: '20px', color: '#00000050' }}
                onClick={() => onClose()}
              />
            </Col>
          </Row>
        }
        contentWrapperStyle={{
          borderRadius: '20px 20px 0 0',
          overflow: 'hidden',
        }}
        drawerStyle={{ background: theme.haupBlueSecondary }}
        closeIcon={null}
        style={{
          maxWidth: '500px',
          margin: '0 auto',
        }}
      >
        <Form form={props.formRef}>
          <Form.Item name="name" rules={[{ required: true, message: `Please enter first name` }]}>
            <CustomLoginInput placeholder="First name" />
          </Form.Item>
          <Form.Item
            name="lastname"
            rules={[{ required: true, message: 'Please enter last name' }]}
          >
            <CustomLoginInput placeholder="Last name" />
          </Form.Item>
          <Form.Item
            name="email"
            rules={[
              { type: 'email', message: 'Invalid email.' },
              { required: true, message: 'Invalid email.' },
            ]}
          >
            <CustomLoginInput placeholder="Email" />
          </Form.Item>

          <Form.Item
            name="password"
            rules={[
              { required: true, message: 'Must be at least 8 characters.' },
              { min: 8, message: 'Must be at least 8 characters.' },
            ]}
          >
            <CustomLoginInputPassword placeholder="Password" />
          </Form.Item>

          <Form.Item
            name="password_repeat"
            dependencies={['password']}
            rules={[
              (form) => ({
                validator(rule, value, callback) {
                  if (!value || form.getFieldValue('password') === value) {
                    return Promise.resolve()
                  }
                  return Promise.reject(new Error(`Those passwords didn't match. Try again.`))
                },
              }),
            ]}
          >
            <CustomLoginInputPassword placeholder="Confirm Password" />
          </Form.Item>

          <Form.Item>
            <Row gutter={8} wrap={false}>
              <Col>
                <Form.Item noStyle name="tel_prefix" initialValue={'66'}>
                  <CustomMobilePrefixSelect style={{ minWidth: 150, maxWidth: 200 }} />
                </Form.Item>
              </Col>
              <Col flex="auto">
                <Form.Item
                  noStyle
                  name="tel"
                  rules={[
                    { required: true, message: 'Please enter a 10 digit phone number' },
                    { min: 9, max: 10, message: 'Please enter a 10 digit phone number' },
                  ]}
                  normalize={(curr, prev) => {
                    if ([...curr].some((char) => isNaN(Number(char)))) {
                      return prev
                    }
                    return curr
                  }}
                >
                  <CustomLoginInput
                    style={{ width: '100%', height: 70 }}
                    placeholder="Phone Number"
                    maxLength={10}
                    controls={false}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form.Item>

          <Form.Item
            name="nationality"
            rules={[{ required: true, message: 'Required' }]}
            initialValue={203}
          >
            <CustomNationalitySelect showSearch />
          </Form.Item>

          <Form.Item>
            <Divider>Or register using</Divider>
          </Form.Item>
          <Form.Item>
            <div className="text-center">
              <Space size="large" align="center">
                <GoogleLogin
                  clientId={GOOGLE_APP_ID}
                  buttonText="Login"
                  render={(renderProps) => (
                    <img
                      src={image.google}
                      srcSet={`${image.google2x} 2x`}
                      onClick={renderProps.onClick}
                      style={{ cursor: 'pointer' }}
                    />
                  )}
                  onFailure={(res) => console.log(res)}
                  onSuccess={props.onGoogleLoginSuccess}
                  cookiePolicy={'single_host_origin'}
                />
                {/* <FacebookLogin
                  appId={FACEBOOK_APP_ID}
                  callback={props.onFacebookLoginSuccess || (() => null)}
                  isMobile={true}
                  disableMobileRedirect={true}
                  render={(renderProps) => (
                    <img
                      src={image.facebook}
                      srcSet={`${image.facebook2x} 2x`}
                      onClick={renderProps.onClick}
                      style={{ cursor: 'pointer' }}
                      hidden
                    />
                  )}
                /> */}
              </Space>
            </div>
          </Form.Item>
          <FormItemBroker
            name="acceptTC"
            rules={[
              {
                validator(rule, value, callback) {
                  if (!value) {
                    return Promise.reject('Please accept terms and conditions')
                  }
                  return Promise.resolve()
                },
              },
            ]}
          >
            {({ value, onChange }: FormItemBrokerType<boolean>) => {
              return (
                <Space align="center">
                  <CustomCheckbox
                    checked={value}
                    onChange={onChange ? (e) => onChange(e.target.checked) : undefined}
                  />
                  <Typography.Text className="color-primary">
                    I have read and accept{' '}
                    <a onClick={popTermsAndCondition}>
                      <u>Term and Conditions</u>
                    </a>{' '}
                    &{' '}
                    <a onClick={popPrivacyPolicy}>
                      <u>Privacy Policy</u>
                    </a>
                  </Typography.Text>
                </Space>
              )
            }}
          </FormItemBroker>
          <Form.Item>
            <Button
              block
              type="primary"
              shape="round"
              size="large"
              disabled={!registerValid}
              onClick={() => registerViaCredentials()}
            >
              Sign up
            </Button>
          </Form.Item>
          <Form.Item>
            <p className="color-primary text-center">
              Already have an account?{' '}
              <a onClick={() => onClose('login')}>
                <u>Login Here</u>
              </a>
            </p>
          </Form.Item>
        </Form>
      </Drawer>
      <div ref={otpDrawerRef} />
    </>
  )
}
