import axios from 'axios'
import config from '../../config'
import { LoginSuccess, MobileRequired, OtpRequired } from './auth.interfaces'
const { authService, social } = config

export async function appleLogin(
  payload: { appleCode: string; firstName?: string | null; lastName?: string | null },
  otpToken?: string,
): Promise<LoginSuccess | MobileRequired | OtpRequired> {
  try {
    const loginPayload = {
      token: payload.appleCode,
      otpToken: otpToken,
      project: authService.AUTH_PROJECT,
      deviceType: 'WEBSITE',
      callBackUrl: social.APPLE_CALLBACK_URL,
      clientId: social.APPLE_LOGIN_ID,
      ...(payload?.firstName && { firstName: payload?.firstName }),
      ...(payload?.lastName && { lastName: payload?.lastName }),
    }
    const loginResponse = await axios.post(`${authService.URL}/social-login/apple`, loginPayload, {
      validateStatus(status) {
        return [419, 400, 303].includes(status) || (status >= 200 && status < 300)
      },
    })
    return loginResponse.data
  } catch (error: any) {
    if (error?.response?.data?.message) {
      throw error?.response?.data?.message
    }
    throw error
  }
}
