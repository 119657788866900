import axios from 'axios'
import config from '../../config'
import {
  GetOTPSuccess,
  MobileDuplicated,
  UserRegisterParams,
  UserRegisterReturnType,
  VerifyOtpRegisterReturnType,
} from './auth.interfaces'
import { verifyMobileOtp } from './auth.service'

const { authService } = config

export async function userRegisterGetMobileOtp(
  countryCode: number,
  mobile: string,
): Promise<MobileDuplicated | GetOTPSuccess> {
  try {
    const response = await axios.post<MobileDuplicated | GetOTPSuccess>(
      `${authService.URL}/otp/register/mobile`,
      {
        mobileNo: mobile,
        mobileCountryCode: countryCode,
        projectName: authService.AUTH_PROJECT,
      },
      {
        validateStatus(status) {
          return [200, 400].includes(status)
        },
      },
    )
    return response.data
  } catch (error: any) {
    if (error?.response?.data?.message) {
      throw error?.response?.data?.message
    }
    throw error
  }
}

export function registerVerifyMobileOtp(refCode: string, otpCode: string) {
  return verifyMobileOtp<VerifyOtpRegisterReturnType>(refCode, otpCode)
}

export async function userRegister(
  userData: Omit<UserRegisterParams, 'projectName' | 'otpToken'>,
  otpToken?: string,
): Promise<UserRegisterReturnType> {
  try {
    const requestBody = {
      ...userData,
      otpToken,
      projectName: authService.AUTH_PROJECT,
    }
    const registerResult = await axios.post<UserRegisterReturnType>(
      `${authService.URL}/user/register`,
      requestBody,
      {
        validateStatus(status) {
          return [200, 303, 400, 419].includes(status)
        },
      },
    )
    return registerResult.data
  } catch (error: any) {
    if (error?.response?.data?.message) {
      throw error?.response?.data?.message
    }
    throw error
  }
}
