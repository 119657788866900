import axios from 'axios'
import config from '../../config'
import type {
  UserLoginGetMobileOTPReturnType,
  UserLoginReturnType,
  VerifyOtpLoginReturnType,
} from './auth.interfaces'
import { verifyMobileOtp } from './auth.service'
const { authService } = config

export async function loginByCredential(
  username: string,
  password: string,
): Promise<UserLoginReturnType> {
  try {
    const response = await axios.post(
      `${authService.URL}/token/login`,
      {
        username,
        password,
        project: authService.AUTH_PROJECT,
        deviceType: 'WEBSITE',
      },
      {
        validateStatus: (status) => [200, 303, 419].includes(status),
      },
    )
    return response.data
  } catch (error: any) {
    if (error?.response?.data?.message) {
      throw error?.response?.data?.message
    }
    throw error
  }
}

export async function userLoginGetMobileOTP(
  mobileCountryCode: number,
  mobileNumber: string,
  loginToken?: string,
): Promise<UserLoginGetMobileOTPReturnType> {
  try {
    const body = {
      mobileCountryCode,
      mobileNo: mobileNumber,
      projectName: authService.AUTH_PROJECT,
    }
    let headers: Record<string, unknown> = {}
    if (loginToken) {
      headers = { ...headers, 'login-token': loginToken }
    }
    const result = await axios.post<UserLoginGetMobileOTPReturnType>(
      `${authService.URL}/otp/login/mobile`,
      body,
      {
        headers: headers,
        validateStatus: (status) => [400].includes(status) || (status >= 200 && status < 300),
      },
    )
    return result.data
  } catch (error: any) {
    if (error?.response?.data?.message) {
      throw error?.response?.data?.message
    }
    throw error
  }
}

export function loginVerifyMobileOtp(refCode: string, otpCode: string) {
  return verifyMobileOtp<VerifyOtpLoginReturnType>(refCode, otpCode)
}
