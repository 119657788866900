import { Checkbox, Divider, Input, InputNumber, Select } from 'antd'
import styled from 'styled-components'
import { theme } from '../../config/style'
import { MobileCountryCodeSelect } from '../MasterKeyFormComponent/Mobile/MobileCountryCodeSelect'
import { NationalitySelect } from '../MasterKeyFormComponent/Nationality/NationalitySelect'

export const CustomInputAttrs: Record<string, any> = {
  bordered: false,
  size: 'large',
  style: { background: '#fff', borderRadius: '10px', padding: '15px', color: theme.haupBlue },
}
export const CustomLoginInput = styled(Input).attrs({ ...CustomInputAttrs })``
export const CustomLoginInputPassword = styled(Input.Password).attrs({ ...CustomInputAttrs })``
export const CustomLoginInputNumber = styled(InputNumber).attrs({
  ...CustomInputAttrs,
  style: { ...CustomInputAttrs.style, width: '100%' },
})``
export const CustomSelect = styled(Select).attrs({ ...CustomInputAttrs })``
export const CustomCheckbox = styled(Checkbox)`
  & > .ant-checkbox {
    border-radius: 100%;
    overflow: hidden;
    border: 1px solid ${theme.haupBlue};
  }
  & > .ant-checkbox:not(.ant-checkbox-checked) {
    border-radius: 100%;
    overflow: hidden;
    border: 1px solid ${theme.haupBlue};
    & span.ant-checkbox-inner {
      background-color: transparent;
      border-color: transparent;
    }
  }
`
export const CustomDivider = styled(Divider)`
  color: ${theme.haupBlue} !important;
  border-top-color: ${theme.haupBlue} !important;
`

export const CustomNationalitySelect = styled(NationalitySelect).attrs({ ...CustomInputAttrs })``
export const CustomMobilePrefixSelect = styled(MobileCountryCodeSelect).attrs({
  ...CustomInputAttrs,
})``
