import { combineReducers } from 'redux'
import DocumentsReducer from './DocumentsReducer'
import FormDataReducer from './FormDataReducer'
import LanguageReducer from './LanguageReducer'
import LocationReducer from './LocationReducer'
import StepReducer from './StepReducer'
import SubmissionReducer from './SubmissionReducer'
import UserRegistrationReducer from './UserRegistrationReducer'
export const AllReducers = combineReducers({
  language: LanguageReducer,
  location: LocationReducer,
  formData: FormDataReducer,
  steps: StepReducer,
  submissions: SubmissionReducer,
  userRegistration: UserRegistrationReducer,
  documents: DocumentsReducer,
})
