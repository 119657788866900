import { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { appleLogin } from '../../services/auth/apple.service'
import LoginPage from '../LoginPage'

function AppleCallback() {
  const [socialLogin, setSocialLogin] = useState(null)
  const search = useLocation().search
  const history = useHistory()
  useEffect(() => {
    const code = new URLSearchParams(search).get('code')
    const firstName = new URLSearchParams(search).get('firstName')
    const lastName = new URLSearchParams(search).get('lastName')
    if (code) {
      appleLogin({ appleCode: code, firstName, lastName })
        .then(async (result: any) => {
          setSocialLogin(result)
        })
        .catch((error) => {
          alert(error)
          history.replace('/login')
        })
    }
  }, [])

  return socialLogin ? <LoginPage socialLogin={socialLogin} channel="APPLE" /> : <div></div>
}

export default AppleCallback
