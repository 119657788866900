type ActionType = 'SET_LANG' | 'TOGGLE_LANG'
type LanguageType = 'TH' | 'EN'

interface Action {
  type: ActionType
  payload: LanguageType
}

export default (state: LanguageType = 'TH', action: Action): LanguageType => {
  switch (action.type) {
    case 'SET_LANG':
      return action.payload
    case 'TOGGLE_LANG':
      if (state === 'TH') {
        return 'EN'
      } else {
        return 'TH'
      }
    default:
      return state
  }
}
