import React, { lazy, Suspense, useEffect } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { theme } from '../../config/style'
import AppleCallback from '../../containers/AuthorizePage/AppleCallback'
import LineCallback from '../../containers/AuthorizePage/LineCallback'
import { clearSession } from '../../contexts/Auth'
import { LoadingProvider } from '../../contexts/LoadingContext'
import AuthenticatedPage from '../AuthenticatedPage'
import FreshChatModule from '../FreshChat'
import { HaupLoading } from '../HaupLoading'

const AutoLogin = lazy(() => import('../../containers/AuthorizePage/AutoLogin'))
const LoginPage = lazy(() => import('../../containers/LoginPage'))
const NewVehicleSubmissionPage = lazy(() => import('../../containers/VehicleSubmissionPage'))
const Logout = () => {
  useEffect(() => {
    clearSession()
    window.location.href = '/'
  }, [])
  return <>Logging Out...</>
}

const submissionPath = '/submission'
const RedirectToSubmission = () => <Redirect to={submissionPath} />

class Router extends React.Component {
  render(): JSX.Element {
    return (
      <LoadingProvider>
        <Suspense fallback={<HaupLoading loading={true} backgroundColor={theme.haupBlue} />}>
          <Switch>
            <Route exact path="/login">
              <LoginPage />
            </Route>
            <Route exact path="/auth/apple/callback" component={AppleCallback} />
            <Route exact path="/auth/line/callback" component={LineCallback} />
            <Route exact path="/login/token/:token" component={AutoLogin} />
            <Route exact path="/register/token/:token" component={AutoLogin} />
            {/* <Route exact path="/selfie/:field/:token" component={AutoLogin} />
            <Route exact path="/submission/token/:token" component={AutoLogin} /> */}
            <Route exact path="/logout">
              <Logout />
            </Route>
            <Route exact path={'/submission'}>
              <AuthenticatedPage>
                <FreshChatModule>
                  <NewVehicleSubmissionPage />
                </FreshChatModule>
              </AuthenticatedPage>
            </Route>
            <Route exact path={'/submission/:recordId'}>
              <AuthenticatedPage>
                <FreshChatModule>
                  <NewVehicleSubmissionPage />
                </FreshChatModule>
              </AuthenticatedPage>
            </Route>
            <Route exact path={'/submission/:recordId/:pagePath'}>
              <AuthenticatedPage>
                <FreshChatModule>
                  <NewVehicleSubmissionPage />
                </FreshChatModule>
              </AuthenticatedPage>
            </Route>
            <Route path="/">
              <RedirectToSubmission />
            </Route>
            {/* {routeconfig.map((item: RouteItem) => {
              if (item.requireAuth) {
                return (
                  <Route exact key={item.path} path={item.path}>
                    <AuthenticatedPage>

                      <FreshChatModule>
                        <item.container />
                      </FreshChatModule>

                    </AuthenticatedPage>
                  </Route>
                )
              } else {
                return (
                  <Route exact key={item.path} path={item.path}>

                    <item.container />

                  </Route>
                )
              }
            })} */}
          </Switch>
        </Suspense>
      </LoadingProvider>
    )
  }
}

export default Router
