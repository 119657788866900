import axios from 'axios'
import config from '../../config'
import { LoginSuccess, MobileRequired, OtpRequired } from './auth.interfaces'
const { authService } = config

export async function facebookLogin(
  facebookToken: string,
  otpToken?: string,
): Promise<LoginSuccess | MobileRequired | OtpRequired> {
  try {
    const loginPayload = {
      token: facebookToken,
      otpToken: otpToken,
      project: authService.AUTH_PROJECT,
      deviceType: 'WEBSITE',
    }
    const loginResponse = await axios.post(
      `${authService.URL}/social-login/facebook`,
      loginPayload,
      {
        validateStatus(status) {
          return [419, 400, 303].includes(status) || (status >= 200 && status < 300)
        },
      },
    )
    return loginResponse.data
  } catch (error: any) {
    if (error?.response?.data?.message) {
      throw error?.response?.data?.message
    }
    throw error
  }
}
